.topo-listagem-fixo {
  // position: sticky;
  // top: 60px;
  // z-index: 13;
  background-color: $cinza2;
}
.bloco-image-user {
  min-width: 100px;
  position: relative;
  cursor: pointer;
}

hr.topo {
  margin: 0px auto;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 59px;
  z-index: 1;
}
.info-user h2 {
  font-family: $font-family-default-2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}
.info-user h2,
.info-user label,
.info-user p {
  margin-bottom: 0;
  margin-left: 10px;
}
.info-user p.txtPerfil {
  color: #999;
  margin-top: 2px;
}

span.icon-btn-filtro {
  content: url('./images/icones/filter.svg');
}

span.icon-btn-indisponibilidade {
  content: url('./images/icon-close-agenda-x1.svg');
  width: 20px !important;
  margin-bottom: -5px !important;
  margin-right: 3px !important;
  filter: brightness(0);
}

.topo-listagem-fixo .btn {
  height: 45px;
}
.topo-listagem-fixo .Select .react-select__single-value {
  margin: 0 0 0 15px;
}
.topo-listagem-fixo .btn-light span,
.topo-listagem-fixo .btn-link span,
.topo-listagem-fixo .btn-primary span,
.topo-listagem-fixo .btn-secondary span {
  margin-right: 6px;
  margin-bottom: -3px;
  width: 16px;
}

.action-buttons-fixed {
  float: right;
  position: fixed;
  top: 55px;
  right: 23px;
  z-index: 13;
}

.action-buttons-fixed .Select .react-select__control {
  min-height: 45px;
  width: 200px;
}

@media (max-width: 414px) {
  .action-buttons-fixed {
    position: inherit;
  }

  .local-atendimento-fixed {
    // top: 130px;
    margin-bottom: 50px;
  }

  .lista-horarios {
    // padding-bottom: 45px;
  }

  .doctor .top {
    top: 166px;
    height: 60px;
  }
  .lista-horarios .calendar-date {
    width: initial;
  }

  .doctor .top .background {
    height: 190px;
  }
  .doctor .schedules {
    padding-top: 110px;
  }
  .doctor .top .photo-name {
    margin-top: 15px;
  }
}
