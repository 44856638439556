.modal-anexos {
  .modal-content {
    // max-width: 400px;
    padding: 30px;
    background: $cinza1;
    border-radius: $border-radius;
    color: $preto;
    border: none;
    box-shadow: $shadow-modal;
  }

  .dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .selected-file-wrapper {
    text-align: center;
  }

  .selected-file {
    color: #000;
    font-weight: bold;
  }
  .modal-body {
    padding: 0 20px;
  }
  .top-modal {
    margin-bottom: 20px;
  }
  .txt-modal {
    font-size: $font-default;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .txt-modal:hover {
    text-decoration: underline;
  }
  .txt-modal.disable {
    color: $cinza4;
    font-style: italic;
    font-weight: 300;
    text-align: center;
    overflow: initial;
    padding: 0 2px;
    cursor: default;
    text-decoration: none;
  }

  span.icon-btn-anexar {
    content: url('./images/icon_anexar.svg');
    width: 20px;
    float: left;
    margin-left: -4px;
    margin-bottom: -4px;
  }
  .btn-add-modal {
    text-align: center;
  }
  .btn-add-modal .btn-secondary {
    margin-top: 20px;
    box-shadow: none;
  }

  hr {
    margin: 15px 0 2px;
    border-top: 1px solid #e2e5f2;
  }

  .font-bold {
    font-weight: bold;
  }
  .destaque {
    font-size: $font-xs;
    color: $primary;
  }
  .pointer {
    cursor: pointer;
  }

  .borda-separacao {
    border-right: 1px solid $cinza2;
  }

  .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0;
  }
  .btn-icon-only {
    min-width: 0px;
    padding: 0px;
    height: 30px;
    width: 30px;
    margin: 0 0 4px 4px;
    box-shadow: none;
    border-radius: 0px;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn.btn-icon-only:hover {
    transform: none;
  }
  span.icon-btn-excluir {
    content: url('./images/icones/delete.svg');
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 1023px) {
  .modal-anexos {
    // margin: 0 20px 0 10px;
  }
}
