.custom-card {
  color: $preto;
  margin: 0 0 10px;
  position: relative;
  border: none;
  padding: 10px;
  width: 638px;
  box-shadow: none;

  .topo-card {
    background-color: #fff;
    height: 80px;
    border-radius: $border-radius $border-radius 0 0;
    padding: 0 10px 0 20px;
    h1 {
      font-size: $font-md;
      margin-left: 10px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
    }
    button {
      box-shadow: none;
      // margin: 0;
      // padding: 0;
    }
  }
  .viewMode .topo-card {
    border: 1px dashed $cinza4;
    border-bottom: none;
  }
  .viewMode.fixed {
    position: initial;
  }

  .conteudo-card {
    padding: 30px;
    border-radius: 0 0 $border-radius $border-radius;
    // transition: all 1s;
  }
  .conteudo-card .item-view {
    background: linear-gradient($branco 50%, $cinza1 100%);
    padding: 24px 10px;
    border-radius: $border-radius-min $border-radius-min 0 0;
  }
  .conteudo-card .btn.shadow {
    box-shadow: $shadow2;
  }
  .viewMode .conteudo-card {
    background-color: $cinza1;
    border: 1px dashed $cinza4;
    border-top: none;
    // box-shadow: $shadow1;
  }
  .noViewMode .conteudo-card {
    padding: 30px 30px 40px;
    background-color: $cinza1;
  }
  .noViewMode.fixed {
    position: fixed;
  }
}
// CARD AGENDA / LOCAL ATENDIMENTO
.card-localAtendimento {
  margin: 10px;
  padding: 20px 0;
  background-color: $cinza1;
  border-radius: $border-radius;
  max-width: 618px;

  img {
    width: 70px;
  }
  h1 {
    font-size: $font-md;
    font-weight: 800;
  }
  p {
    font-size: $font-xs;
    margin: 0;
  }
}
//////////

// CARD LINKS AGENDAMENTO / TV
.card-agendamento {
  margin: 10px;
  background-color: $cinza1;
  border-radius: $border-radius;
  max-width: 618px;

  .area-link {
    border-radius: $border-radius;
    padding: 20px 20px 6px;
    text-align: center;
    max-width: 50%;
    cursor: pointer;
    transition: all 500ms ease-out;
  }
  .area-link a {
    text-decoration: none;
  }
  .area-link .icon {
    height: 70px;
    width: 70px;
    border-radius: 40px;
    padding: 20px;
    margin: auto;
    background: transparent;
  }
  .area-link .icon img {
    height: 45px;
    mix-blend-mode: multiply;
    transition: all 500ms ease-in-out;
  }

  .area-link .title {
    font-size: $font-default;
    color: $preto;
    text-align: center;
    font-weight: 800;
  }
  .area-link p {
    margin-bottom: 0;
    text-align: center;
  }
  .area-link .btn-link {
    color: $primary;
    text-decoration: none !important;
    transform: translatey(6px) !important;
    opacity: 0;
  }
  .area-link .button-copy {
    transform: translatey(6px) !important;
    opacity: 0;
  }
  .area-link .button-copy img {
    filter: brightness(0);
    width: 20px;
    transition: all 250ms;
  }
}
.area-link:hover {
  background: $branco;
}
.area-link:hover .icon {
  background-color: $primary;
  transition: all 500ms ease-in-out;
}
.area-link:hover .icon img {
  mix-blend-mode: normal;
}
.area-link:hover .btn-link {
  transform: translatey(0) !important;
  opacity: 1;
}
.area-link:hover .button-copy {
  transform: translatey(0) !important;
  opacity: 1;
}
.area-link .button-copy:hover img {
  filter: brightness(1);
}
//////////////

//1280
@media (max-width: 1280px) {
  .noViewMode.fixed {
    position: initial;
  }
}
// 1024
@media (max-width: 1024px) {
  .custom-card {
    width: 60%;
    margin: auto auto 10px;
    padding: 0px;
  }
  .noViewMode.fixed {
    position: initial;
  }
  .card-localAtendimento {
    margin: auto auto 10px;
    max-width: 600px;
    text-align: center;
    padding: 20px 100px;

    p {
      margin-bottom: 20px;
    }
  }
  .card-agendamento {
    margin: auto auto 10px;
    max-width: 60%;
    .area-link {
      max-width: 100%;
    }
  }
}

// Mobile
@media (max-width: 1023px) {
  .custom-card {
    width: 100%;
  }
  .card-localAtendimento {
    max-width: 100%;
    padding: 20px;
  }
  .card-agendamento {
    max-width: 100%;
    background-color: transparent;

    .area-link {
      background: $branco;
      margin-bottom: 10px;
    }
    .area-link:last-child {
      margin-bottom: 0;
    }
    .area-link .icon {
      background-color: $primary;
    }
    .area-link .icon img {
      mix-blend-mode: normal;
    }
    .area-link .btn-link {
      transform: translatey(0) !important;
      opacity: 1;
    }
    .area-link .button-copy {
      transform: translatey(0) !important;
      opacity: 1;
    }
    .area-link .button-copy img {
      filter: brightness(1);
    }
  }

  .topo-card {
    text-align: center;
    // margin-top: 20px;
    height: 130px;
    h1 {
      margin-top: 10px;
      margin-left: 0;
    }
    button {
      border: 1px solid $cinza2;
      margin: 0 8px 0;
      padding: 0 20px;
    }
  }
}
