body {
  font-family: $font-family-default;
}

h1 {
  // font-family: 'Roboto Slab', sans-serif;
  font-size: $font-xl;
  font-weight: bold;
  margin: 0;
}
h2 {
  font-size: $font-md;
  line-height: $font-lg;
}
p {
  font-size: $font-default;
  margin-bottom: 10px;
  line-height: 17px;
}
// span {
//   font-size: $font-default;
// }
p.instruction {
  font-size: $font-xs;
  color: $preto;
  margin-bottom: 20px;
  margin-left: 15px;
}
hr {
  border-top: 1px solid $cinza2;
  margin: 30px 0;
  width: 100%;
}

.font-md {
  font-size: $font-md;
}

.font-xs {
  font-size: $font-xs;
}
.h100 {
  height: 100vh;
}
.rotate {
  transform: rotate(180deg);
}
// .borderTop {
//   border-top: 1px solid $cinza3;
// }

.filas {
  & > *:nth-child(n) > * {
    $red: random(255);
    $green: random(255);
    $blue: random(255);
    border-left: 5px solid rgb($red, $green, $blue);
    background-color: transparent;
  }
  & > *:nth-child(2n) > * {
    $red: random(255);
    $green: random(255);
    $blue: random(255);
    border-left: 5px solid rgb($red, $green, $blue);
    background-color: transparent;
  }
  & > *:nth-child(3n) > * {
    $red: random(255);
    $green: random(255);
    $blue: random(255);
    border-left: 5px solid rgb($red, $green, $blue);
    background-color: transparent;
  }

  & > *:nth-child(4n) > * {
    $red: random(255);
    $green: random(255);
    $blue: random(255);
    border-left: 5px solid rgb($red, $green, $blue);
    background-color: transparent;
  }
  /* ... */
}
.barra-status-pendente {
  background-color: $warning-color;
  color: #697300;
}

.barra-status-sucesso {
  background-color: $success-color;
  color: #fff;
}

.barra-status-negado {
  background-color: $danger-color;
  color: #fff;
}
.card.card-fila {
  height: auto;
}
.card.login > hr {
  margin-top: 5px;
  margin-bottom: 20px;
}
.valor-particular {
  font-size: 15px;
  color: #02cb7d;
  margin-left: 10px;
}
.logo {
  background: url('./images/logo.svg') center no-repeat;
  width: 240px;
  height: 180px;
  margin: 0 auto 20px;
}
.logo-agenda {
  background: url('./images/icon-agenda-online.svg') center no-repeat;
  width: 100%;
  height: 120px;
  margin: 0 auto 20px;
}
.logoOnline {
  height: 70px;
  margin-bottom: 20px;

  width: auto;
}
.title-tab {
  font-weight: bold;
  border-bottom: solid 1px #000;
  font-size: 13px;
}
.line {
  border-bottom: solid 1px #7f7c7c;
  font-size: 13px;
  padding: 10px 5px 10px 5px;
}
.f-10 {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding-top: 11px;
}
.modalCrud .modal-content {
  max-width: 800px;
}
.logo-sasc-cm {
  background: url('./images/logosasccm.png') center no-repeat;
  background-size: contain;
  width: 310px;
  height: 120px;
  margin: 0 auto 20px;
}
.back-elements {
  background: url('./images/background-menu.svg') top left no-repeat;
  width: 100%;
  height: 100vh;
}
.back-elements::after {
  content: '';
  background: url('./images/background-menu.svg') top left no-repeat;
}

.topo .iconNotification,
.topo .iconMenu,
.topo .iconError {
  height: 26px;
  cursor: pointer;
}

.bg-1 {
  background-color: $cinza1;
  padding: 20px 20px;
  margin: 20px 0px;
}

.bg-2 {
  background-color: $branco;
  padding: 20px 20px;
  margin: 20px 0px;
}
.subitem {
  border-top: 1px dotted $cinza3;
  margin-top: 15px;
}
.bg-2 > .subitem,
.bg-1 > .subitem {
  margin-top: 0px;
}

.bg-1 input:-webkit-autofill,
.bg-1 input:-webkit-autofill:hover,
.bg-1 input:-webkit-autofill:focus,
.bg-1 input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $cinza1 !important;
  -webkit-box-shadow: 0 0 0px 1000px $cinza1 inset !important;
}

.topo .iconLogo {
  height: 50px;
  cursor: pointer;
}
.txt-destaque {
  font-size: 13px;
  font-weight: 700;
  color: #516c80;
}

.txt-destaque.red {
  color: #da2c2c;
}

// BOX
.card {
  background: $default;
  padding: 20px;
  border-radius: $border-radius;
  transition: all 500ms;
}
.card.login {
  width: 420px;
  box-shadow: $shadow1;
  background-color: $branco;
  margin: auto;
  border: none;
  padding: 40px 50px;
  border-radius: 35px;
}
.card.cadastro {
  height: 80vh;
}
.checkbox {
  padding: 5px;
}
.is-invalid .checkbox {
  border-bottom: 2px solid $vermelho;
  padding: 5px;
}

.termoCheck {
  font-size: 12px;
  padding: 5px 5px 4px 0px;
  font-weight: bold;
  cursor: pointer;
  color: #04af72;
}
.fixed-btn {
  position: fixed;
  bottom: 315px;
  right: 1rem;
  z-index: 1001;
}
footer {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: -10;
}

.bg-riscos {
  background-color: #eff3f4;
  background: url('./images/background-menu.svg') no-repeat;
}

.background-lines .bg-riscos {
  position: absolute;
  height: 545px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  background-color: none;
}

.bg-riscos.bottom {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  height: 400px;
  width: 600px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
}

.background-lines {
  position: relative;
}
.btn-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: -1;
  bottom: 0;
}

.card.telemedicina {
  height: 90vh;
  width: 90vw;
  box-shadow: $shadow1;
  margin: auto;
  border: none;
  padding: 30px;
}

.card.telemedicina iframe {
  border-radius: 20px;
}
.card.videocall {
  cursor: move;
  position: fixed;
  z-index: 3000;
  min-width: 30vw;
}
.card.videocall iframe {
  border-radius: 20px;
}

.card.filter {
  width: 400px;
  margin: auto;
  border: none;
  padding: 20px 50px;
  background: none;
}

.card.select {
  position: fixed;
  background: $gradient-default-invert;
  border-radius: $border-radius-btn;
  width: 250px;
  box-shadow: $shadow1;
  margin-top: 40px;
  margin-left: 10px;
  z-index: 200;
}
.cardInfo {
  background-color: $cinza1;
  border-radius: $border-radius;
  padding: 30px;
  border: none !important;
  margin: 10px 20px;
  // height: 100%;
}
.cardInfo img {
  width: 260px;
  height: 180px;
}
.cardInfo p.title {
  margin: 0;
  font-size: $font-sm;
  font-weight: 800;
  padding-left: 20px;
  line-height: 20px;
}
.cardInfo p.instruction {
  margin: 0;
  padding-left: 20px;
}
.icon-atendimento-prioritario {
  width: 40px;
  margin-left: 10px;
}

input {
  background-color: $branco;
  color: $preto;
  font-size: $font-default;
  height: 40px;
  border-radius: $border-radius;
  padding: 6px 20px;
  // border: 1px solid $cinza1 !important;
  // margin-bottom: 10px;
}
input:hover {
  // border: 1px solid $cinza2 !important;
}
input:focus {
  color: $preto !important;
  // border: 1px solid $cinza2 !important;
}
// Autocomplete
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.nomeProprio {
  text-transform: uppercase;
}

.nomeProprioMenu {
  text-transform: uppercase;
  font-size: 12px;
}

.select-menu {
  margin: 0px 0px 9px -10px;
}

.form-control {
  // background-color: $branco;
  color: $preto;
  font-size: $font-default;
  height: 50px;
  border-radius: $border-radius;
  padding: 6px 20px;
  // border: 1px solid $cinza1 !important;
}

.form-inside .section-form > div.form-group textarea.textarea-modelo {
  background-color: #f2f5f6;
  padding: 10px;
  min-height: 150px;
  border-radius: 5px;
}
.receituario-content .form-control {
  padding: 8px 5px;
  line-height: 30px;
}
.form-control:focus {
  box-shadow: none;
  background-color: transparent;
}
textarea.form-control {
  background-color: $branco;
  border-radius: $border-radius;
  min-height: 50px;
  border: 1px solid $cinza1;
  resize: none;
}
textarea.form-control:focus {
  // border: 1px solid $cinza2 !important;
  color: $preto;
}

// ICONE EXIBIR SENHA
span.icon-ver {
  content: url('./images/icon_visualizar.svg');
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}
span.icon-ocultar {
  content: url('./images/icon_ocultar.svg');
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-radius: $border-radius;
}
.input-group-append {
  position: absolute;
  right: 2px;
  top: 12px;
  background-color: $branco;
  border-radius: $border-radius;
  z-index: 4;
}
.is-invalid .input-group-append {
  display: none;
}
.input-group-text {
  background-color: transparent;
  border: none;
}

// INVALID
.is-invalid .input-group-text {
  border: 2px solid $vermelho;
}

.input-group > .form-control {
  // border-right: none !important;
}

.pl-5px {
  padding-left: 5px;
}
.pl-10px {
  padding-left: 10px;
}

// TOOLTIP
.tooltip.show {
  opacity: 1;
}
.invalid-tip {
  z-index: 9000;
}

.info-tip .tooltip-inner {
  background-color: #2a444eec;
}
.tooltip-inner {
  padding: 14px;
  line-height: 16px;
  color: $branco;
  font-size: $font-xs;
  max-width: 230px;
}
.invalid-tip .tooltip-inner {
  background-color: $vermelho2;
  box-shadow: 0px 0px 20px #85898e2e;
}
.invalid-tip .arrow::before {
  border-top-color: $vermelho2;
}

.info-tip .tooltip-inner {
  background-color: #2a444eec;
}

.info-tip .arrow::before {
  border-top-color: #2a444eec;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: $danger;
}

.float-button-out {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 12;
}

//tooltip legend
.icon-duvida {
  content: url('./images/Info_icon.svg');
  width: 18px;
  margin-left: 0px;
  cursor: pointer;
}
.icon-duvida:before {
  content: '\e914';
}
.tool-tip {
  display: inline-block;
}

.tool-tip [disabled] {
  pointer-events: none;
}

//DISABLE
.disabled {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent;
}

.btn.btn-sm.btn-link.desabilitado {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent;
}

//LEGENDA CONSULTA
.legends {
  width: 70px;
}
.encaixe .legends {
  margin-left: 55px;
}

.legenda-consulta {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-right: 4px;
  font-weight: bold;
  padding-top: 0px;
  cursor: context-menu;
}
.hora-detalhe-row {
  .btn.btn-sm {
    margin: 0px 5px 0px 24px;
    font-size: $font-xs;
    padding: 5px 0px;
    text-align: left;
  }
  .btn.btn-sm span {
    margin-right: 3px;
    margin-bottom: -1px;
    width: 12px;
  }
  width: 130px;
}

.button-logout {
  width: 130px;
  padding: 0;
  border: none;
  background: none;
}

.button-logout:focus {
  outline: 3px solid $cinza3;
}
.button-logout span {
  font-size: $font-xs;
  margin: 0;
}

.txt-red {
  color: #da2c2c;
}

.txt-black {
  color: #000;
}

.txt-green {
  color: #2dc352;
}

.txt-reserva {
  font-size: smaller;
  color: #000;
}
.box-obs {
  max-width: 630px;
}

.btn.txt-red,
.btn.txt-red:hover,
.btn.txt-red:visited {
  color: #da2c2c;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.txt-green,
.btn.txt-green:hover,
.btn.txt-green:visited {
  color: #2dc352;
}
.legenda-consulta.recomendacao {
  background-color: #2a444f;
  border: 1px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
  border-radius: 20px;
}
.recomendacao::before {
  content: '?';
  color: #ffffff;
}

.check-recorrente > label {
  font-size: 12px;
}
.check-recorrente .lbl-recorrente {
  font-size: 12px;

  padding: 0px 5px;
}

.dias-recorrentes {
  font-size: 13px;
  color: black;
}
.legenda-consulta.comum {
  background-color: #efb274;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}

.legenda-consulta.primeira {
  background-color: #2dc352;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}
.checkbox-custom {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #02cb7d !important;
  appearance: none;
  cursor: pointer;
}

.checkbox-custom:checked {
  background-color: #02cb7d !important;
}
.checkbox-custom:checked::after {
  content: '✓';
  color: white;
  font-size: 18px;
  position: absolute;
  top: -2px;
  left: 5px;
}

.legenda-consulta.encaixe {
  background-color: #e0f116;
  color: #778007;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}

.legenda-consulta.retorno {
  background-color: #457bb7;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}

.legenda-consulta.compareceu {
  background-color: #2cda97;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}

.legenda-consulta.nao-compareceu {
  background-color: #da2c2c;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
}

.local-atendimento-fixed {
  // z-index: 12;
  // top: 120px;
  // position: sticky;
}

.item-precificacao p.form-view {
  line-height: 20px;
}

// BOTOES
.btn {
  font-size: $font-btn;
  font-weight: 600;
  height: 35px;
  min-width: 80px;
  padding: 0 10px;
  margin: 10px 5px;
  transition: all 250ms;
  border-radius: $border-radius-btn;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: scale(0.99);
}
.btn:hover {
  // color: inherit;
  transform: scale(0.95);
  box-shadow: none !important;
  border-color: transparent;
}
.btn:active {
  transform: scale(0.92);
}
.btn:disabled {
  box-shadow: none !important;
  color: $preto !important;
  transform: scale(1) !important;
  cursor: default;
  opacity: 0.4;
  border: none;
}

.bt-size-fixed {
  width: $bt-size-fixed;
}

.btn-link {
  font-size: $font-default;
  // transform: scale(1) !important;
  color: $preto;
  height: 30px;
  box-shadow: none;
  // font-style: italic;
  font-weight: 600;
  transition: all 500ms;
  // text-decoration: underline;
  border: 1px solid transparent;
}
.btn-link:hover {
  color: $preto;
  text-decoration: none;
}
.btn-link:focus {
  color: $preto;
  text-decoration: none;
  box-shadow: $shadow-none;
  border: 0px solid $cinza2;
}
.btn-link.btn-link-black {
  color: $preto;
}

.btn-link-danger {
  font-size: 12px;
  color: $danger-color;
  height: 18px;
  box-shadow: none;
  font-weight: bold;
  transition: all 500ms;
  border: 0px solid transparent;
  margin: -1px 5px;
  padding: 0px;
}
.btn-link-danger:hover {
  color: $danger-color;
  text-decoration: none;
}
.btn-link-danger:focus {
  color: $danger-color;
  text-decoration: none;
  box-shadow: $shadow-none;
  border: 0px solid $cinza2;
}

.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  height: initial;
}
a {
  color: $cinza5;
  transition: all 500ms;
}
a:hover {
  color: $preto;
}
a:focus {
  outline: none;
}
.action {
  cursor: pointer;
}
.btn-primary {
  border: none;
  color: $branco !important;
  // background: $gradient-primary !important;
}
.btn-primary.light {
  border: none;
  color: $preto !important;
  background: $branco !important;
}
.btn-secondary {
  border: 1px solid #e3e7e8;
  color: $preto;
  background: $branco !important;
}
.btn-secondary:focus {
  border: 1px solid $cinza3 !important;
  box-shadow: $shadow2;
  color: $preto;
}
.btn-secondary:active {
  border: 1px solid transparent !important;
  color: $preto !important;
}
.btn-secondary.save:hover,
.btn-secondary.save:focus {
  color: $secondary;
}
.btn-secondary.delete:hover,
.btn-secondary.delete:focus {
  color: $danger;
}
.btn-secondary.open:hover,
.btn-secondary.open:focus {
  border: 1px solid #e3e7e8;
  color: $success;
}

.btn-secondary:hover {
  color: $preto;
}
.btn span {
  margin-right: 6px;
  margin-bottom: -1px;
  width: 16px;
}
span.icon-visualizar {
  content: url('./images/icon_visualizar.svg');
}
span.icon-recorrente {
  content: url('./images/recorrente.png');
  margin-bottom: -3px;
}
span.icon-btn-ecxel {
  content: url('./images/icones/export_excel.svg');
}

span.icon-btn-add {
  content: url('./images/icones/circle-plus.svg');
}

span.icon-btn-editar {
  content: url('./images/icones/edit.svg');
}

span.icon {
  display: inline-flex;
  width: 30px;
  height: 24px;
  padding: 0;
  border: 0 !important;
  outline: none !important;
}
span.icon.icon-cadeirante {
  background: url('./images/icones/cadeirante.svg') no-repeat center !important;
}
span.icon.icon-idoso {
  background: url('./images/icones/idoso.svg') no-repeat center !important;
}
span.icon.icon-gestante {
  background: url('./images/icones/gravida.svg') no-repeat center !important;
}
span.icon.risco-queda {
  background: url('./images/icones/riscoQueda.svg') no-repeat center !important;
}
span.icon-historico {
  content: url('./images/icon-hitorico.svg');
  margin-bottom: -3px;
  width: 20px;
}

span.icon-btn-deletar {
  content: url('./images/icon_fechar_BLACK.svg');
  margin-bottom: -3px;
}

span.icon-btn-voucher {
  content: url('./images/icon_voucher.svg');
  margin-bottom: -3px;
}

span.icon-btn-fechar {
  content: url('./images/icones/circle-x.svg');
  margin-bottom: -3px;
}

span.icon-btn-cancelar {
  content: url('./images/icones/close.svg');
}

span.mobile-center {
  text-align: center;
}
.btn-integration {
  font-weight: normal;
}

.btn-blue {
  border: none;
  color: $branco;
  background: $bt-azul !important;
}
.btn-blue:focus {
  border: none !important;
  box-shadow: $shadow2;
  color: $branco;
}

.btn-secondary.search {
  border: 1px solid #eff3f4;
}

.btn-blue:hover {
  border: none !important;
  // box-shadow: $shadow2;
  color: $branco;
}
.btn-blue:active {
  border: none !important;
  color: $branco !important;
}

.btn-edit {
  border: none;
  color: $branco;
  background: $bt-edit !important;
}
.btn-edit:focus {
  border: none !important;
  box-shadow: $shadow2;
  color: $branco;
}

.btn-edit:hover {
  border: none !important;
  color: $branco;
}
.btn-edit:active {
  border: none !important;
  color: $branco !important;
}

.btn-orange {
  color: #fff;
  background-color: #eb9a20;
  border-color: #eb9a20;
}

.btn-orange:hover {
  color: #fff;
  background-color: #db8d17;
  border-color: #db8d17;
}
.btn-orange:active {
  border: none !important;
  color: $branco !important;
}
.btn-orange:focus {
  border: none !important;
  box-shadow: $shadow2;
  color: $branco;
}

.btn-gray {
  color: #fff;
  background-color: #959595;
  border-color: #959595;
}

.btn-gray:hover {
  color: #fff;
  background-color: #7f7c7c;
  border-color: #7f7c7c;
}
.btn-gray:active {
  border: none !important;
  color: $branco !important;
}
.btn-gray:focus {
  border: none !important;
  box-shadow: $shadow2;
  color: $branco;
}

.btn-light-blue {
  border: none;
  color: $preto;
  background: $default !important;
}
.btn-light-blue:focus {
  border: none !important;
  box-shadow: $shadow2;
  color: $preto;
}

.btn-light-blue:hover {
  border: none !important;
  color: $preto;
}
.btn-light-blue:active {
  border: none !important;
  color: $preto !important;
}

.btn-arrow {
  background: url('./images/icones/arrow-left.svg') no-repeat center;
  width: 70px;
  height: 24px;
  border: none;
  outline: none !important;
}
.btn-arrow.white {
  filter: invert(1);
}

.btn-close {
  background: url('./images/icones/close.svg') no-repeat center;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  border: none;
}

.cardInfo img.ma-icon {
  width: 16px;
  height: 16px;
  padding-right: 3px;
}

.btn.btn-primary:disabled {
  background-color: $cinza2;
  background: $gradient-cinza !important;
}

.lbl-status {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin-right: 10px;
}

// TOOGLE BUTTONS
.toogleButtons {
  margin-bottom: 10px;
}
.toogleButtons .btn-toogle {
  width: 150px;
  font-size: $font-default;
  font-style: normal;
  text-align: center;
  padding: 10px 0;
  border-radius: $border-radius-btn;
  margin: 5px 10px;
  transform: scale(1);
  cursor: pointer;
  transition: all 250ms;
}

.toogleButtons .btn-toogle.disabled {
  display: inline-block;
  cursor: default;
}
.pointer {
  cursor: pointer;
}

.toogleButtons .btn-toogle-off {
  color: $preto;
  background-color: $branco;
  border-color: $branco;
  font-weight: 600;
}
.toogleButtons .btn-toogle-off:hover {
  outline: none !important;
  box-shadow: none !important;
  transform: scale(0.95);
}

.toogleButtons .btn-toogle-off.disabled:hover {
  outline: none !important;
  box-shadow: none !important;
  transform: scale(1);
}

.toogleButtons .btn-toogle-on {
  color: $branco !important;
  background-color: $primary !important;
  border-color: $primary;
  font-weight: 800;
}

.quebra {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}
// ALERTS
.alert {
  font-size: $font-default;
  position: fixed;
  padding: 20px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 30px 30px 0 0;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  box-shadow: 0px -20px 30px #d2d6dc;
  z-index: 1000;
}
.alert-danger {
  background-color: $danger;
  color: $branco;
  font-weight: 600;
  border: none;
}
.alert-success {
  background-color: $success;
  color: $branco;
  font-weight: 600;
  border: none;
}

// MENU
.sideMenu {
  height: 100vh;
  background: #516c80;
}

// ICONS
.icon-back {
  background: url('./images/icones/arrow-left.svg') no-repeat center;
  width: 30px;
  height: 30px;
  opacity: 0.5;
}
.icon-doctor {
  background: url('./images/icones/profissionais.svg') no-repeat center;
  width: 22px;
  height: 22px;
}
.icon-convenio {
  background: url('./images/icon-convenio.svg') no-repeat center;
  width: 22px;
  height: 50px;
}
.icon-patient {
  background: url('./images/icones/clipboard.svg') no-repeat center;
  width: 22px;
  height: 22px;
}
.icon-delete {
  // background-color: $branco;
  // border: 1px solid $cinza1;
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  cursor: pointer;
}
.icon-delete img {
  height: 30px;
  // right: 26px;
}

.icon-delete.local-horario img {
  height: 40px;
}

.icon-delete-2 {
  cursor: pointer;
}
.icon-delete-2 img {
  height: 30px;
}

.fixed {
  position: fixed;
}
.icon-limpar-filtro {
  background: url('./images/ico_x_clear.svg') no-repeat;
  width: 15px;
  height: 16px;
}

// CALENDAR
.icon-filtro {
  position: fixed;
  z-index: 99;
  top: 94px;
  left: 40px;
  background: $gradient-default-invert;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: $shadow2;
}
.icon-filtro.active {
  background: $gradient-primary;
}
.icon-filtro img {
  height: 26px;
  filter: brightness(0);
}
.icon-filtro.active img {
  filter: invert(1);
  height: 30px;
}

.icon-indisponibilidade {
  position: fixed;
  z-index: 99;
  top: 150px;
  left: 40px;
  background: $gradient-default-invert;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: $shadow2;
}
.empty-content {
  content: ' ';
}
.icon-indisponibilidade.active {
  background: $gradient-primary;
}
.icon-indisponibilidade img {
  height: 30px;
  // filter: brightness(0);
}
.icon-indisponibilidade.active img {
  // filter: invert(1);
  height: 30px;
}

.alpha {
  opacity: 0.7;
  margin: 10px;
}

span.icon-btn-imprimir {
  content: url('./images/icon-print.svg');
  width: 15px;
  float: left;
  margin-right: 5px;
  margin-left: 0px;
  margin-top: 4px;
}

button.btn-trash {
  background: url('./images/icones/delete.svg') no-repeat;
  height: 20px;
  width: 20px;
  display: flex;
  min-width: inherit;
  min-height: inherit;
  margin: auto;
}

.item-edit hr {
  margin: 7px;
}

.wh-40 {
  width: 40px;
  height: 40px;
}

.cardCrud.card {
  background-color: $cinza1;
}

.btn-branco {
  background-color: $branco;
}
.btn-rounded {
  border-radius: 20px;
}

.bg-branco {
  background-color: $branco;
  border-radius: 20px;
}

.bkg-branco {
  background-color: $branco;
}

.bkg-cinza {
  background-color: $cinza0;
}

// LOADING
.modalLoading .modal-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: none;
}
.form-control.is-invalid,
textarea.form-control.is-invalid {
  border: 2px solid $danger !important;
  box-shadow: none !important;
}

.border-left .custom-card {
  border-left: 1px solid $cinza2;
}

.list-horarios {
  background: linear-gradient(#fefeff 0%, #f0f5f9 100%);
  padding: 24px 10px;
  border-bottom: 1px solid #e0e5ea;
}

// ANIMATIONS
.fade-in {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
.badge {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  position: absolute;
  right: 12px;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 4px 2px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  top: 18px;
}

.badge-danger {
  background-color: $vermelho;
  border: 1px solid $vermelho1;
}

.container-meet {
  height: 90vh;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-in {
  animation-name: moveIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  // transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveIn {
  0% {
    transform: translate(0, -150%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.move-out {
  animation-name: moveOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  // transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -150%);
  }
}

.move-up {
  animation-name: moveUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes moveUp {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.move-down {
  animation-name: moveDown;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveDown {
  0% {
    transform: translate(0, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 1366px) {
  .listagem-custom .conteudo-listagem {
    padding: 0px;
  }
}

@media (max-width: 1280px) {
  .listagem-custom .conteudo-listagem {
    padding: 0px;
  }
}

//Media 1024
@media (max-width: 1024px) {
  .icon-filtro {
    bottom: 30px;
    left: 30px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: $shadow3;
  }

  body {
    margin-bottom: 100px;
  }
  .back-elements {
    background-size: 180%;
    background-position-x: 0;
    background-position-y: 0;
  }
  .hora-detalhe-row {
    width: 160px;
  }

  .logo-sasc-cm {
    width: 100%;
  }

  // BUTTONS
  .btn-secondary.save {
    color: $secondary;
  }
  .btn-secondary.delete {
    color: $danger;
  }
  .btn-secondary.open {
    color: $success;
  }

  .legends {
    width: initial;
  }

  .icon-filtro {
    bottom: 30px;
    left: 20px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: $shadow3;
  }
  .icon-indisponibilidade {
    bottom: 30px;
    left: 85px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: $shadow3;
  }
  .cardInfo {
    margin: 0;
  }
  .cardInfo p.title {
    text-align: center;
    margin-bottom: 20px;
  }
  .cardInfo p.instruction {
    margin-bottom: 20px;
  }
}

@media (max-width: 414px) {
  .toogleButtons .btn-toogle {
    padding: 10px 8px;
    width: initial;
    margin: 0px 3px;
  }
}
