.barra-filtro {
  position: relative;
  width: 98%;
  background-color: #fff;
  padding: 7px 15px;
  margin: 0 1%;
  z-index: 100;
}

.barra-filtro .icon-filtro {
  position: inherit;
  background: $cinza1;
  width: 40px;
  height: 40px;
  box-shadow: none;
}
.barra-filtro .icon-filtro img {
  fill: $primary;
  height: 16px;
  filter: brightness(1);
}

.barra-filtro .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 18px;
  font-weight: bold;
  margin: inherit;
}

.barra-filtro .dados-filtro p {
  margin-bottom: 0px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.barra-filtro .dados-filtro span.info-secundaria {
  font-size: $font-xs;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.barra-filtro .tipo-filtro span {
  font-size: $font-xs;
}
.barra-filtro .tipo-filtro img {
  filter: brightness(0);
}
.barra-filtro .icon-limpar-filtro {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .barra-filtro {
    bottom: 10px;
    top: inherit;
  }
}

//  Media Mobile
@media (max-width: 1023px) {
  .barra-filtro {
    // border-radius: $border-radius-min;
    position: fixed;
    top: inherit;
    bottom: 10px;
    margin-right: 14px;
  }
  .barra-filtro .dados-filtro {
    max-width: 90%;
  }
  .barra-filtro .dados-filtro p {
    width: 90%;
  }
  .barra-filtro .dados-filtro span.info-secundaria {
    width: 90%;
  }
}
//  Media Mobile
@media (max-width: 320px) {
  .barra-filtro .icon-filtro {
    display: none !important;
  }
}
