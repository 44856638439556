.boxButtonCadastro {
  margin: 0px;
  padding: 0px 20px;
  text-align: center;
}
.boxButtonCadastro .btn-secondary {
  border: 1px solid #d8dde3 !important;
  color: #000;
}
.by-1 {
  border-top: 1px solid #555353;
  border-bottom: 1px solid #555353;
}
.bb-1 {
  border-bottom: 1px solid #555353;
}
.bt-1 {
  border-top: 1px solid #555353;
}

.cadastro .form-inside .section-form div.form-group,
.cadastro .search-box.form-inside .section-form div.form-group {
  padding: 10px 10px;
}

.txt-cupom-desconto{
color: #03af72;
}