.tags{
  border: #f0f0f0 1px solid;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}

.tags:nth-child(even){
  background-color: #02cb7d;
}


.tags:nth-child(odd){
  background-color: #c0c0c0;
}

.icon-remove{
  content: url('./images/icon_x.svg');
  width: 20px;
  height: 20px;
  vertical-align: middle;
}