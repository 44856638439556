.dash-financeiro {
  width: 1100px;
  border: 1px #dfe2e6  solid;
  border-radius: 10px;
}
.dash-financeiro h1{
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 2px;
  color: #000;
  border-bottom: 1px #dfe2e6 solid;
}

.box-grafico{
  background-color:#ffff ;
}
.box-grafico h2{
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 2px;
}
.listagem-valores,.label-listagem{
  width: 900px;
  margin: 0 auto;
 
}
.label-listagem p{
  font-size: 12px;
  font-weight: 800;
  border-bottom: 1px #dfe2e6  solid;
  padding: 15px;
}
.listagem-valores{
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  border-bottom: 1px #dfe2e6  solid;
}
.listagem-valores:nth-child(odd){
  background-color: #eff1f3 ;
}