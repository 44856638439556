.prontuario {
  background-color: $branco;
  padding: 10px 30px 20px 10px;
}
.prontuario > div {
  flex-basis: 50%;
}

.btn span.icon-anexar {
  content: url('./images/icon-anexar.svg');
  width: 20px;
  height: 20px;
}

.prontuario .btn.btn-warning {
  color: $branco;
  background-color: #cb9e00;
}

.prontuario .nav .nav-item {
  margin: 10px auto 0px auto;
  min-width: 30%;
  cursor: pointer;
}

.prontuario .nav .nav-item .nav-link {
  padding: 10px 20px;
  border-color: #dee2e6 #dee2e6 #eff3f4;
}

.prontuario .nav-tabs {
  border-bottom: 0px;
}

.icon-receituario {
  content: url('./images/icon-receituario.svg');
  width: 25px;
  height: 25px;
}

.icon-procedimentos {
  content: url('./images/icon-procedimentos.svg');
  width: 25px;
  height: 25px;
}

.icon-exames {
  content: url('./images/icon-exames.svg');
  width: 25px;
  height: 25px;
}
.btn-incluir {
  background-color: #00cbcb;
  color: $branco;
}
.icon-incluir {
  content: url('./images/icon-incluir.svg');
}
.tabela-prontuario {
  border: 1px #f2f3f5 solid;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
}
.tabela-prontuario .row:nth-child(odd) {
  background-color: #f2fcf8;
}
.tabela-prontuario .row:nth-child(even) {
  background-color: #f2f3f5;
}

.icon-edit {
  content: url('./images/icon-edit.svg');
}

.tabela-prontuario .icon-delete {
  content: url('./images/icon-delete.svg');
  width: 20px;
  height: 20px;
}

.procedimentos .form-control,
.receituario-exame .form-control,
.modal-cids .form-control {
  height: 38px;
}

.modal-cids {
  max-width: 800px;
}

.box-list-cid {
  height: 600px;
  overflow: auto;
}

.item-list-cid {
  border: 1px #ededed solid;
  font-size: 15px;
  font-weight: 600;
}

.cabecalho-list-cid {
  border: 1px #cccc solid;
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 10000;
}

.cabecalho-list-cid p {
  font-size: 18px;
  font-weight: 800;
}
.btn span.icon-search {
  content: url('./images/icones/search.svg');
  width: 20px;
  height: 20px;
}

.btn span.ico_mais {
  content: url('./images/ico_mais-white.svg');
  width: 15px;
  height: 15px;
}

.btn span.icon-close {
  content: url('./images/icon_fechar_WHITE.svg');
  width: 15px;
  height: 15px;
}

@media (max-width: 414px) {
  .prontuario {
    padding: 5px;
    width: 100%;
  }

  .prontuario .nav .nav-item {
    margin: 1px;
  }
  .prontuario > div {
    flex-basis: 100%;
  }
  .prontuario .nav .nav-item .nav-link {
    padding: 5px;
  }
  .receituario-exame {
    width: 100%;
  }
  .anamnese {
    width: 100%;
  }
}
