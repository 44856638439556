.avatar {
  position: relative;
  z-index: 0;
}
.avatar.action {
  cursor: pointer;
}

.avatarCustom {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: $branco;
  background-color: $primary;
  font-size: 47px;
  margin: auto;
  z-index: 1;
}
.avatarCustom img {
  box-shadow: 0px 0px 0px 1px $branco;
  background-color: $branco;
}

// Avatar Listagem
.avatarCustom.avatar-list,
.avatarCustom.avatar-list .avatarCustom {
  width: 40px;
  height: 40px;
}
td .avatar-list-icon-prioridade .avatarCustom {
  width: 70px;
  height: 70px;
  font-size: 22px;
}
.avatar-list-icon-prioridade .avatarCustom > .avatarCustom {
  width: 70px;
  height: 70px;
}

.avatarCustom.avatar-list img {
  width: 40px;
  height: 40px;
}
.avatarCustom.avatar-list span {
  display: flex;
  font-size: $font-sm;
}

.icon-foto-user {
  background-color: $branco;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10;
}
.icon-foto-user img {
  height: 16px;
  position: absolute;
  right: 7px;
}

//  Media Mobile
@media (max-width: 1023px) {
  .icon-foto-user {
    right: 32%;
  }
  // Avatar Listagem
  .avatarCustom.avatar-list {
    width: 80px;
    height: 80px;
    margin: 10px auto;
  }
  .avatarCustom.avatar-list img {
    width: 80px;
    height: 80px;
  }
  .avatarCustom.avatar-list span {
    font-size: 34px;
  }

  // Avatar Listagem
  .Select .avatarCustom.avatar-list {
    width: 40px;
    height: 40px;
    margin: 10px auto;
  }
  .Select .avatarCustom.avatar-list img {
    width: 40px;
    height: 40px;
  }
  .Select .avatarCustom.avatar-list span {
    font-size: 24px;
  }
}
