.textCKeditor {
  // Barra de ferramentas
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border: 1px solid $cinza1;
    border-radius: $border-radius-min !important;
    background-color: $branco;
    padding: 0 20px;
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background-color: $cinza1;
  }

  //Conteúdo
  .ck.ck-content.ck-editor__editable {
    background-color: $branco;
    border-radius: $border-radius-min !important;
    min-height: 200px;
    border: 1px solid $cinza1 !important;
    margin: 10px 0 50px;
    padding: 10px 20px;
    font-size: $font-default;
  }
  .ck.ck-content.ck-editor__editable:focus {
    border: 1px solid $cinza2;
    box-shadow: none;
  }
  .ck.ck-content.ck-editor__editable .ck-placeholder:before {
    color: $cinza4;
    font-size: $font-default;
    font-weight: 200;
  }
}
.modal-body {
  .textCKeditor {
    .ck.ck-content.ck-editor__editable {
      margin: 5px;
      min-height: 350px;
    }
  }
}

// Inválido
.textCKeditor.is-invalid .ck.ck-content.ck-editor__editable {
  border: 2px solid $danger !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('images/invalid.svg');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
