.navbar {
  background-color: $default;
  height: 60px;
  padding: 15px 20px;
  z-index: 13;
}

.header-back-menu {
  width: 130px;
}

.header-icon {
  display: flex;
  width: 30px;
  height: 24px;
  padding: 0;
  border: 0 !important;
  outline: none !important;
}
.header-icon.menu-icon {
  background: url('./images/icones/grid.svg') no-repeat center !important;
}
.header-icon.back-icon {
  background: url('./images/icones/arrow-left.svg') no-repeat center !important;
}
.header-icon.close-icon {
  background: url('./images/icones/close.svg') no-repeat center !important;
}
.header-icon.task-icon {
  background: url('./images/icones/bell.svg') no-repeat center !important;
}
.header-icon.icon-sair {
  background: url('./images/icones/log-out.svg') no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.txt-header {
  font-size: $font-md;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 5px;
}
.txt-header.red {
  color: $danger;
}

.header-title {
  font-family: $font-family-default-2;
  font-size: $font-xl;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1023px) {
  .navbar {
    padding: 10px 20px;
    margin: 0 -14px;
  }
  .header-back-menu {
    width: 30px;
  }
  .header-icon {
    height: 25px;
  }
  .header-title {
    font-size: $font-md;
    width: 200px;
  }

  .button-logout {
    width: 30px;
  }
  .button-logout .header-icon {
    height: 30px;
  }
}

// .mais-icon {
//   background-image: url("../../images/ico_mais.svg") !important;
// }
