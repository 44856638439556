// CALENDAR
.calendar {
  max-width: 800px;
  margin: auto;
}

.agenda-online-content .calendar {
  margin: initial;
}
.calendar .arrowMonth {
  padding: 0;
  text-align: center;
}
.calendar .arrowMonth img {
  margin-top: 18px;
  height: 20px;
  cursor: pointer;
}

.calendar .month {
  font-family: $font-family-default-2;
  font-size: $font-md;
  text-align: center;
  margin: 0 auto 30px;
  // text-decoration: underline;
  cursor: pointer;
  background-color: #f8f8f8;
  color: $preto;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  max-width: 250px;
  padding: 10px 0;
  height: 90px;
}
.calendar .week {
  font-size: $font-default;
  text-align: center;
}
.calendar .week > div {
  max-width: 100px;
  width: 100px;
  margin: 5px;
}

.calendar-date {
  text-align: center;
  width: 100px;
  height: 100px;
  padding: 0;
  margin: 5px;
  border: 1px solid $branco;
  background: #f8f8f8;
  border-radius: $border-radius-btn;
  box-shadow: $shadow2;
  max-width: 100px;
  transition: all 500ms;
  cursor: pointer;
}
.calendar .calendar-date:hover {
  background: $branco;
  // border: 1px solid $cinza3;
}

.calendar-date.view {
  cursor: default;
}

.calendar-date .font-date {
  font-size: $font-xxl;
  font-weight: 600;
  margin: 0;
}
.calendar-date .font-details {
  font-size: $font-xs;
  color: $cinza5;
  margin-bottom: -3px;
  min-height: 15px;
  border-top: 1px solid $default;
  padding-top: 4px;
  margin: -4px -20px 0;
}
.calendar-date .font-details:last-child {
  margin: -6px 0 0;
  border: none;
}
.calendar-date .font-details.bold {
  font-weight: bold;
}
.calendar-date .red {
  color: $danger !important;
}
.calendar-date .font-details.italic {
  font-style: italic;
  font-weight: 300;
}

.calendar-date.date-actual {
  opacity: 1 !important;
  border: 2px solid $primary !important;
  background: $branco;
}
.calendar-date .disponivel {
  color: $primary;
  font-weight: 600;
  margin: -12px 0 0 !important;
}
.calendar-date.agenda-vazia {
  background: none;
  box-shadow: none;
  opacity: 0.4;
  border: 1px solid $cinza3;
}
.calendar-date.agenda-vazia:hover {
  background: $cinza1;
  border: 1px solid $cinza4;
  opacity: 1;
}
.calendar-date .font-details.concluido {
  margin: -10px 0 6px;
}
.calendar-date .font-details.nenhuma-agenda {
  border: none;
  margin: -10px 0 -2px;
}

.calendar-date .font-date.big {
  margin-top: 2px;
}
.calendar-date .nome-mes {
  font-size: $font-xs;
  text-transform: capitalize;
  margin-bottom: 1px;
  font-weight: 600;
  margin: -6px 0 -2px;
}
.calendar-date .ano {
  font-size: $font-label;
  color: $cinza4;
  margin: 0;
}
.calendar-date .diaSemana {
  font-size: 10px;
  color: #02cb7d;
  font-weight: bold;
  margin: 0;
  line-height: 9px;
  margin-top: 4px;
}

.calendar-date .prev,
.calendar-date .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  margin: -8px;
  height: 70%;
  transition: all 250ms;
  cursor: pointer;
}
.calendar-date .prev {
  border-right: 1px solid transparent;
}
.calendar-date .prev:hover {
  border-right: 1px solid $cinza2;
}
.calendar-date .next {
  border-left: 1px solid transparent;
}
.calendar-date .next:hover {
  border-left: 1px solid $cinza2;
}
.calendar-date .arrows {
  width: 10px;
  height: 15px;
  margin: 2px;
  cursor: pointer;
  // filter: opacity(0.3);
  transition: all 250ms;
}
.calendar-date .prev:hover .arrows,
.calendar-date .next:hover .arrows {
  filter: opacity(1);
}

.lista-horarios {
  position: sticky;
  top: 60px;
  z-index: 11;
  background-color: #eff3f4;
  padding-bottom: 20px;
}
.lista-horarios .calendar-date {
  text-align: center;
  width: 400px;
  border-radius: 0px;
  border-bottom-left-radius: $border-radius-btn;
  border-bottom-right-radius: $border-radius-btn;
  max-width: 400px;
  margin: 0px;
  box-shadow: none;
}

// CALENDAR PICKER
.month-picker {
  width: 100%;
}
.month-picker > div {
  width: 100%;
}
.month-picker > .rmp-container .rmp-overlay {
  background: none;
  // transition: all 1sms;
  // z-index: 1002;
}
.month-picker > .rmp-container .rmp-cell {
  display: flex;
  justify-content: center;
}
.month-picker > .rmp-container .rmp-popup.light {
  background: $branco;
  border-radius: $border-radius-btn;
  box-shadow: $shadow-modal;
  border: none;
  // right: 192px;
  padding: 20px;
  z-index: 1003;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  color: $preto;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  background: url('./images/icones/arrow-left.svg') no-repeat center;
  background-size: 40px 20px;
  color: transparent;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i:hover {
  background-color: transparent !important;
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
  transform: rotate(180deg);
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  font-size: $font-md;
  font-weight: 600;
  color: $preto;
  border-radius: $border-radius;
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
  background: none;
  color: transparent;
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: $primary;
  font-weight: 800;
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
  background-color: $cinza1;
}

//  Media Mobile
@media (max-width: 1023px) {
  //CALENDAR
  .calendar {
    max-width: 340px;
  }
  .calendar .arrowMonth img {
    margin-top: 20px;
    height: 16px;
  }
  .calendar .week > div {
    padding: 0 5px;
  }

  .calendar-date {
    height: 38px;
    box-shadow: $shadow3;
    border: none;
  }
  .calendar-date.view {
    height: 70px;
    min-width: 100%;
    margin: 0;
    background: $branco;
    box-shadow: none;
  }

  .calendar-date .arrows {
    width: 20px;
    height: 16px;
    // filter: opacity(1);
  }
  .calendar-date .font-date {
    font-size: $font-sm;
    font-weight: 700;
    margin: 2px 0 0;
  }
  .calendar-date .font-date.big {
    font-size: $font-xxl;
    margin: 0 6px 0 10px;
  }
  .calendar-date .info-fixed-date p {
    text-align: left;
  }
  .calendar-date .nome-mes {
    font-size: $font-default;
    font-weight: 700;
    text-align: left;
    margin-right: 4px;
    margin-top: -4px;
  }
  .calendar-date .ano {
    font-size: $font-xs;
    text-align: left;
    margin: 0;
  }

  .calendar-date.date-actual {
    box-shadow: none;
    border: 1px solid $primary !important;
    color: $primary;
  }

  .month-picker .rmp-container .rmp-popup {
    left: 0px;
  }

  // CALENDAR PICKER
  .month-picker > .rmp-container .rmp-overlay {
    background: none;
  }
  .month-picker > .rmp-container .rmp-popup.light {
    margin: 0 5% 5% 5%;
    width: 90%;
    height: 250px;
  }
}
