// BACKGROUND
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 20000 !important;
}

.react-confirm-alert {
  width: 400px;
  padding: 30px;
  background: $cinza1;
  border-radius: $border-radius;
  // box-shadow: $shadow2;
  color: $preto;
}
.react-confirm-alert img {
  width: 100%;
  height: 80px;
  margin: auto auto 10px;
}
.react-confirm-alert h2 {
  text-align: center;
  font-size: $font-md;
  font-weight: 800;
}
.react-confirm-alert .body-confirm {
  font-size: $font-default;
  width: 100%;
  margin: auto;
  text-align: center;
}
.react-confirm-alert .alert-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  width: 100%;
}

.alert-absolute {
  position: absolute;
  border-radius: 20px;
  z-index: 1000;
  padding: 20px;
  width: 80vh;
  text-align: center;
}

//  Media Mobile
@media (max-width: 1023px) {
  .react-confirm-alert {
    width: 85%;
    padding: 20px;
  }
  .react-confirm-alert .body-confirm {
    width: 100%;
  }
}
