.valor-total{
    border-bottom: 2px solid #ccc;
    border-color: black;
    line-height: 1.5 ;
    font-weight: 400;
    padding: 0 5px;
    height: 35px;
    border-width: 2px;
    color: #000000;
    font-size: 14px;
}
.label-valor-total{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0;
    color:#000000;
    font-size: 12px;
    display: inline-block;
    padding: 0px 6px;
    font-style: italic;

}