// AGENDA
.barra-filtro ~ .fixed-date,
.barra-filtro ~ .agenda,
.barra-filtro ~ .calendar {
  margin-top: 80px;
}

.agenda {
  // max-width: 900px;
}
.agenda .date {
  color: $branco;
  font-size: $font-lg;
  text-transform: capitalize;
  font-weight: bold;
}
.agenda .month {
  font-size: $font-lg;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 30px;
}
.agenda .font-details {
  font-size: $font-default;
  color: $preto;
  margin-bottom: -3px;
}
.agenda .font-details.count {
  font-weight: bold;
}
.agenda .margin {
  background: $default;
  height: 115px;
  width: 50%;
  top: 80px;
  position: fixed;
  z-index: 98;
}

// .doctor.cardInfo img {
//   height: 90px;
// }
// .doctor.cardInfo p.title {
//   margin: 0;
//   font-size: $font-sm;
//   font-weight: 800;
//   padding-left: 20px;
// }
// .doctor.cardInfo p.instruction {
//   margin: 0;
//   padding-left: 20px;
// }

.fixed-date .calendar-date {
  position: fixed;
  cursor: default;
  margin-top: 14px;
  border: 1px solid $branco !important;
}
.doctor {
  margin-top: 5px;
}
.agenda .doctor {
  border-left: 1px solid $cinza3;
}

.filter.agenda .doctor {
  justify-content: center;
  border-left: none;
}

.doctor .top {
  display: flex;
  justify-content: space-between;
  background: $default;
  z-index: 9;
  top: 185px;
  width: 100%;
  height: 60px;
  // padding-top: 20px;
  align-items: center;
  border-radius: 0;
  position: sticky;
}
.doctor .top .background {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 70px;
  z-index: -1;
  border-top: 2px solid #2a444e;
}

.doctor .top .photo-name {
  padding-left: 20px;
}
.doctor .top .photo-name .avatar {
  margin-right: 1px;
}
.doctor .top .photo-name .avatar .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 24px;
}
.doctor .top .title {
  padding-left: 10px;
  text-align: left;
  width: 100%;
}
.doctor .top .title .name {
  font-size: $font-default;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
.doctor .top .title .info {
  font-size: $font-xs;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.doctor .count {
  font-size: $font-default;
  font-weight: bold;
  text-align: right;
  padding-right: 30px;
}

.doctor .schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $branco;

  margin-bottom: 50px;
}

.agenda-online-content {
  .doctor .top {
    display: flex;
    justify-content: space-between;
    background: $default;
    z-index: 10;
    top: 65px;
    width: 100%;
    height: 60px;
    // padding-top: 20px;
    align-items: center;
    border-radius: 0;
    position: sticky;
  }
  .doctor .barra-info {
    background-color: #f8f8f8;
    font-size: 11px;
    color: #ababab;
    position: -webkit-sticky;
    position: sticky;
    top: 125px;
    z-index: 1;
  }
}

.schedules .row {
  padding: 0px 20px;
}

.schedules .row:nth-child(odd) {
  background-color: #fff;
}
.schedules .row:nth-child(even) {
  background-color: #e3e7e8;
}
.doctor .barra-info {
  background-color: #f8f8f8;
  font-size: 11px;
  color: #ababab;
  position: sticky;
  top: 245px;
  z-index: 1;
}

.label-custom {
  padding: 3px 12px;
  margin: 0px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: normal;
}

.label-particular {
  background-color: #2a444e;
  color: #fff;
}

.label-especialidade {
  background-color: #00cb7ca6;
  color: #04613d;
}
.label-convenio {
  background-color: #fff;
  color: #2a444e;
  outline: 1px solid #2a444e;
  outline-offset: -1px;
}
.label-cortesia {
  background-color: #8f9aa0;
  color: #fff;
}
.doctor .timetable {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $cinza2;
  width: 100%;
  min-height: 60px;
}

.doctor .timetable .Select .react-select__placeholder,
.doctor .timetable .Select .react-select__single-value {
  margin: 0 0 0 15px;
}

.doctor .encaixe.timetable {
  border-top: 1px solid $cinza2;
  border-bottom: 0px solid $cinza2;
}
.doctor .timetable:last-child {
  // border-bottom: none;
  // padding: 0 !important;
}
.doctor .timetable .hour {
  font-size: $font-md;
  font-weight: 300;
  width: 50px;
  margin: 0px 5px 0px 0px;
}
.doctor .timetable .patient {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
  margin: 15px 0;
}
.doctor .timetable .patient .select {
  padding: 0;
  width: 100%;
}
.doctor .timetable .patient .name {
  font-weight: bold;
}

.doctor .timetable .actions {
  padding: 0;
  display: flex;
  align-items: center;
}
.doctor .timetable .actions .btn {
  // box-shadow: none;
}

// Media 1024
@media (max-width: 1024px) {
  .barra-filtro ~ .fixed-date,
  .barra-filtro ~ .agenda,
  .barra-filtro ~ .calendar {
    margin-top: 0;
  }
}

//  Media Mobile
@media (max-width: 1023px) {
  .fixed-date .calendar-date {
    position: initial;
    margin-top: 14px;
    height: 80px;
    max-width: 95%;
    background: #fff;
    box-shadow: none;
  }

  .agenda .doctor {
    border-left: none;
  }
  // .doctor .top {
  //   height: 250px;
  //   top: 60px;
  // }
  .doctor .top .background {
    height: 250px;
  }
  .doctor .barra-info {
    top: 185px;
    display: none;
  }
  .actions .btn {
    padding: 0 10px;
  }
  .doctor .top .photo-name .avatar .avatarCustom {
    width: 60px;
    height: 60px;
  }
  .doctor .top .title .name {
    text-align: center;
    margin: 10px 0 0;
    max-width: 100%;
  }
  .doctor .top .title .info {
    text-align: center;
    max-width: 100%;
  }

  .doctor.sem-horarios {
    width: 100%;
    margin: 10px auto 0;
  }
  .doctor.sem-horarios img {
    margin-bottom: 10px;
  }
  .doctor.sem-horarios p.title {
    text-align: center;
    margin-bottom: 10px;
    line-height: 20px;
    padding: 0 10px;
  }
  .doctor .schedules {
    padding: 0px;
  }
  .doctor.sem-horarios p.instruction {
    margin: 0;
    padding: 0 10px;
  }
  .doctor .timetable {
    padding: 0px 10px 20px 10px;
  }
  .doctor .timetable .hour {
    font-size: $font-xl;
    font-weight: 100;
    margin: 0px 5px 0 0;
    width: 60px;
  }

  .doctor .timetable .patient {
    margin: 10px auto;
    width: 100%;
    text-align: center;
  }
  .doctor .timetable .patient .dados-user {
    justify-content: center !important;
    text-align: left;
  }
  .doctor .timetable .actions {
    justify-content: flex-end;
  }
}
