.modal-impressao {
  display: flex;
  justify-content: center;
  .min-heigth-modal {
    min-height: 580px;
  }
  .modal-content {
    padding: 30px;
    background: $cinza1;
    border-radius: $border-radius;
    color: $preto;
    border: none;
    box-shadow: $shadow-modal;
  }
  .modal-body {
    padding: 0 20px;

    .nome,
    .posologia {
      margin-bottom: 0px;
    }
    .nome {
      font-weight: bold;
    }
    .flag-impresso {
      color: $primary;
      font-size: $font-xs;
      font-style: italic;
    }
    .data {
      color: $preto;
      font-size: $font-label;
      font-style: italic;
    }
    .flag-nao-impresso {
      color: $danger;
      font-size: $font-xs;
      font-style: italic;
    }
  }
  .top-modal {
    margin-bottom: 20px;
  }

  hr {
    margin: 5px 0 5px;
  }

  .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .modal-impressao {
    margin: 0 20px 0 10px;
  }
}
