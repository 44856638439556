.prontuario-box {
  margin-top: 20px;
  .col-esquerda,
  .col-direita {
    padding: 0px 21px;
    z-index: -1;
  }
  .calendar-date {
    width: 300px;
    height: 80px;
    max-width: 300px;

    .font-date {
      font-size: 22px;
    }
  }
  .col-direita {
    width: 152px;
    min-width: 152px;
  }
  .col-esquerda {
    // border-right: 1px solid $cinza3;

    .avatar {
      margin-left: 4px;
      z-index: 100;
    }
    .calendar-date {
      cursor: default;
    }
    .calendar-date {
      margin-top: 120px;
    }
    .calendar-date:hover {
      border: 1px solid $branco;
    }
  }

  hr {
    margin: 10px 0;
  }

  .view-doencas {
    font-weight: bold;
    margin: -5px 35px 0;
    font-style: italic;
  }

  .descricao-anamnese p,
  .descricao-anamnese ul,
  .descricao-anamnese ol {
    font-size: $font-default;
    margin: 0 15px;
  }

  .buttons-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .buttons-bottom .btn {
    min-width: 165px;
  }

  .buttons-bottom .btn-secondary {
    box-shadow: none;
  }

  span.icon-btn-receituario {
    content: url('./images/icon-receituario.svg');
    width: 20px;
    margin-bottom: -5px;
    margin-right: 0px;
  }

  .buttons-bottom .destaque {
    font-size: $font-xs;
  }

  span.icon-btn-anexar {
    content: url('./images/icon_anexar.svg');
    width: 20px;
    margin-bottom: -5px;
    margin-right: 0px;
  }
  .buttons-bottom span.icon-btn-imprimir {
    content: url('./images/icon-print.svg');

    float: initial;
  }

  // RECEITUARIO
  .receituario button {
    box-shadow: none;
  }
  .receituario .receituario-label-buttons label {
    margin-top: 10px;
    font-size: $font-xs;
    color: $primary;
  }
  .receituario-content {
    margin-bottom: 65px;
    // overflow-y: auto;
    // overflow-x: hidden;
    min-height: 300px;
  }
  .receituario-content .receituario-info {
    text-align: center;
    max-width: 300px;
    margin: 40px auto;
    color: $cinza4;
    font-weight: 200;
  }
  .receituario .receituario-buttons button {
    margin: 5px;
  }

  .box-folhas {
    // min-width: 1065px;
    // max-width: 1065px;
  }
  .footer-folhas {
    margin: 5px 21px;
    max-width: 100%;
  }
}

.prontuario-box:last-child {
  margin-bottom: 80px;
}

.prontuario-box-sintetico {
  margin-top: 0;
}

.prontuario-box-sintetico:last-child {
  margin-bottom: 0;
}

// 1280
@media (max-width: 1280px) {
  .prontuario-box {
    .box-folhas {
      min-width: 946px;
    }
  }
}

// 1024
@media (max-width: 1024px) {
  .prontuario-box {
    .box-folhas {
      min-width: 994px;
    }
    .col-esquerda,
    .col-direita {
      border: none;
    }
    .col-esquerda .calendar-date {
      margin: 0 0 10px 40px;
      width: 220px;
      max-width: 220px;
    }
    .col-esquerda .avatar {
      margin-left: 2px;
      z-index: 1;
    }
  }
}
// Media Mobile
@media (max-width: 1023px) {
  .prontuario-box {
    .col-esquerda {
      margin-bottom: 10px;
      padding: 0 0 10px 0;
    }
    .col-esquerda .calendar-date {
      margin: 14px auto 0;
      height: 80px;
      max-width: 100%;
      width: 95%;
      background: #fff;
      box-shadow: none;
    }
    .col-esquerda .calendar-date .font-date {
      font-size: $font-xxl;
      margin: 0 6px 0 10px;
    }

    .box-folhas {
      min-width: 0;
      width: 100%;
      min-width: 0;
      z-index: 10;
    }
  }
}

.anamnese .table td{
  padding:5px;
}