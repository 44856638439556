// // TOAST GERAL
// .redux-toastr .top-center .toast-marph,
// .redux-toastr .bottom-center .toast-marph {
//   width: 163%;
//   left: -32%;
// }
.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 100%;
  padding: 0px;
}

.redux-toastr .toastr.toast-marph {
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  opacity: 1;
  box-shadow: none !important;
  width: 98%;
  border-radius: 35px;
  min-height: 70px;
  text-align: center;
  margin: 0 1% 6px;
}

// Icone
.redux-toastr .toastr.toast-marph .rrt-left-container {
  width: 50px;
  left: 30px;
}
.redux-toastr .toastr.toast-marph .rrt-left-container .rrt-holder {
  width: 40px;
}
.toast-marph .icon_error,
.icon_success,
.icon_pendencia,
.icon_warning,
.icon_info {
  padding: 5px 0 0;
  margin-left: 12px;
}

// Texto
.redux-toastr .toastr.toast-marph .rrt-middle-container {
  margin: auto;
  font-family: $font-family-default;
  font-size: $font-default;
  font-weight: 700;
  line-height: 18px;
  width: 100%;
  text-align: center;
}
.redux-toastr .toastr.toast-marph .rrt-middle-container .rrt-title {
  // font-family: $font-family-default;
  // font-size: $font-default;
  // font-weight: 400;
  color: $branco;
}

// Botão fechar
.toastr.toast-marph .close-toastr {
  font-size: 12px;
  color: $branco;
  opacity: 1;
  margin-right: -2%;
  cursor: pointer;
}

// Barra de progressão
.redux-toastr .toastr.rrt-error.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-success.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-warning.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-pendencia.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-info.toast-marph .rrt-progressbar {
  background-color: #0000000f;
}

// TOAST ERRO
// Cor principal
.redux-toastr .toastr.rrt-error.toast-marph-erro {
  background-color: $danger;
  // display: inline-table;
}
// .redux-toastr .toastr.rrt-error.toast-marph-erro:hover {
//   box-shadow: none;
// }

// TOAST SUCCESS
// Cor principal
.redux-toastr .toastr.rrt-success.toast-marph-success {
  background-color: $primary;
  // display: inline-table;
}
// .redux-toastr .toastr.rrt-success.toast-marph-success:hover {
//   box-shadow: none;
// }

// TOAST WARNING
// Cor principal
.redux-toastr .toastr.rrt-warning.toast-marph-warning {
  background-color: $warning;
  // display: inline-table;
}
// .redux-toastr .toastr.rrt-warning.toast-marph-warning:hover {
//   box-shadow: none;
// }

// TOAST PENDENCIA
// Cor principal
// .redux-toastr .toastr.rrt-success.toast-marph-pendencia {
//   background-color: $cor2;
//   display: inline-table;
// }

// .redux-toastr .toastr.rrt-pendencia.toast-marph-pendencia:hover {
//   box-shadow: none;
// }

// TOAST INFO
// Cor principal
.redux-toastr .toastr.rrt-info.toast-marph-info {
  background-color: $secondary;
  // display: inline-table;
}
.redux-toastr .toastr.rrt-info.toast-marph-info .rrt-text,
.redux-toastr .toastr.rrt-info.toast-marph-info .rrt-title {
  color: $branco;
}

.redux-toastr .toastr.toast-marph.toast-marph-info .close-toastr {
  color: $branco;
}
.redux-toastr .toastr.rrt-info.toast-marph-info:hover {
  box-shadow: none;
}

// TOAST INFO BLACK
// Cor principal
.redux-toastr .toastr.rrt-info.toast-marph-info-black {
  background-color: $branco;
  // display: inline-table;
}

.redux-toastr .toastr.rrt-pendencia.toast-marph-pendencia:hover {
  box-shadow: none;
}

.redux-toastr .toastr.rrt-info.toast-marph-info:hover {
  box-shadow: none;
}

.toast-marph {
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  left: 50%;
  margin-left: -254px;
  width: 509px;
  height: 50px;
  border-radius: 4px;
  margin-top: 9px;
}

.toast-marph {
  position: fixed;
  z-index: 99999999;
  padding: 0 5px;
  left: 0%;
  margin-left: auto;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  margin-top: 9px;
}

// Toast
.icon_error,
.icon_sucess,
.icon_pendente_WHITE,
.icon_warning,
.icon_pendencia,
.icon_info,
.icon_fechar {
  max-width: 20px;
}

//  Media Mobile
@media (max-width: 1023px) {
  .redux-toastr .top-center .toast-marph,
  .redux-toastr .bottom-center {
    width: 100%;
    left: 0;
  }
  .redux-toastr .toastr.rrt-info.toast-marph-info,
  .redux-toastr .toastr.rrt-error.toast-marph-erro,
  .redux-toastr .toastr.rrt-pendencia.toast-marph-pendencia,
  .redux-toastr .toastr.rrt-warning.toast-marph-warning,
  .redux-toastr .toastr.rrt-success.toast-marph-success {
    margin: 0 1% 2%;
  }
  // Texto
  .redux-toastr .toastr.toast-marph .rrt-middle-container {
    width: 76%;
    margin: 0 12%;
    top: 4px;
    padding: 0;
  }
  // Botão fechar
  .redux-toastr .toastr.toast-marph .close-toastr {
    // display: none;
    margin: 0 10px;
  }

  .toast-marph {
    position: fixed;
    z-index: 99999999;
    padding: 0 5px;
    left: 0%;
    margin-left: auto;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-top: 9px;
  }
}
