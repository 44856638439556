.dados-user {
  min-width: 0;
  .avatar .avatarCustom {
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
  .info-secundaria {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nome {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min-0 {
    min-width: 0;
  }
}
