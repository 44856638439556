.react-select {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
}

.react-select-trigger {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 31px;
  padding: 6px 27px 6px 6px;
  font-size: 14px;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #d3d4d4;
  outline: 0;
  cursor: pointer;
  position: relative;
  background: {
    color: #fff;
    image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 21 21"><polygon points="10.5,12 7,8.5 14,8.5"/></svg>');
    repeat: no-repeat;
    position: calc(100% - 5px) center;
  }

  &,
  &:active {
    color: black;
  }

  &.react-select-enabled {
  }

  &.react-select-target-attached-top {
    border-radius: 0 0 3px 3px;
  }

  &.react-select-target-attached-bottom {
    border-radius: 3px 3px 0 0;
  }

  // truncate children with ellipsis
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.react-select-trigger__option {
  user-select: none;

  & + & {
    margin-left: 6px;
  }

  .react-select-trigger--multiple & {
    padding: 0 3px;
    border-radius: 2px;
    background: #e6f9ff;
  }
}

.react-select-trigger__arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.react-select-menu {
  max-height: 180px;
  padding: 3px 0;
  border: 1px solid #e1e1e1;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px 0 rgba(218, 221, 222, 0.35);
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;

  .react-select-enabled & {
    pointer-events: auto;
  }

  .react-select-element-attached-top & {
    margin-top: -1px;
    border-top: 0;
  }

  .react-select-element-attached-bottom & {
    margin-top: 1px;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 -2px 4px 0 rgba(218, 221, 222, 0.35);
  }
}

.react-select-header {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #f1f3f5;
  border-top: 1px solid #f1f3f5;
}

.react-select-btn {
  flex: 1;
}

.react-select-options {
  padding: 0;
  margin: 0;
  list-style: none;
}

.react-select-option {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  text-indent: 4px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #fbfbfb;
  }
}

.react-select-option__label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-select-optgroup {
  display: block;
  padding: 3px 0;
  cursor: default;
  border-top: 1px solid #f1f3f5;

  &:first-child {
    border: 0;
  }
}

.react-select-optgroup__title {
  display: block;
  padding: 8px 12px 6px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #909294;
  user-select: none;
}
