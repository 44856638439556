#wrapper {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  flex: 0 0 0;
  max-width: 0;
  flex-shrink: 1;
  background-color: white;
}

#wrapper.toggled #sidebar {
  flex-grow: 1;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

#sidebar > div {
  overflow-y: auto;
  overflow-x: hidden;
}
