.conteiner-triagem{
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
}
.conteiner-classificacao {
    border: 1px #cccc solid ;
    border-radius: 10px;
}
.modalCrud.modal-filaAtendimento .modal-content {
    max-width: 1000px;
}
.btn-classificacao {
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 800;
  height: 40px;
  width: 100%;
  transition: all 250ms;
  overflow: hidden;
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
  color: #fff;
}
.btn-classificacao:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-sem-dor {
  background-color: #1C66C7;
  border-color: #1C66C7;
}
.btn-sem-dor:hover {
  color: #fff;
  background-color: #154C94;
  border-color: #154C94;
}

.card-classificacao-sem-dor{
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    background-color:#1C66C7 ;
    border: 1px #154C94 solid;
    border-radius: 10px;
    margin-top: 5px;
   
}


.btn-dor-leve {
    background-color: #149E61;
    border-color: #149E61;
  }
  .btn-dor-leve:hover {
    color: #fff;
    background-color: #0E6B41;
    border-color: #0E6B41;
  }
  
  .card-classificacao-dor-leve{
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 10px;
      background-color:#149E61 ;
      border: 1px #0E6B41 solid;
      border-radius: 10px;
      margin-top: 5px;
     
  }

  .btn-dor-moderada {
    background-color: #EBCD26;
    border-color: #EBCD26;
  }
  .btn-dor-moderada:hover {
    color: #fff;
    background-color: #9E8919;
    border-color: #9E8919;
  }
  
  .card-classificacao-dor-moderada{
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 10px;
      background-color:#EBCD26 ;
      border: 1px #9E8919 solid;
      border-radius: 10px;
      margin-top: 5px;
     
  }
  .btn-dor-intensa {
    background-color: #9E0700;
    border-color: #9E0700;
  }
  .btn-dor-intensa:hover {
    color: #fff;
    background-color: #6B0500;
    border-color: #6B0500;
  }
  
  .card-classificacao-dor-intensa{
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 10px;
      background-color:#9E0700 ;
      border: 1px #6B0500 solid;
      border-radius: 10px;
      margin-top: 5px;
     
  }
  .conteiner-triagem .relato-text-area {
      min-height: 150px;
  }

  .opacity{
      opacity: 0.2;
  }