.react-datepicker {
  font-family: $font-family-default;
  font-size: $font-default;
  background-color: $branco;
  color: $preto;
  border-radius: $border-radius-min;
  border: none;
}
// .react-datepicker__input-container input {
//   text-align: center;
// }

// HEADER
.react-datepicker__triangle {
  border-bottom-color: $cinza2;
}
.react-datepicker__triangle::before {
  border-bottom-color: transparent !important;
}
.react-datepicker__header {
  background-color: $cinza2;
  border: none;
  border-radius: 10px;
}

// CONTEUDO
// Horas
.react-datepicker__time-list-item {
  transition: all 250ms;
  padding: 0 5px;
  margin: 0 8px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $branco !important;
  color: $primary;
  border-radius: $border-radius-min;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: $cinza1;
  // color: $preto;
  border-radius: $border-radius-min;
}

// Data
.react-datepicker__day {
  transition: all 250ms;
}
.react-datepicker__day--today {
  color: $primary;
  background-color: $branco;
}
.react-datepicker__day:hover {
  background-color: $cinza1;
  color: $preto;
  border-radius: $border-radius-min;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: $primary !important;
  color: $branco !important;
  border-radius: $border-radius-min;
}
