.iconLocal img {
  height: 60px;
  mix-blend-mode: multiply;
  margin-bottom: 20px;
}

.modal-consulta-avulsa label {
  margin-left: 0px;
  font-size: 12px;
}
