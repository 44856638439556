.cabecalhoFundo {
  background-color: #ffffff;
  display: flex;
  min-height: 200px;
}

.lbl-tipo-atendimento {
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid #56cb7d;
  border-radius: 20px;
  margin: 5px 15px;
  color: #56cb7d;
}
.list-anexo-paciente {
  font-size: 14px;
  font-weight: bold;
}
.list-anexo {
  font-size: 11px;
  margin: 15px 0px 20px 0px;
  overflow-y: auto;
  hr {
    margin: 5px 0;
  }
}
.nome-anexo {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
.list-anexo-icon {
  width: 15px;
  margin-right: 5px;
}

.desc-anexo {
  font-size: 10px;
}
.cabecalho_img {
  border-radius: 100%;
  width: 68%;
  padding: 15px;
}

.cabecalho_img2 {
  border-radius: 50%;
  width: 5%;
}

.cabecalho_div {
  margin-left: -33px;
  margin-right: -100px;
}

.cabecalho_ul {
  list-style: none;
  display: contents;
  align-items: start;
}

.cabecalho_li {
  display: flex;
  align-items: flex-end;
}

.fonte_especialdade {
  font-size: 9px;
}
.dadosPaciente {
  font-size: 14px;
  border-right: 1px solid #ccc;
}

.dadosPaciente div {
  margin-top: 1px;
}
.infoPaciente {
  font-size: 14px;
}

.cabecalho-paciente-nome {
  font-size: 24px;
  font-weight: bold;
}
.cabecalho-paciente-ico-text img {
  margin-right: 5px;
}

.cabecalho-paciente-data {
  font-weight: bold;
}

@media (max-width: 1366px) {
}

@media (max-width: 1280px) {
}

//Media 1024
@media (max-width: 1024px) {
  .cabecalhoFundo {
    height: 100%;
  }
}

@media (max-width: 414px) {
  .cabecalhoFundo {
    height: 100%;
  }
  .cabecalho-paciente-avatar .avatarCustom {
    width: 60px;
    height: 60px;
    font-size: 27px;
  }

  .cabecalho-paciente-nome {
    font-size: 16px;
  }
  .cabecalho-paciente-data {
    font-size: 14px;
  }
  .cabecalho-paciente-ico-text img {
    height: 15px;
    margin-right: 5px;
  }
  .lbl-tipo-atendimento {
    font-size: 13px;
    padding: 5px 10px;
    border: 1px solid #56cb7d;
    border-radius: 20px;
    margin: 0px 15px 15px 15px;
    color: #56cb7d;
  }
}
