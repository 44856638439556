.modal-filaAtendimento.modal-lg.modal-dialog {
  display: flex;
  justify-content: center;
  min-width: 700px;
  max-width: 1500px;
}

.btn-custom {
  padding: 3px 12px;
  margin: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: normal;
  background-color: #02cb7d;
  color: #ffff;
  display: inline;
  position: relative;
  border: 2px solid #eff3f4;
  transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}

.btn-custom:hover {
  color: #428ee6 !important;
  background-color: transparent;
}
.card-paciente-agenda {
  margin: 10px;

  margin-top: 0;
  background-color: #03cc7e;
  border-radius: 20px;
}
.card-paciente-sem-agenda {
  margin: 10px;

  margin-top: 0;
  background-color: #a3cc00;
  border-radius: 20px;
}
.label-card-paciente h3 {
  color: #ffff;
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  letter-spacing: 1px;
}
.nome-paciente {
  margin-left: 5px;
  text-transform: uppercase;
  color: #244431;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
}
.avatar-card {
  border: 1px #ffff solid;
}
.icon-agenda {
  max-width: 25px;
  margin-right: 15px;
}
.clock-icon {
  max-width: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.next-patient-details p{
  font-size: 15px;
  letter-spacing: .5px;
  font-weight: 700;
  color: #ffff;
 
}