// AGENDA
.barra-filtro ~ .fixed-date,
.barra-filtro ~ .listaTarefas,
.barra-filtro ~ .calendar {
  margin-top: 80px;
}

.listaTarefas {
  // max-width: 900px;
}
.listaTarefas .date {
  color: $branco;
  font-size: $font-lg;
  text-transform: capitalize;
  font-weight: bold;
}
.listaTarefas .month {
  font-size: $font-lg;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 30px;
}
.listaTarefas .font-details {
  font-size: $font-default;
  color: $preto;
  margin-bottom: -3px;
}
.listaTarefas .font-details.count {
  font-weight: bold;
}
.listaTarefas .margin {
  background: $default;
  height: 115px;
  width: 50%;
  top: 80px;
  position: fixed;
  z-index: 98;
}

// .tarefas.cardInfo img {
//   height: 90px;
// }
// .tarefas.cardInfo p.title {
//   margin: 0;
//   font-size: $font-sm;
//   font-weight: 800;
//   padding-left: 20px;
// }
// .tarefas.cardInfo p.instruction {
//   margin: 0;
//   padding-left: 20px;
// }

.fixed-date .calendar-date {
  position: fixed;
  cursor: default;
  margin-top: 14px;
  border: 1px solid $branco !important;
}
.tarefas {
  display: flex;
  justify-content: center;
  margin: auto;
}
.listaTarefas .tarefas {
  border-left: 1px solid $cinza3;
}

.filter.listaTarefas .tarefas {
  justify-content: center;
  border-left: none;
}

.tarefas .top {
  display: flex;
  justify-content: space-between;
  background: $default;
  z-index: 10;
  top: 60px;
  width: 90%;
  height: 80px;
  padding-top: 20px;
  align-items: center;
  border-radius: 0;
  position: sticky;
  max-width: 90%;
}
.tarefas .top .background {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 25px 25px 3px 3px;
}

.tarefas .top .photo-name {
  padding-left: 20px;
}
.tarefas .top .photo-name .avatar {
  margin-right: 1px;
}
.tarefas .top .photo-name .avatar .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 24px;
}
.tarefas .top .title {
  padding-left: 10px;
  text-align: left;
  width: 100%;
}

.tarefas .box-alerta {
  background-color: white;
  width: 90%;
  padding: 20px 32px;
  max-width: 90%;
  margin-top: 11px;
}
.tarefas .box-alerta p {
  margin-bottom: 0px;
}
.tarefas .top .title .name {
  font-size: $font-default;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
.tarefas .top .title .info {
  font-size: $font-xs;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.tarefas .count {
  font-size: $font-default;
  font-weight: bold;
  text-align: right;
  padding-right: 30px;
}
.tarefas .count p {
  margin-bottom: 0px;
}

.tarefas .schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  background: $cinza1;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 0 0 25px 25px;
  max-width: 90%;
}

.tarefas .timetable {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $cinza2;
  width: 100%;
  min-height: 60px;
}
.tarefas .timetable:last-child {
  // border-bottom: none;
  // padding: 0 !important;
}
.tarefas .timetable .hour {
  font-size: $font-md;
  font-weight: 300;
  max-width: 80px;
  margin: auto 24px;
}
.tarefas .timetable .patient {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
  margin: 30px 0;
}
.tarefas .timetable .patient .select {
  padding: 0;
  width: 100%;
}
.tarefas .timetable .patient .name {
  font-weight: bold;
}

.tarefas .timetable .actions {
  padding: 0;
  display: flex;
  align-items: center;
}
.tarefas .timetable .actions .btn {
  // box-shadow: none;
}

// Media 1024
@media (max-width: 1024px) {
  .barra-filtro ~ .fixed-date,
  .barra-filtro ~ .listaTarefas,
  .barra-filtro ~ .calendar {
    margin-top: 0;
  }
}

//  Media Mobile
@media (max-width: 1023px) {
  .fixed-date .calendar-date {
    position: initial;
    margin-top: 14px;
    height: 80px;
    max-width: 95%;
    background: #fff;
    box-shadow: none;
  }
  .tarefas {
    margin: 0 -20px;
  }
  .listaTarefas .tarefas {
    border-left: none;
  }
  .tarefas .top {
    height: 200px;
  }
  .tarefas .top .photo-name .avatar .avatarCustom {
    width: 60px;
    height: 60px;
  }
  .tarefas .top .title .name {
    text-align: center;
    margin: 10px 0 0;
  }
  .tarefas .top .title .info {
    text-align: center;
  }

  .tarefas.sem-horarios {
    width: 100%;
    margin: 10px auto 0;
  }
  .tarefas.sem-horarios img {
    margin-bottom: 10px;
  }
  .tarefas.sem-horarios p.title {
    text-align: center;
    margin-bottom: 10px;
    line-height: 20px;
    padding: 0 10px;
  }
  .tarefas.sem-horarios p.instruction {
    margin: 0;
    padding: 0 10px;
  }
  .tarefas .timetable {
    padding-bottom: 20px;
  }
  .tarefas .timetable .hour {
    font-size: $font-xl;
    font-weight: 100;
    margin: 20px 0 0;
  }
  .tarefas .timetable .patient {
    margin: 10px auto;
    width: 100%;
    text-align: center;
  }
  .tarefas .timetable .patient .dados-user {
    justify-content: center !important;
    text-align: left;
  }
  .tarefas .timetable .actions {
    justify-content: flex-end;
  }
}
