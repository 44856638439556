.background-menu {
  height: 100vh;
  box-shadow: 0px 4px 20px #0000001c;
  background-color: $cinza2;
}

.topo .iconError,
.topo.iconMenu .btnVoltar img,
.topo.iconNotification .btnVoltar img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
}

.background-menu .icon-menu {
  height: 35px;
}

// .background-menu .menu-sair .icon-menu {
//   height: 60px;
// }

.container-box {
  width: 880px;
  padding: 80px 20px 20px;
  position: relative;
}
.container-box div.box {
  margin: 2px;
  background: #ffffff;
  border-radius: 3px;
  text-align: center;
  font-size: 2.7em;
  float: left;
  width: 120px;
  height: 120px;
  cursor: pointer;
  padding: 0px 5px;
  border: 1px solid #a5a6a9;
}
.container-box div.box.sub-menu {
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 0px;
}

.container-box div.box.sub-menu:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0px 12px 12px;
  border-color: transparent transparent #a5a6a9 transparent;
  right: 0;
  bottom: 0;
  position: absolute;
}

.container-box div.box.selecionado {
  outline: 1px solid #00cb7c;
  border: 1px solid #00cb7c;
}

.container-box div.box p {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.container-box > .box-perfil {
  width: 195px;
  height: 495px;
  float: left;
}
.container-box div.menu-user {
  height: 245px;
  padding: 20px 10px 5px;
  width: 190px;
}

.container-box div.menu-user .txt-secondary {
  color: $cinza4;

  font-size: 12px;
}
.container-box .box-perfil .menu-perfil {
  padding: 0px 15px 0px;
}
.container-box .box-perfil.menu-user-perfil {
  height: 46px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  font-size: 4.5em;
  float: left;
  width: 140px;
  cursor: pointer;
  border-radius: 10px;
  font-family: $font-family-default-2;
  font-weight: 400;
}
.container-box .menu-user-perfil .multi {
  color: $branco;
}
.container-box .menu-user-perfil p {
  color: $cinza5;
}

.container-box .menu-user-perfil .multi p {
  color: $branco;
}
.container-box > div.menu-user.selecao-auditor {
  position: absolute;
  z-index: 100;
}
.container-box > div.menu-felicoop {
  width: 290px;
}

.container-box > div.menu-auditor {
  position: absolute;
  width: 460px;
  left: 170px;
  height: 290px;
  text-align: inherit;
  opacity: 1;
}

.logoMenu {
  width: 200px;
}

.container-box > div.menu-auditor.fadeOut {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 3s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide {
  100% {
    left: -150px;
    transform: scaleX(0);
    opacity: 0;
  }
}

@keyframes slide {
  100% {
    left: -150px;
    transform: scaleX(0);
    opacity: 0;
  }
}
.container-box .box-auditor {
  max-width: 250px;
  margin: auto;
  padding-top: 2rem;
  font-size: initial;
}

.botao-box {
  position: absolute;
  right: 30px;
}

.container-box .box-auditor h2 {
  text-align: center;
}

.container-box .box-auditor p {
  text-align: center;
}

.menu-user h5 {
  font-size: 12px;
}
.menu-user h6 {
  font-size: 12px;
  font-style: italic;
  color: #999999;
}

.logo-menor {
  height: 50px;
}

.max-hr {
  margin-top: 80px;
  width: 90%;
}

.backMenu {
  height: 17px;
  margin-bottom: 2px;
}

.menuOpened .container-com-topo {
  margin-top: 0px;
  transition: all 0.4s ease-in-out;
}
.menuOpened .bodyMenu {
  overflow-y: auto;
  padding-top: 60px;
}

.navbarMenu {
  background-color: $cinza2;
  opacity: 1;
}

footer p {
  color: #cccccc !important;
}

/* MOBILE */
@media (max-width: 768px) {
  .container-box {
    width: 750px;
  }
  .container-box > div.box {
  }
}
@media (max-width: 575px) {
  .background-menu {
    background-color: #eff3f4 !important;
    background: none;
    height: initial;
  }
  .container-box {
    width: 550px;
    padding: 20px;
    height: 100vh;
    overflow-y: scroll;
  }
  .container-box > div.box {
    width: calc(50% - 5px);
    margin: 5px 0px;
  }
  .max-hr {
    margin-top: 30px;
    width: 70%;
  }

  .container-box > div.box:nth-child(odd) {
    margin-left: 10px;
  }
  .container-box div.menu-user {
    height: 140px;
    width: 100%;
    padding: 20px 30px;
    margin: 0px;
  }
  .container-box div.menu-felicoop {
    width: 100%;
  }

  .backMenu {
    height: 22px;
  }
  .container-box > .box-perfil {
    width: 100%;
    height: 210px;
    top: 35px;
    position: relative;
  }
  .container-box .box-perfil.menu-user-perfil {
    height: 50px;
    width: 100%;
  }
}
@media (max-width: 414px) {
  .avatarMenu {
    width: 95px;
    height: 95px;
  }
  .container-box {
    width: 100%;
    padding: 30px 5px 5px 5px;
  }
}
@media (max-width: 375px) {
  .container-box {
    width: 100%;
    padding: 30px 10px 10px 10px;
  }
  .container-box div.menu-user {
    padding: 20px;
  }
  .avatarMenu {
    width: 90px;
    height: 90px;
  }
  .max-hr {
    margin-top: 60px;
    width: 70%;
  }
}
@media (max-width: 320px) {
  .container-box div.menu-user {
    padding: 20px 13px;
  }
  .avatarMenu {
    width: 70px;
    height: 70px;
    font-size: 34px;
  }
}
