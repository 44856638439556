.menu-filtros label {
  color: $preto;
  font-size: $font-xs;
  font-style: normal;
  margin-left: 2px;
}

.menu-filtros .select-filter {
  padding: 0px 0px 10px;
  border-bottom: 1px solid $cinza2;
}

.menu-filtros .titulo-filtro img {
  height: 16px;
  margin-bottom: 0px;
  filter: brightness(0);
  margin-right: 5px;
}
