.listagem-custom {
  padding: 10px 0;

  .topo-listagem {
    margin-bottom: -47px;
    z-index: 1;
  }

  span.icone-lupa {
    content: url('./images/icones/search.svg');
    margin-right: 6px;
    margin-bottom: -3px;
    width: 13px;
  }

  .conteudo-listagem {
    border-radius: $border-radius;
    padding: 20px 40px;
  }
  .conteudo-listagem .search-box {
    background-color: #f8f8f8;
    border-bottom: 1px dashed $cinza3;
    padding-top: 30px;
  }
  .action-topo-tabela {
    background-color: #f8f8f8;
  }

  .conteudo-listagem {
    .search-box {
      .subtitulo {
        font-size: $font-lg;
        font-weight: bold;
        flex-grow: 1;
        text-align: left;
        margin: 0 0 20px 10px;
      }
      .close-search {
        background: url(./images/icones/close.svg) no-repeat center center;
        height: 30px;
        width: 30px;
        background-size: 10px;
        // opacity: 0.3;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1023px) {
  .boxButton {
    margin-left: 0 !important;
  }
  .listagem-custom {
    .topo-listagem {
      text-align: center;
      height: 55px;
      margin-bottom: 0px;
    }
    .conteudo-listagem {
      padding: 10px 10px;
      .search-box {
        padding: 10px 0px 10px 10px;
      }
    }
  }
}
