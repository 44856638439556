.filter-agendamento{
    max-width: 1500px;
    margin: auto;
    
}

.filter-icon-agendamento {
    width: 30px;
    margin: 5px;
}
.filter-agendamento h1{
    font-size: 17px;
    font-weight: 800;

}
.btn-find-horarios{
    width: 175px;
    border-radius: 30px;
    font-weight: 800;
    font-size: 14px;
}
.btn-clean-filters{
    width: 175px;
    height: 50px;
    border-radius: 30px;
    font-weight: 800;
    background-color: #999999;
    font-size: 14px;
}
