.prontuario.d-flex .header-prontuario {
  flex-basis: 100%;
}
.header-prontuario h1,
.header-prontuario > div {
  flex-basis: 50%;
}
.icon-print {
  content: url('./images/print.svg');
}
.icon-triagem {
  content: url('./images/icon-param-triagem-white.svg');
}
.header-prontuario h1 {
  font-size: 22px;
}
