.checkbox input[type='checkbox'] + i,
.radio input[type='radio'] + i {
  width: 22px;
  height: 22px;
  background: url('./images/icon-check-radio-button.svg') 0 0 no-repeat;
  background-size: 240px 24px;
  display: inline-block;
  position: relative;
  bottom: -4px;
  margin-right: 5px;
  cursor: pointer;
}
.checkbox input[type='checkbox'] {
  display: none;
}
.checkbox input[type='checkbox'] + .icon-checkbox {
  background-position: -120px 0;
}

// .checkbox:hover input[type='checkbox'] + .icon-checkbox {
//   background-position: -24px 0;
// }

// .checkbox input[type='checkbox']:checked + .icon-checkbox {
//   background-position: -48px 0;
// }

// .checkbox input[type='checkbox']:disabled + .icon-checkbox {
//   background-position: -72px 0;
// }

// .checkbox input[type='checkbox']:disabled:checked + .icon-checkbox {
//   background-position: -96px 0;
// }

.checkbox:hover input[type='checkbox'] + .icon-checkbox {
  background-position: -144px 0;
}

.checkbox input[type='checkbox']:checked + .icon-checkbox {
  background-position: -168px 0;
}

.checkbox input[type='checkbox']:disabled + .icon-checkbox {
  background-position: -196px 0;
}

.checkbox input[type='checkbox']:disabled:checked + .icon-checkbox {
  background-position: -220px 0;
}
