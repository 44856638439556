.container-sintetico {
  background-color: #fff;
  min-height: 100px;
  flex-basis: 50%;
  border-top: 2px solid #2a444e;
  position: relative;
  padding: 15px;
  margin: 0px 10px;
}
.prontuario-box-sintetico .font-date {
  font-size: 20px;
}
.prontuario-box-sintetico .font-professional {
  font-size: 16px;
}

#anamnese-0.prontuario-box-sintetico {
  margin-top: 20px;
}

.container-sintetico {
  width: auto;
}
