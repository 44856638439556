// THEAD
.react-bs-container-header .table {
  margin-bottom: 0;
}
// .table thead th {
//   font-family: $font-family-default;
//   color: $cinza4;
//   font-size: $font-default;
//   font-weight: 300;
//   border: none;
//   padding: 8px 10px;
// }
// .table-header thead th:focus {
//   outline: none;
// }

// .table thead th.column-sm {
//   max-width: 140px;
// }
// .table thead th.column-md {
//   max-width: 300px;
// }
// .table thead th.column-full {
//   width: 100%;
// }

// ORDENAÇÃO
.sort-column {
  cursor: pointer;
}
.sort-column .fa-sort {
  margin: 10px 5px !important;
}

// TBODY
// .react-bs-container-body .table {
//   font-size: $font-default;
//   cursor: pointer;
// }
// .react-bs-container-body .table tbody tr:hover {
//   background-color: $branco;
//   transition: all 500ms;
// }

// .table tbody tr {
//   height: 60px;
// }

// .table tbody td {
//   padding: 8px 10px;
//   vertical-align: middle;
//   border-bottom: 1px solid $cinza2;
//   border-top: none;
// }
// .table tbody td:focus {
//   outline: none;
// }
// .table tbody td:first-child {
//   border-radius: 4px 0 0 4px;
// }
// .table tbody td:last-child {
//   border-radius: 0 4px 4px 0;
// }

// CONTEÚDO
.column-bold {
  font-weight: 700;
}

.column-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table tbody td.react-bs-table-no-data {
  margin: 20px 0;
  padding: 40px 10px;
  font-style: italic;
  text-align: center;
}

.action .selectRow {
  background-color: #00cb7c;
  color: #004329;
}

// REGISTROS
.total-text {
  color: $cinza4;
  font-size: $font-default;
  font-weight: 300;
}

// PAGINAÇÃO
.react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination {
  justify-content: flex-end;
}
.react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination li {
  margin: 0px 4px;
}
li.page-item.disabled {
  display: none;
}
li.page-item .page-link {
  font-family: $font-family-default;
  color: $preto;
  background: $branco;
  font-size: $font-default;
  border: 2px solid transparent;
  font-weight: 600;
  box-shadow: none;
  padding: 10px 15px;
  border-radius: 4px;
}
li.page-item.active .page-link {
  background: $cinza2;
  color: $cinza4;
  border: 2px solid transparent;
}
li.page-item .page-link:focus,
li.page-item.active .page-link:focus {
  border: 2px solid $cinza3;
  box-shadow: $shadow2;
}

// MOBILE
@media (max-width: 1023px) {
  .react-bs-container-body table,
  .react-bs-container-body thead,
  .react-bs-container-body tbody,
  .react-bs-container-body tr,
  .react-bs-container-body th,
  .react-bs-container-body td {
    display: block;
  }

  // THEAD
  .table thead {
    display: none;
  }

  .table tbody tr {
    height: inherit;
  }

  // TBODY
  .table tbody tr {
    border-bottom: 2px solid $preto;
  }
  .table tbody tr:first-child {
    border-top: none;
  }

  .table tbody td {
    position: relative;
    border: none;
    border-bottom: 1px solid $cinza2;
    padding: 22px 10px 12px !important;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table tbody td div:after {
    position: absolute;
    content: attr(data-title);
    top: 8px;
    left: 10px;
    width: 100%;
    text-align: left;
    color: $cinza4;
    font-size: $font-label;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table tbody td:last-child {
    border: none;
  }
  .total-text {
    text-align: center;
  }
  // PAGINAÇÃO
  .react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination {
    margin-top: 30px;
  }
  li.page-item.disabled {
    display: block;
  }
}
