.agendamento-container {
  max-width: 1500px;
  margin: 5px;
  background-color: white;
  border: 1px #cccc solid;
  margin: auto;
}



.check-periodo label {
  font-size: 12px;
  font-weight: 600;
}

.btn-painel-horarios {
  margin-bottom: 5px;
  margin-top: 5px;

}

.btn-painel-horarios button {
  width: 110px;
  border-radius: 30px;
  font-weight: 200;
  font-size: 19px;
  color: white;
  margin: 10px;
  padding: 8px 26px;
  border: transparent;

}

.btn-painel-horarios button:nth-child(odd) {
  background-color: #8DDA2C;
}

.btn-painel-horarios button:nth-child(odd):hover {
  background-color: white;
}

.painel-horarios {
  max-width: 1500px;
  margin: auto;
}

.box-data {
  background-color: #EFF3F4;
  width: 120px;
  height: 120px;
  border: 1px solid #fff;
  padding: 8px 3px;
  border-radius: 10px;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
}

.box-data h1 {
  font-size: 35px;
  font-weight: 800;
  color: black;
  margin: 0;
}

.box-data h2 {
  font-size: 14px;
  font-weight: 800;
  color: black;
  margin: 0;
}

.box-data p {
  font-size: 13px;
  font-weight: 600;
  color: #2CDA97;
  margin: 0;
}

.box-data p:last-child {
  font-size: 13px;
  font-weight: 400;
  color: black;
  margin: 0;
}

.carrossel-medicos {
  border-right: 1px #EFF3F4 solid;
}

.dados-medico-carrossel h1 {
  font-size: 15px;
  font-weight: 700;
}

.dados-medico-carrossel-conselho,
.dados-medico-carrossel-especialidade {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  line-height: 14px;
}

.icon-agenda-carrossel {
  width: 15px;
}

.dados-medico-carrossel span {
  font-size: 12px;
  font-weight: 900;

}

.label-input-marcacao {
  font-size: 10px;
  font-weight: 900;
  color: #d9d9d9;

}

.topo-carousel-profissionais {
  position: sticky;
  top: 60px;
  z-index: 9;
  background-color: #ffffff;
  border-top: 1px solid #dfe2e3;
  border-bottom: 1px solid #dfe2e3;
  height: 155px;
}

.horarios-agendamento {
  margin-top: 5px;
  position: sticky;
  top: 215px;
  z-index: 10;
  background-color: #ffffff;

  border-bottom: 1px solid #dfe2e3;
  height: 45px;
  margin-bottom: 30px;
}

.carousel-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  height: 155px;

}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content>* {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}


.medico-select {
  border: 2px #B5E776 solid;
  width: 80%;
  margin: 15px auto;
}

@media (max-width: 1366px) {
  .topo-carousel-profissionais {
    height: 170px;
  }

  .carousel-container {
    height: 170px;
  }
}

@media (max-width: 1280px) {
  .topo-carousel-profissionais {
    height: 190px;
  }

  .carousel-container {
    height: 190px;
  }
}

@media (max-width: 767px) {
  .carousel-container .avatarCustom {
    height: 60px;
    width: 60px;
    font-size: 15px;
  }

  .topo-carousel-profissionais {
    height: 210px;
  }

  .carousel-container {
    height: 210px;
  }
}

@media (max-width: 414px) {
  .avatar {
    display: none;
  }

  .topo-carousel-profissionais {
    height: 160px;
  }

  .carousel-container {
    height: 160px;
  }

  .dados-medico-carrossel h1 {
    font-size: 12px;
  }

  .dados-medico-carrossel-conselho {
    font-size: 12px;
    line-height: 13px;
  }

  .dados-medico-carrossel-especialidade {
    font-size: 12px;
    line-height: 13px;
  }

  .left-arrow,
  .right-arrow {
    width: 30px;
    height: 59px;
  }

  .right-arrow {
    right: 5px;
  }

  .left-arrow {
    left: 5px;
  }

  .btn-painel-horarios button {
    width: 95px;
    padding: 8px;
  }
}