.prontuario-page {
  // blocos
}

.consultas-container {
  padding-top: 5px;
  background-color: #02cb7d;
  border: 1px solid #02cb7d;
  width: 100% !important;
}
.mr-50 {
  margin-right: 50% !important;
}
.w-c-50 {
  width: 50%;
}
.consultas-container-texto {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  height: 30px;
  box-shadow: none;
}

.prontuario label {
  font-size: 12px;
}

.hr-green {
  border-top: 1px solid #56cb7d;
}

.checkbox-container {
  background-color: #fff;
  padding-bottom: 10px;
  border: 1px solid #02cb7d;
  border-top: none;
  width: 100% !important;
}

.anamnese-anterior-topo {
  top: 60px;
  position: sticky;
  z-index: 100;
}

.sem-consulta-container {
  background-color: #fff;
  padding-bottom: 10px;
  width: 100% !important;
}

.sem-consulta {
  padding-top: 5px;
  background-color: #fff;
  border-top: 1px solid #2a444e;
  width: 100% !important;
  height: 60px;
}

.sem-consulta p {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 0;
}
.exameAnteriores {
  height: 60px;
  overflow: auto;
}
.descricao-anamnese table {
  width: 100%;
}
#anamnese-sintetico p {
  margin: 0px;
}
@media (max-width: 414px) {
  .mr-50 {
    margin-right: 0% !important;
  }
  .w-c-50 {
    width: 100%;
  }
  .descricao-anamnese {
    padding: 10px;
  }
  .prontuario-box-sintetico .font-date {
    font-size: 16px;
    font-weight: bold;
  }
  .prontuario-box-sintetico .font-professional {
    font-size: 13px;
  }
  .descricao-receituario {
    margin-top: 5px;
    padding: 10px;
  }
}

//hight 30 px; box shadow none;

// .anamnese {
//   border-top: 1px solid #ddd;
//   padding-top: 15px;
// }

// .anamnese-title {
//   background-color: #888;
//   color: white;
// }

// .anamnese-texto {
//   border: 1px solid #ddd;
//   padding: 15px 10px 15px 10px;
// }

// .list-anamense {
//   margin-top: 15px;
// }

// .anamnese-title.new-anamnese-title {
//   background-color: #7bcc6d;
// }

// .header-icon.imagem-icon {
//   background-image: url('./images/ico_imagem.svg') !important;
// }

// .header-icon.add-imagem-icon {
//   background-image: url('./images/ico_maisGrey.svg') !important;
// }

// .add-imagem-button {
//   cursor: pointer;
// }

// .btn.btn-anamnese {
//   color: white;
//   font-weight: 500 !important;
//   font-size: 30px !important;
// }
