@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,600,600i,700,700i&display=swap");
@import url(~font-awesome/css/font-awesome.min.css);
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(~react-bootstrap-table/dist/react-bootstrap-table.min.css);
@import url(./pages/cadastroWizard/components/MultiStepProgressBar.css);
@import url(./pages/cadastroWizard/CadastroWizard.css);
@import url(./components/countNumber/DumbNumber.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #02cb7d;
  --secondary: #428ee6;
  --success: #2cda97;
  --info: #17a2b8;
  --warning: #d4dd66;
  --danger: #ea3a45;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #eff3f4; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #02cb7d;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #017f4e;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #eff3f4;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #d8dde3; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #d8dde3; }
  .table tbody + tbody {
    border-top: 2px solid #d8dde3; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #d8dde3; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d8dde3; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8f0db; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7be4bb; }

.table-hover .table-primary:hover {
  background-color: #a3ecd0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3ecd0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cadff8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9dc4f2; }

.table-hover .table-secondary:hover {
  background-color: #b3d1f5; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b3d1f5; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4f5e2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #91ecc9; }

.table-hover .table-success:hover {
  background-color: #aef1d7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #aef1d7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f3f5d4; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #e9edaf; }

.table-hover .table-warning:hover {
  background-color: #edf0bf; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #edf0bf; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9c8cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f4999e; }

.table-hover .table-danger:hover {
  background-color: #f6b1b5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f6b1b5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #d8dde3; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #4ffdba;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #2cda97; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(44, 218, 151, 0.9);
  border-radius: 10px; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2cda97;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232cda97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #2cda97;
    box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2cda97;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232cda97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #2cda97;
    box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2cda97; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2cda97; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #2cda97; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #57e2ac;
  background-color: #57e2ac; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2cda97; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2cda97; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2cda97;
  box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ea3a45; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(234, 58, 69, 0.9);
  border-radius: 10px; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea3a45;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea3a45' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea3a45' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ea3a45;
    box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ea3a45;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea3a45' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea3a45' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ea3a45;
    box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea3a45; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea3a45; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ea3a45; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef6871;
  background-color: #ef6871; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ea3a45; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea3a45; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ea3a45;
  box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #02cb7d;
  border-color: #02cb7d; }
  .btn-primary:hover {
    color: #fff;
    background-color: #02a566;
    border-color: #02985e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #02a566;
    border-color: #02985e;
    box-shadow: 0 0 0 0.2rem rgba(40, 211, 145, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #02cb7d;
    border-color: #02cb7d; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #02985e;
    border-color: #018c56; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 211, 145, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #428ee6;
  border-color: #428ee6; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #207ae2;
    border-color: #1d74d8; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #207ae2;
    border-color: #1d74d8;
    box-shadow: 0 0 0 0.2rem rgba(94, 159, 234, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #428ee6;
    border-color: #428ee6; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1d74d8;
    border-color: #1b6ecd; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 159, 234, 0.5); }

.btn-success {
  color: #212529;
  background-color: #2cda97;
  border-color: #2cda97; }
  .btn-success:hover {
    color: #fff;
    background-color: #21be82;
    border-color: #1fb47b; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #21be82;
    border-color: #1fb47b;
    box-shadow: 0 0 0 0.2rem rgba(42, 191, 135, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #2cda97;
    border-color: #2cda97; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1fb47b;
    border-color: #1ea973; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 191, 135, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #d4dd66;
  border-color: #d4dd66; }
  .btn-warning:hover {
    color: #212529;
    background-color: #cbd647;
    border-color: #c8d43c; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #cbd647;
    border-color: #c8d43c;
    box-shadow: 0 0 0 0.2rem rgba(185, 193, 93, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #d4dd66;
    border-color: #d4dd66; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #c8d43c;
    border-color: #c5d132; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 193, 93, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ea3a45;
  border-color: #ea3a45; }
  .btn-danger:hover {
    color: #fff;
    background-color: #e51825;
    border-color: #da1723; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e51825;
    border-color: #da1723;
    box-shadow: 0 0 0 0.2rem rgba(237, 88, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ea3a45;
    border-color: #ea3a45; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #da1723;
    border-color: #ce1622; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 88, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #02cb7d;
  border-color: #02cb7d; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #02cb7d;
    border-color: #02cb7d; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #02cb7d;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #02cb7d;
    border-color: #02cb7d; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.5); }

.btn-outline-secondary {
  color: #428ee6;
  border-color: #428ee6; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #428ee6;
    border-color: #428ee6; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 142, 230, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #428ee6;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #428ee6;
    border-color: #428ee6; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 142, 230, 0.5); }

.btn-outline-success {
  color: #2cda97;
  border-color: #2cda97; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #2cda97;
    border-color: #2cda97; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #2cda97;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #2cda97;
    border-color: #2cda97; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #d4dd66;
  border-color: #d4dd66; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #d4dd66;
    border-color: #d4dd66; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 221, 102, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #d4dd66;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #d4dd66;
    border-color: #d4dd66; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 221, 102, 0.5); }

.btn-outline-danger {
  color: #ea3a45;
  border-color: #ea3a45; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ea3a45;
    border-color: #ea3a45; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ea3a45;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea3a45;
    border-color: #ea3a45; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #02cb7d;
  text-decoration: none; }
  .btn-link:hover {
    color: #017f4e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #02cb7d; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 10px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #02cb7d;
    background-color: #02cb7d; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #4ffdba; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #82fece;
    border-color: #82fece; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 10px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #02cb7d;
  background-color: #02cb7d; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(2, 203, 125, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(2, 203, 125, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(2, 203, 125, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(2, 203, 125, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 10px;
  appearance: none; }
  .custom-select:focus {
    border-color: #4ffdba;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #4ffdba;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 10px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 10px 10px 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #eff3f4, 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #eff3f4, 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #eff3f4, 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #02cb7d;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #82fece; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #02cb7d;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #82fece; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #02cb7d;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #82fece; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #eff3f4;
    border-color: #dee2e6 #dee2e6 #eff3f4; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 10px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #02cb7d; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 9px;
      border-bottom-left-radius: 9px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 9px 9px 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 9px 9px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 9px; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 10px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 10px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #02cb7d;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #017f4e;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; }

.page-item:last-child .page-link {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #02cb7d;
  border-color: #02cb7d; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #02cb7d; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #02985e; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 203, 125, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #428ee6; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #1d74d8; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(66, 142, 230, 0.5); }

.badge-success {
  color: #212529;
  background-color: #2cda97; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #1fb47b; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 218, 151, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #d4dd66; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #c8d43c; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 221, 102, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ea3a45; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #da1723; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(234, 58, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 10px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #016a41;
  background-color: #ccf5e5;
  border-color: #b8f0db; }
  .alert-primary hr {
    border-top-color: #a3ecd0; }
  .alert-primary .alert-link {
    color: #013722; }

.alert-secondary {
  color: #224a78;
  background-color: #d9e8fa;
  border-color: #cadff8; }
  .alert-secondary hr {
    border-top-color: #b3d1f5; }
  .alert-secondary .alert-link {
    color: #173150; }

.alert-success {
  color: #17714f;
  background-color: #d5f8ea;
  border-color: #c4f5e2; }
  .alert-success hr {
    border-top-color: #aef1d7; }
  .alert-success .alert-link {
    color: #0e4731; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #6e7335;
  background-color: #f6f8e0;
  border-color: #f3f5d4; }
  .alert-warning hr {
    border-top-color: #edf0bf; }
  .alert-warning .alert-link {
    color: #4d5025; }

.alert-danger {
  color: #7a1e24;
  background-color: #fbd8da;
  border-color: #f9c8cb; }
  .alert-danger hr {
    border-top-color: #f6b1b5; }
  .alert-danger .alert-link {
    color: #511418; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 10px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #02cb7d;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 10px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #02cb7d;
    border-color: #02cb7d; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #016a41;
  background-color: #b8f0db; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #016a41;
    background-color: #a3ecd0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #016a41;
    border-color: #016a41; }

.list-group-item-secondary {
  color: #224a78;
  background-color: #cadff8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #224a78;
    background-color: #b3d1f5; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #224a78;
    border-color: #224a78; }

.list-group-item-success {
  color: #17714f;
  background-color: #c4f5e2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #17714f;
    background-color: #aef1d7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #17714f;
    border-color: #17714f; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #6e7335;
  background-color: #f3f5d4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #6e7335;
    background-color: #edf0bf; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #6e7335;
    border-color: #6e7335; }

.list-group-item-danger {
  color: #7a1e24;
  background-color: #f9c8cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7a1e24;
    background-color: #f6b1b5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7a1e24;
    border-color: #7a1e24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d8dde3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #d8dde3;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 10px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #02cb7d !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #02985e !important; }

.bg-secondary {
  background-color: #428ee6 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1d74d8 !important; }

.bg-success {
  background-color: #2cda97 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1fb47b !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #d4dd66 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c8d43c !important; }

.bg-danger {
  background-color: #ea3a45 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #da1723 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #d8dde3 !important; }

.border-top {
  border-top: 1px solid #d8dde3 !important; }

.border-right {
  border-right: 1px solid #d8dde3 !important; }

.border-bottom {
  border-bottom: 1px solid #d8dde3 !important; }

.border-left {
  border-left: 1px solid #d8dde3 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #02cb7d !important; }

.border-secondary {
  border-color: #428ee6 !important; }

.border-success {
  border-color: #2cda97 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #d4dd66 !important; }

.border-danger {
  border-color: #ea3a45 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 10px !important; }

.rounded-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important; }

.rounded-right {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; }

.rounded-bottom {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important; }

.rounded-left {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #02cb7d !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #017f4e !important; }

.text-secondary {
  color: #428ee6 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #1a68c2 !important; }

.text-success {
  color: #2cda97 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1c9e6c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #d4dd66 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #beca2d !important; }

.text-danger {
  color: #ea3a45 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #c31520 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #d8dde3; }
  .table .thead-dark th {
    color: inherit;
    border-color: #d8dde3; } }

.month-picker {
  position: relative; }
  .month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .rmp-container.rmp-table {
      display: table; }
    .month-picker > .rmp-container.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .rmp-container.show .rmp-overlay {
        left: 0; }
    .month-picker > .rmp-container .rmp-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .rmp-container .rmp-cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-cell {
          vertical-align: bottom; } }
    .month-picker > .rmp-container .rmp-popup {
      position: absolute;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .rmp-container .rmp-popup.range {
            height: 28rem;
            transform: translate3d(0, 28rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
              margin-top: 0.4rem; }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .rmp-container .rmp-popup {
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .rmp-container .rmp-popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .rmp-container .rmp-popup.show {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .month-picker > .rmp-container .rmp-popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .rmp-container .rmp-popup .rmp-pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
            left: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
            right: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
          display: block;
          width: 100%; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          display: block;
          float: left;
          text-align: center;
          font-size: 1.15rem;
          border-radius: 3px;
          line-height: 3.3rem;
          box-sizing: border-box;
          padding: 0.05rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 33.33333%; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
            background-clip: content-box; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
            border-radius: 1px; }
      .month-picker > .rmp-container .rmp-popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
            background-color: #d3d3d3; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .rmp-container .rmp-popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
            background-color: #262828; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

.navbar {
  background-color: #eff3f4;
  height: 60px;
  padding: 15px 20px;
  z-index: 13; }

.header-back-menu {
  width: 130px; }

.header-icon {
  display: flex;
  width: 30px;
  height: 24px;
  padding: 0;
  border: 0 !important;
  outline: none !important; }

.header-icon.menu-icon {
  background: url("./images/icones/grid.svg") no-repeat center !important; }

.header-icon.back-icon {
  background: url("./images/icones/arrow-left.svg") no-repeat center !important; }

.header-icon.close-icon {
  background: url("./images/icones/close.svg") no-repeat center !important; }

.header-icon.task-icon {
  background: url("./images/icones/bell.svg") no-repeat center !important; }

.header-icon.icon-sair {
  background: url("./images/icones/log-out.svg") no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 6px; }

.txt-header {
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 5px; }

.txt-header.red {
  color: #dc3545; }

.header-title {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media (max-width: 1023px) {
  .navbar {
    padding: 10px 20px;
    margin: 0 -14px; }
  .header-back-menu {
    width: 30px; }
  .header-icon {
    height: 25px; }
  .header-title {
    font-size: 18px;
    width: 200px; }
  .button-logout {
    width: 30px; }
  .button-logout .header-icon {
    height: 30px; } }

.cheeseburger-menu {
  z-index: 1050; }

.cheeseburger-menu-overlay {
  background: rgba(0, 0, 0, 0.6) !important; }

.cheeseburger-menu-outer {
  background: #f8f8f8 !important; }

.cheeseburger-menu-inner {
  padding: 20px 30px; }

.cheeseburger-menu-inner .titulo {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: bold;
  margin-left: 0px; }

.cheeseburger-menu-inner .menu ul {
  padding: 0; }

.cheeseburger-menu-inner .menu hr {
  margin: 0 auto;
  width: 80%; }

.cheeseburger-menu-inner .menu ul li {
  display: flex;
  align-items: center;
  list-style: none;
  height: 65px;
  padding: 0 20px;
  border-radius: 20px;
  transition: all 250ms;
  cursor: pointer; }

.cheeseburger-menu-inner .menu ul li a {
  display: inline-block;
  width: 100%; }

.cheeseburger-menu-inner .menu ul li img {
  width: 30px;
  margin-right: 10px;
  filter: brightness(0);
  transition: all 250ms; }

.cheeseburger-menu-inner .menu ul li span {
  font-size: 14px; }

.cheeseburger-menu-inner .menu ul li:hover {
  background: #fff; }

.cheeseburger-menu-inner .menu ul li:hover img {
  filter: brightness(1); }

.cheeseburger-menu-inner .nome-user {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px; }

.cheeseburger-menu-inner .tipo-user {
  font-size: 14px;
  line-height: 14px;
  color: #85898e;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.cheeseburger-menu-inner .meu-perfil {
  cursor: pointer;
  padding: 20px;
  border-radius: 20px;
  transition: all 250ms; }

.cheeseburger-menu-inner .meu-perfil:hover {
  background: #fff; }

.cheeseburger-menu-inner .meus-dados img {
  width: 26px;
  filter: brightness(0);
  transition: all 250ms; }

.cheeseburger-menu-inner .meus-dados p {
  margin: 0 14px 3px 0; }

.cheeseburger-menu-inner .meu-perfil:hover .meus-dados img {
  filter: brightness(1); }

.cheeseburger-menu-inner .menu ul li:last-child,
.cheeseburger-menu-inner .menu ul li:last-child a,
.cheeseburger-menu-inner .menu ul li:last-child img {
  color: #dc3545 !important;
  filter: brightness(1); }

.close-menu > div {
  font-size: 12px;
  color: #000; }

.close-menu .btn-close {
  background: url("./images/icones/close.svg") no-repeat center;
  background-size: 10px;
  background-position-x: right;
  background-position-y: center;
  opacity: 0.3;
  border: none;
  outline: none !important; }

@media (max-width: 1023px) {
  .cheeseburger-menu-outer {
    width: 100vw !important;
    max-width: 100% !important; } }

.avatar {
  position: relative;
  z-index: 0; }

.avatar.action {
  cursor: pointer; }

.avatarCustom {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #fff;
  background-color: #02cb7d;
  font-size: 47px;
  margin: auto;
  z-index: 1; }

.avatarCustom img {
  box-shadow: 0px 0px 0px 1px #fff;
  background-color: #fff; }

.avatarCustom.avatar-list,
.avatarCustom.avatar-list .avatarCustom {
  width: 40px;
  height: 40px; }

td .avatar-list-icon-prioridade .avatarCustom {
  width: 70px;
  height: 70px;
  font-size: 22px; }

.avatar-list-icon-prioridade .avatarCustom > .avatarCustom {
  width: 70px;
  height: 70px; }

.avatarCustom.avatar-list img {
  width: 40px;
  height: 40px; }

.avatarCustom.avatar-list span {
  display: flex;
  font-size: 16px; }

.icon-foto-user {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10; }

.icon-foto-user img {
  height: 16px;
  position: absolute;
  right: 7px; }

@media (max-width: 1023px) {
  .icon-foto-user {
    right: 32%; }
  .avatarCustom.avatar-list {
    width: 80px;
    height: 80px;
    margin: 10px auto; }
  .avatarCustom.avatar-list img {
    width: 80px;
    height: 80px; }
  .avatarCustom.avatar-list span {
    font-size: 34px; }
  .Select .avatarCustom.avatar-list {
    width: 40px;
    height: 40px;
    margin: 10px auto; }
  .Select .avatarCustom.avatar-list img {
    width: 40px;
    height: 40px; }
  .Select .avatarCustom.avatar-list span {
    font-size: 24px; } }

.listagem-custom {
  padding: 10px 0; }
  .listagem-custom .topo-listagem {
    margin-bottom: -47px;
    z-index: 1; }
  .listagem-custom span.icone-lupa {
    content: url("./images/icones/search.svg");
    margin-right: 6px;
    margin-bottom: -3px;
    width: 13px; }
  .listagem-custom .conteudo-listagem {
    border-radius: 10px;
    padding: 20px 40px; }
  .listagem-custom .conteudo-listagem .search-box {
    background-color: #f8f8f8;
    border-bottom: 1px dashed #d8dde3;
    padding-top: 30px; }
  .listagem-custom .action-topo-tabela {
    background-color: #f8f8f8; }
  .listagem-custom .conteudo-listagem .search-box .subtitulo {
    font-size: 22px;
    font-weight: bold;
    flex-grow: 1;
    text-align: left;
    margin: 0 0 20px 10px; }
  .listagem-custom .conteudo-listagem .search-box .close-search {
    background: url(./images/icones/close.svg) no-repeat center center;
    height: 30px;
    width: 30px;
    background-size: 10px;
    cursor: pointer; }

@media (max-width: 1023px) {
  .boxButton {
    margin-left: 0 !important; }
  .listagem-custom .topo-listagem {
    text-align: center;
    height: 55px;
    margin-bottom: 0px; }
  .listagem-custom .conteudo-listagem {
    padding: 10px 10px; }
    .listagem-custom .conteudo-listagem .search-box {
      padding: 10px 0px 10px 10px; } }

.react-bs-container-header .table {
  margin-bottom: 0; }

.sort-column {
  cursor: pointer; }

.sort-column .fa-sort {
  margin: 10px 5px !important; }

.column-bold {
  font-weight: 700; }

.column-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.table tbody td.react-bs-table-no-data {
  margin: 20px 0;
  padding: 40px 10px;
  font-style: italic;
  text-align: center; }

.action .selectRow {
  background-color: #00cb7c;
  color: #004329; }

.total-text {
  color: #a9afb5;
  font-size: 14px;
  font-weight: 300; }

.react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination {
  justify-content: flex-end; }

.react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination li {
  margin: 0px 4px; }

li.page-item.disabled {
  display: none; }

li.page-item .page-link {
  font-family: "Muli", sans-serif;
  color: #000;
  background: #fff;
  font-size: 14px;
  border: 2px solid transparent;
  font-weight: 600;
  box-shadow: none;
  padding: 10px 15px;
  border-radius: 4px; }

li.page-item.active .page-link {
  background: #eff3f4;
  color: #a9afb5;
  border: 2px solid transparent; }

li.page-item .page-link:focus,
li.page-item.active .page-link:focus {
  border: 2px solid #d8dde3;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21; }

@media (max-width: 1023px) {
  .react-bs-container-body table,
  .react-bs-container-body thead,
  .react-bs-container-body tbody,
  .react-bs-container-body tr,
  .react-bs-container-body th,
  .react-bs-container-body td {
    display: block; }
  .table thead {
    display: none; }
  .table tbody tr {
    height: inherit; }
  .table tbody tr {
    border-bottom: 2px solid #000; }
  .table tbody tr:first-child {
    border-top: none; }
  .table tbody td {
    position: relative;
    border: none;
    border-bottom: 1px solid #eff3f4;
    padding: 22px 10px 12px !important;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .table tbody td div:after {
    position: absolute;
    content: attr(data-title);
    top: 8px;
    left: 10px;
    width: 100%;
    text-align: left;
    color: #a9afb5;
    font-size: 10px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .table tbody td:last-child {
    border: none; }
  .total-text {
    text-align: center; }
  .react-bs-table-pagination .react-bootstrap-table-page-btns-ul.pagination {
    margin-top: 30px; }
  li.page-item.disabled {
    display: block; } }

.horarioFuncionamento .form-inside {
  margin-right: 0px;
  margin-left: 0px; }

@media (max-width: 1023px) {
  .weekDays {
    text-align: center; } }

.local-horario-atendimento .horario-estabelecimento label {
  margin-top: 10px; }

.local-horario-atendimento .horario-estabelecimento p {
  margin: 2px 0 0 10px; }

.local-horario-atendimento .input-hour {
  margin: 0 5px 0px; }

.local-horario-atendimento .agenda {
  width: 100%; }

.agenda .horario-agenda {
  background: linear-gradient(#fff 50%, #f0f5f9 100%);
  padding: 20px;
  border-radius: 20px 20px 0 0; }

.agenda .horario-agenda .lines {
  border-bottom: 1px solid #f0f5f9;
  padding-bottom: 10px; }

.local-horario-atendimento .horario-estabelecimento label {
  margin-top: 10px; }

.local-horario-atendimento .horario-estabelecimento p {
  margin: 2px 0 0 10px; }

.local-horario-atendimento .input-hour {
  margin: 0 5px 0px; }

.local-horario-atendimento .agenda {
  width: 100%; }

.agenda .horario-agenda {
  background: linear-gradient(#fff 50%, #f0f5f9 100%);
  padding: 20px;
  border-radius: 20px 20px 0 0; }

.agenda .horario-agenda .lines {
  border-bottom: 1px solid #f0f5f9;
  padding-bottom: 10px; }

.custom-card {
  color: #000;
  margin: 0 0 10px;
  position: relative;
  border: none;
  padding: 10px;
  width: 638px;
  box-shadow: none; }
  .custom-card .topo-card {
    background-color: #fff;
    height: 80px;
    border-radius: 10px 10px 0 0;
    padding: 0 10px 0 20px; }
    .custom-card .topo-card h1 {
      font-size: 18px;
      margin-left: 10px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800; }
    .custom-card .topo-card button {
      box-shadow: none; }
  .custom-card .viewMode .topo-card {
    border: 1px dashed #a9afb5;
    border-bottom: none; }
  .custom-card .viewMode.fixed {
    position: initial; }
  .custom-card .conteudo-card {
    padding: 30px;
    border-radius: 0 0 10px 10px; }
  .custom-card .conteudo-card .item-view {
    background: linear-gradient(#fff 50%, #f0f5f9 100%);
    padding: 24px 10px;
    border-radius: 20px 20px 0 0; }
  .custom-card .conteudo-card .btn.shadow {
    box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21; }
  .custom-card .viewMode .conteudo-card {
    background-color: #f0f5f9;
    border: 1px dashed #a9afb5;
    border-top: none; }
  .custom-card .noViewMode .conteudo-card {
    padding: 30px 30px 40px;
    background-color: #f0f5f9; }
  .custom-card .noViewMode.fixed {
    position: fixed; }

.card-localAtendimento {
  margin: 10px;
  padding: 20px 0;
  background-color: #f0f5f9;
  border-radius: 10px;
  max-width: 618px; }
  .card-localAtendimento img {
    width: 70px; }
  .card-localAtendimento h1 {
    font-size: 18px;
    font-weight: 800; }
  .card-localAtendimento p {
    font-size: 12px;
    margin: 0; }

.card-agendamento {
  margin: 10px;
  background-color: #f0f5f9;
  border-radius: 10px;
  max-width: 618px; }
  .card-agendamento .area-link {
    border-radius: 10px;
    padding: 20px 20px 6px;
    text-align: center;
    max-width: 50%;
    cursor: pointer;
    transition: all 500ms ease-out; }
  .card-agendamento .area-link a {
    text-decoration: none; }
  .card-agendamento .area-link .icon {
    height: 70px;
    width: 70px;
    border-radius: 40px;
    padding: 20px;
    margin: auto;
    background: transparent; }
  .card-agendamento .area-link .icon img {
    height: 45px;
    mix-blend-mode: multiply;
    transition: all 500ms ease-in-out; }
  .card-agendamento .area-link .title {
    font-size: 14px;
    color: #000;
    text-align: center;
    font-weight: 800; }
  .card-agendamento .area-link p {
    margin-bottom: 0;
    text-align: center; }
  .card-agendamento .area-link .btn-link {
    color: #02cb7d;
    text-decoration: none !important;
    transform: translatey(6px) !important;
    opacity: 0; }
  .card-agendamento .area-link .button-copy {
    transform: translatey(6px) !important;
    opacity: 0; }
  .card-agendamento .area-link .button-copy img {
    filter: brightness(0);
    width: 20px;
    transition: all 250ms; }

.area-link:hover {
  background: #fff; }

.area-link:hover .icon {
  background-color: #02cb7d;
  transition: all 500ms ease-in-out; }

.area-link:hover .icon img {
  mix-blend-mode: normal; }

.area-link:hover .btn-link {
  transform: translatey(0) !important;
  opacity: 1; }

.area-link:hover .button-copy {
  transform: translatey(0) !important;
  opacity: 1; }

.area-link .button-copy:hover img {
  filter: brightness(1); }

@media (max-width: 1280px) {
  .noViewMode.fixed {
    position: initial; } }

@media (max-width: 1024px) {
  .custom-card {
    width: 60%;
    margin: auto auto 10px;
    padding: 0px; }
  .noViewMode.fixed {
    position: initial; }
  .card-localAtendimento {
    margin: auto auto 10px;
    max-width: 600px;
    text-align: center;
    padding: 20px 100px; }
    .card-localAtendimento p {
      margin-bottom: 20px; }
  .card-agendamento {
    margin: auto auto 10px;
    max-width: 60%; }
    .card-agendamento .area-link {
      max-width: 100%; } }

@media (max-width: 1023px) {
  .custom-card {
    width: 100%; }
  .card-localAtendimento {
    max-width: 100%;
    padding: 20px; }
  .card-agendamento {
    max-width: 100%;
    background-color: transparent; }
    .card-agendamento .area-link {
      background: #fff;
      margin-bottom: 10px; }
    .card-agendamento .area-link:last-child {
      margin-bottom: 0; }
    .card-agendamento .area-link .icon {
      background-color: #02cb7d; }
    .card-agendamento .area-link .icon img {
      mix-blend-mode: normal; }
    .card-agendamento .area-link .btn-link {
      transform: translatey(0) !important;
      opacity: 1; }
    .card-agendamento .area-link .button-copy {
      transform: translatey(0) !important;
      opacity: 1; }
    .card-agendamento .area-link .button-copy img {
      filter: brightness(1); }
  .topo-card {
    text-align: center;
    height: 130px; }
    .topo-card h1 {
      margin-top: 10px;
      margin-left: 0; }
    .topo-card button {
      border: 1px solid #eff3f4;
      margin: 0 8px 0;
      padding: 0 20px; } }

.cardCrud span.icon-btn-excluir {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px; }

.cardCrud:first-child {
  margin-top: 0px; }

.cardCrud .section-form div.form-group input.is-invalid {
  border: none;
  border-bottom: 2px solid #ea3a45 !important; }

.cardCrud .section-form.section-form-confirm {
  padding: 5px 0px; }

.cardCrud.card {
  border-left: none;
  border-right: none; }

.cardCrud .card-header {
  padding: 1.75rem 1.25rem 1.75rem 0.75rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: none;
  height: 70px;
  color: #000; }

.cardCrud .card-body {
  padding: 0 0.25rem 0 0.25rem; }

.cardCrud .subheader.card-header {
  padding: 0.45rem 1.25rem 0.75rem;
  margin-bottom: 0;
  background-color: #f0f5f9;
  border-bottom: none;
  color: #85898e;
  height: 30px;
  font-size: 10px; }

.cardCrud .section-form .input-required input {
  border-color: #000;
  border-width: 2px;
  color: #000; }

.cardCrud .input-required label {
  font-weight: bold;
  margin-bottom: -5px; }

.input-required .is-invalid .react-select__control {
  border: 2px solid #ea3a45 !important; }

.cardCrud .Select.is-invalid .react-select__control {
  border-bottom: 2px solid #ea3a45 !important; }

.cardCrud .Select.is-invalid .react-select__control {
  border-bottom: 2px solid #ea3a45; }

.cardCrud .Select.is-invalid .react-select__control .css-1uccc91-singleValue {
  color: #ea3a45; }

.cardCrud .input-required label {
  font-weight: bold; }

.cardCrud .section-form > div.form-group,
.cardCrud .section-form > div.form-row > div.form-group {
  padding: 7px 5px; }

.cardCrud {
  background-color: #fff; }
  .cardCrud .topo-card {
    background-color: #fff;
    height: 80px;
    border-radius: 0px;
    border-top: 2px solid #2a444e;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid #d8dde357;
    margin-bottom: 20px; }
    .cardCrud .topo-card h1 {
      font-size: 18px;
      margin-left: 15px;
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800; }
    .cardCrud .topo-card button {
      box-shadow: none; }
  .cardCrud .viewMode .topo-card {
    border: 0px dashed #a9afb5;
    border-bottom: none; }
  .cardCrud .viewMode.fixed {
    position: initial; }
  .cardCrud .form-control {
    height: 35px; }
  .cardCrud .Select {
    min-height: 35px; }
    .cardCrud .Select .react-select__control {
      min-height: 35px; }
    .cardCrud .Select .react-select__control:hover {
      border: 0px solid #eff3f4;
      border-bottom: 2px solid #a9afb5; }
  .cardCrud .conteudo-card {
    padding: 0px;
    border-radius: 0px; }
    .cardCrud .conteudo-card .item-view {
      background: linear-gradient(#fff 50%, #f0f5f9 100%);
      padding: 24px 10px;
      border-radius: 20px 20px 0 0; }
    .cardCrud .conteudo-card .btn.shadow {
      box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21; }
    .cardCrud .conteudo-card h1 {
      font-size: 18px;
      margin-left: 15px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
      margin-top: 20px;
      margin-bottom: 15px; }
  .cardCrud .viewMode .conteudo-card {
    background-color: #f0f5f9;
    border: 0px dashed #a9afb5;
    border-top: none; }
  .cardCrud .noViewMode .conteudo-card {
    padding: 30px 30px 40px;
    background-color: #f0f5f9; }
  .cardCrud .noViewMode.fixed {
    position: fixed; }

.mt-100 {
  margin-top: 100px; }
  .mt-100 .topo-card {
    border-top: 5px solid #2a444e; }

.form-inside,
.search-box.form-inside {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px 0px 20px 0px; }
  .form-inside .visualizacao .section-form > div.form-group,
  .search-box.form-inside .visualizacao .section-form > div.form-group {
    border-right: 1px solid #eff3f4; }
  .form-inside .visualizacao .section-form > div.b-bottom,
  .search-box.form-inside .visualizacao .section-form > div.b-bottom {
    border-bottom: 1px solid #eff3f4; }
  .form-inside .visualizacao .section-form > div.form-group > p,
  .search-box.form-inside .visualizacao .section-form > div.form-group > p {
    line-height: 20px;
    margin-bottom: 3px; }
  .form-inside .section-form.item-view div.form-group,
  .search-box.form-inside .section-form.item-view div.form-group {
    background-color: initial;
    padding: 5px; }
  .form-inside .section-form,
  .search-box.form-inside .section-form {
    color: #85898e;
    font-size: 10px;
    font-style: italic;
    padding: 0px 15px; }
    .form-inside .section-form .weekDays,
    .search-box.form-inside .section-form .weekDays {
      padding: 0; }
    .form-inside .section-form .weekDays .buttonWeek,
    .search-box.form-inside .section-form .weekDays .buttonWeek {
      width: 62px;
      font-size: 14px;
      font-style: normal;
      text-align: center;
      padding: 10px 0;
      border-radius: 10px;
      margin: 5px 0 5px 9px;
      transform: scale(1);
      cursor: pointer;
      transition: all 250ms; }
    .form-inside .section-form .weekDays .buttonWeek-off,
    .search-box.form-inside .section-form .weekDays .buttonWeek-off {
      color: #000;
      background-color: #fff;
      border-color: #fff;
      font-weight: 600; }
    .form-inside .section-form .weekDays .buttonWeek-off:hover,
    .search-box.form-inside .section-form .weekDays .buttonWeek-off:hover {
      outline: none !important;
      box-shadow: none !important;
      transform: scale(0.9); }
    .form-inside .section-form .weekDays .buttonWeek-on,
    .search-box.form-inside .section-form .weekDays .buttonWeek-on {
      color: #fff !important;
      background-color: #02cb7d !important;
      border-color: #02cb7d;
      font-weight: 800; }
    .form-inside .section-form .convenios .control--checkbox span,
    .search-box.form-inside .section-form .convenios .control--checkbox span {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      font-style: normal; }
    .form-inside .section-form > h6,
    .search-box.form-inside .section-form > h6 {
      padding-left: 15px;
      font-size: 10px;
      line-height: 30px;
      margin-bottom: 0px;
      height: 30px !important;
      background-color: #f0f5f9; }
    .form-inside .section-form > .convenios label.desabilitado,
    .search-box.form-inside .section-form > .convenios label.desabilitado {
      font-size: 12px;
      font-style: normal;
      padding: 0px 10px; }
    .form-inside .section-form div.form-group.text-center .form-control,
    .search-box.form-inside .section-form div.form-group.text-center .form-control {
      text-align: center; }
    .form-inside .section-form .horario-agenda,
    .search-box.form-inside .section-form .horario-agenda {
      padding: 0px 5px;
      font-size: 14px; }
    .form-inside .section-form div.form-group,
    .form-inside .section-form div.form-row div.form-group,
    .search-box.form-inside .section-form div.form-group,
    .search-box.form-inside .section-form div.form-row div.form-group {
      padding-right: 5px;
      padding-left: 5px;
      background-color: transparent;
      padding-bottom: 3px;
      margin-bottom: 0px; }
    .form-inside .section-form > div .form-control:focus,
    .search-box.form-inside .section-form > div .form-control:focus {
      outline-offset: 0px !important;
      outline: none !important;
      border: none;
      background-color: transparent; }
    .form-inside .section-form div > label,
    .search-box.form-inside .section-form div > label {
      margin: 0px; }
    .form-inside .section-form div.form-group .form-control,
    .search-box.form-inside .section-form div.form-group .form-control {
      width: 100%;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid #ccc;
      height: 35px;
      padding: 0 5px; }
    .form-inside .section-form div.form-group .form-control:disabled,
    .form-inside .section-form div.form-group .form-control[readonly],
    .search-box.form-inside .section-form div.form-group .form-control:disabled,
    .search-box.form-inside .section-form div.form-group .form-control[readonly] {
      color: #999999;
      opacity: 1;
      border: none;
      background-color: #fff; }
    .form-inside .section-form div.form-group .form-control.is-invalid,
    .search-box.form-inside .section-form div.form-group .form-control.is-invalid {
      border-bottom: 2px solid #ea3a45; }
    .form-inside .section-form div.form-group,
    .search-box.form-inside .section-form div.form-group {
      background-color: transparent;
      padding: 20px 10px;
      width: 0; }
    .form-inside .section-form div.form-group.central,
    .search-box.form-inside .section-form div.form-group.central {
      max-width: 140px;
      min-width: 70px;
      padding: 20px 5px;
      text-align: center; }
    .form-inside .section-form div.form-group.central .Select .react-select__value-container,
    .search-box.form-inside .section-form div.form-group.central .Select .react-select__value-container {
      display: flex !important;
      justify-content: center !important; }
    .form-inside .section-form div.form-group.central.fixo,
    .search-box.form-inside .section-form div.form-group.central.fixo {
      width: 70px; }
    .form-inside .section-form .quebra,
    .search-box.form-inside .section-form .quebra {
      flex-basis: 100%;
      height: 0; }
    .form-inside .section-form div.form-group.fixo,
    .search-box.form-inside .section-form div.form-group.fixo {
      width: 210px;
      flex: 0 0 auto !important; }
    .form-inside .section-form div.form-group.large,
    .search-box.form-inside .section-form div.form-group.large {
      width: 30%; }
    .form-inside .section-form div.form-group.full,
    .search-box.form-inside .section-form div.form-group.full {
      width: 80%; }
    .form-inside .section-form > .w-100 + div,
    .search-box.form-inside .section-form > .w-100 + div {
      border-left: 1px solid #eff3f4; }
    .form-inside .section-form > .quebra + div,
    .search-box.form-inside .section-form > .quebra + div {
      border-left: 1px solid #eff3f4; }
    .form-inside .section-form .form-group.campoPendente,
    .search-box.form-inside .section-form .form-group.campoPendente {
      outline: 2px solid #3c6669;
      outline-offset: -2px;
      z-index: 2;
      background-size: 10px;
      background-position: 97% center;
      background-repeat: no-repeat; }
    .form-inside .section-form .icone-pendente,
    .search-box.form-inside .section-form .icone-pendente {
      display: none; }
    .form-inside .section-form .form-group.campoPendente .icone-pendente,
    .search-box.form-inside .section-form .form-group.campoPendente .icone-pendente {
      display: inline-flex; }
    .form-inside .section-form div.form-group.w-20,
    .search-box.form-inside .section-form div.form-group.w-20 {
      width: 20%; }
    .form-inside .section-form .label-nowrap,
    .search-box.form-inside .section-form .label-nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0; }
    .form-inside .section-form .input-required label,
    .search-box.form-inside .section-form .input-required label {
      font-weight: bold; }
    .form-inside .section-form label,
    .search-box.form-inside .section-form label {
      color: #000;
      font-size: 12px;
      padding-left: 0px;
      display: inline-block;
      padding: 0px 5px; }
    .form-inside .section-form .form-group.central label,
    .search-box.form-inside .section-form .form-group.central label {
      padding-left: 0px;
      padding-right: 0px; }
    .form-inside .section-form > div.form-group .input-group .form-control,
    .search-box.form-inside .section-form > div.form-group .input-group .form-control {
      width: 90%; }
    .form-inside .section-form > div.form-group .input-group .input-group-text,
    .search-box.form-inside .section-form > div.form-group .input-group .input-group-text {
      background-color: transparent;
      border: none;
      border-radius: 0; }
    .form-inside .section-form > div.form-group textarea.form-control,
    .search-box.form-inside .section-form > div.form-group textarea.form-control {
      min-height: 54px; }
    .form-inside .section-form > div.form-group.central .form-control,
    .search-box.form-inside .section-form > div.form-group.central .form-control {
      text-align: center; }
    .form-inside .section-form div.form-group .input-required input,
    .search-box.form-inside .section-form div.form-group .input-required input {
      border-color: #000000;
      border-width: 2px;
      color: #000000; }
    .form-inside .section-form div.form-group .form-control:disabled,
    .form-inside .section-form div.form-group .form-control[readonly],
    .search-box.form-inside .section-form div.form-group .form-control:disabled,
    .search-box.form-inside .section-form div.form-group .form-control[readonly] {
      color: #85898e;
      font-weight: bold;
      background-color: #fff;
      border-bottom: 2px solid #a9afb5; }
  .form-inside .Select.form-control .Select-control,
  .search-box.form-inside .Select.form-control .Select-control {
    border: none;
    background-color: transparent;
    text-align: left; }
  .form-inside .Select.form-control .Select-control:hover,
  .search-box.form-inside .Select.form-control .Select-control:hover {
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0); }
  .form-inside .Select .Select-control .Select-value-label,
  .search-box.form-inside .Select .Select-control .Select-value-label {
    font-size: 12px; }
  .form-inside .text-center .Select .react-select__value-container,
  .search-box.form-inside .text-center .Select .react-select__value-container {
    display: flex !important;
    justify-content: center !important;
    margin-left: 30px; }
  .form-inside .Select.form-control.is-focused:not(.is-open) > .Select-control,
  .search-box.form-inside .Select.form-control.is-focused:not(.is-open) > .Select-control {
    box-shadow: none; }
  .form-inside .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  .search-box.form-inside .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
    color: #000; }
  .form-inside .Select .Select-menu-outer,
  .form-inside .react-select__menu,
  .search-box.form-inside .Select .Select-menu-outer,
  .search-box.form-inside .react-select__menu {
    font-family: 'Muli', sans-serif;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #fff;
    border: none !important;
    box-shadow: 0 0 20px #00000030 !important;
    margin-top: 0px;
    z-index: 300000 !important; }
  .form-inside .Select .Select-placeholder,
  .form-inside .Select.Select--single > .Select-control .Select-value,
  .form-inside .react-select__placeholder,
  .search-box.form-inside .Select .Select-placeholder,
  .search-box.form-inside .Select.Select--single > .Select-control .Select-value,
  .search-box.form-inside .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: #a9afb5 !important;
    border-radius: 10px; }
  .form-inside .Select,
  .search-box.form-inside .Select {
    padding: 0px;
    background-color: transparent;
    min-height: 40px;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    border-radius: 10px;
    border: initial; }
  .form-inside .Select .react-select__control,
  .search-box.form-inside .Select .react-select__control {
    min-height: 30px;
    font-family: 'Muli', sans-serif;
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid #a9afb5;
    background-color: transparent;
    cursor: pointer; }
  .form-inside .input-required .react-select__control,
  .search-box.form-inside .input-required .react-select__control {
    border-color: #000;
    border-width: 2px; }
  .form-inside .input-required .is-invalid .react-select__control,
  .search-box.form-inside .input-required .is-invalid .react-select__control {
    border: 2px solid #ea3a45 !important; }
  .form-inside .Select .Select-placeholder,
  .form-inside .Select.Select--single > .Select-control .Select-value,
  .form-inside .react-select__placeholder,
  .search-box.form-inside .Select .Select-placeholder,
  .search-box.form-inside .Select.Select--single > .Select-control .Select-value,
  .search-box.form-inside .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: #a9afb5 !important;
    border-radius: 10px; }
  .form-inside .css-tlfecz-indicatorContainer,
  .form-inside .css-1gtu0rj-indicatorContainer,
  .search-box.form-inside .css-tlfecz-indicatorContainer,
  .search-box.form-inside .css-1gtu0rj-indicatorContainer {
    padding: 0px !important;
    width: 13px;
    height: 20px;
    margin-right: 10px; }
  .form-inside .icon-input,
  .search-box.form-inside .icon-input {
    position: absolute;
    width: 15px;
    top: 12px;
    left: 20px; }

.search-box.form-inside {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px; }

.search-box.form-inside .section-form div.form-group {
  padding: 5px 10px;
  width: 0; }

.card-localAtendimento {
  margin: 10px;
  padding: 20px 0;
  background-color: #f0f5f9;
  border-radius: 10px;
  max-width: 618px; }
  .card-localAtendimento img {
    width: 70px; }
  .card-localAtendimento h1 {
    font-size: 18px;
    font-weight: 800; }
  .card-localAtendimento p {
    font-size: 12px;
    margin: 0; }

.card-central {
  margin: 10px;
  padding: 20px 0;
  border-radius: 10px;
  max-width: 400px;
  background-color: transparent; }
  .card-central img {
    height: 60px; }
  .card-central h1 {
    font-size: 18px;
    font-weight: 800; }
  .card-central p {
    font-size: 12px;
    margin: 0; }

.card-central-sumario {
  margin: 10px;
  padding: 20px 0;
  border-radius: 10px;
  background-color: transparent; }
  .card-central-sumario .bl-1 {
    border-left: 1px solid #d8dde3; }
  .card-central-sumario img {
    height: 60px; }
  .card-central-sumario h1 {
    font-size: 18px;
    font-weight: 800; }
  .card-central-sumario p {
    font-size: 12px;
    margin: 0; }

.card-agendamento {
  margin: 35px 10px 0px 10px;
  background-color: #f0f5f9;
  border-radius: 10px;
  max-width: initial; }
  .card-agendamento .area-link {
    border-radius: 10px;
    padding: 20px 20px 6px;
    text-align: center;
    max-width: 50%;
    cursor: pointer;
    transition: all 500ms ease-out; }
  .card-agendamento .area-link a {
    text-decoration: none; }
  .card-agendamento .area-link .icon {
    height: 70px;
    width: 70px;
    border-radius: 40px;
    padding: 20px;
    margin: auto;
    background: transparent; }
  .card-agendamento .area-link .icon img {
    height: 45px;
    mix-blend-mode: multiply;
    transition: all 500ms ease-in-out; }
  .card-agendamento .area-link .title {
    font-size: 14px;
    color: #000;
    text-align: center;
    font-weight: 800; }
  .card-agendamento .area-link p {
    margin-bottom: 0;
    text-align: center; }
  .card-agendamento .area-link .btn-link {
    color: #02cb7d;
    text-decoration: none !important;
    transform: translatey(6px) !important;
    opacity: 0; }
  .card-agendamento .area-link .button-copy {
    transform: translatey(6px) !important;
    opacity: 0; }
  .card-agendamento .area-link .button-copy img {
    filter: brightness(0);
    width: 20px;
    transition: all 250ms; }

.area-link:hover {
  background: #fff; }

.area-link:hover .icon {
  background-color: #02cb7d;
  transition: all 500ms ease-in-out; }

.area-link:hover .icon img {
  mix-blend-mode: normal; }

.area-link:hover .btn-link {
  transform: translatey(0) !important;
  opacity: 1; }

.area-link:hover .button-copy {
  transform: translatey(0) !important;
  opacity: 1; }

.area-link .button-copy:hover img {
  filter: brightness(1); }

@media (max-width: 1280px) {
  .noViewMode.fixed {
    position: initial; } }

@media (max-width: 1024px) {
  .custom-card {
    width: 60%;
    margin: auto auto 10px;
    padding: 0px; }
  .noViewMode.fixed {
    position: initial; }
  .card-localAtendimento {
    margin: auto auto 10px;
    max-width: 600px;
    text-align: center;
    padding: 20px 100px; }
    .card-localAtendimento p {
      margin-bottom: 20px; }
  .card-agendamento {
    margin: auto auto 10px;
    max-width: 60%; }
    .card-agendamento .area-link {
      max-width: 100%; } }

@media (max-width: 1023px) {
  .custom-card {
    width: 100%; }
  .card-localAtendimento {
    max-width: 100%;
    padding: 20px; }
  .card-agendamento {
    max-width: 100%;
    background-color: transparent; }
    .card-agendamento .area-link {
      background: #fff;
      margin-bottom: 10px; }
    .card-agendamento .area-link:last-child {
      margin-bottom: 0; }
    .card-agendamento .area-link .icon {
      background-color: #02cb7d; }
    .card-agendamento .area-link .icon img {
      mix-blend-mode: normal; }
    .card-agendamento .area-link .btn-link {
      transform: translatey(0) !important;
      opacity: 1; }
    .card-agendamento .area-link .button-copy {
      transform: translatey(0) !important;
      opacity: 1; }
    .card-agendamento .area-link .button-copy img {
      filter: brightness(1); }
  .cardCrud .topo-card {
    text-align: center;
    height: 130px; }
    .cardCrud .topo-card h1 {
      margin-top: 10px;
      margin-left: 0; }
    .cardCrud .topo-card button {
      border: 1px solid #eff3f4;
      margin: 0 8px 0;
      padding: 0 20px;
      height: 40px; }
  .cardCrud .conteudo-card .form-inside .section-form .weekDays .buttonWeek-on {
    width: 18%; }
  .cardCrud .conteudo-card .form-inside .section-form div.form-group {
    width: 100%;
    padding: 7px 5px; }
  .cardCrud .conteudo-card .form-inside .section-form div.form-group.central {
    max-width: 50%;
    width: 50%;
    padding: 20px 20px;
    text-align: left; }
  .cardCrud .conteudo-card .form-inside .section-form div.form-group.central.fixo {
    width: 50%; }
  .cardCrud .conteudo-card .form-inside .section-form div.form-group.fixo {
    width: 50%; }
  .cardCrud .conteudo-card .form-inside .section-form .quebra {
    display: none; }
  .cardCrud .conteudo-card .form-inside .section-form div.form-group.mobile-100 {
    width: 100%;
    min-width: 100%; } }

@media (max-width: 414px) {
  .cardCrud .topo-card {
    height: 155px;
    padding: 0px; }
  .cardCrud .conteudo-card .form-inside .section-form .weekDays .buttonWeek-on,
  .cardCrud .conteudo-card .form-inside .section-form .weekDays .buttonWeek-off {
    width: 14%; } }

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 100%;
  padding: 0px; }

.redux-toastr .toastr.toast-marph {
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  opacity: 1;
  box-shadow: none !important;
  width: 98%;
  border-radius: 35px;
  min-height: 70px;
  text-align: center;
  margin: 0 1% 6px; }

.redux-toastr .toastr.toast-marph .rrt-left-container {
  width: 50px;
  left: 30px; }

.redux-toastr .toastr.toast-marph .rrt-left-container .rrt-holder {
  width: 40px; }

.toast-marph .icon_error,
.icon_success,
.icon_pendencia,
.icon_warning,
.icon_info {
  padding: 5px 0 0;
  margin-left: 12px; }

.redux-toastr .toastr.toast-marph .rrt-middle-container {
  margin: auto;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  width: 100%;
  text-align: center; }

.redux-toastr .toastr.toast-marph .rrt-middle-container .rrt-title {
  color: #fff; }

.toastr.toast-marph .close-toastr {
  font-size: 12px;
  color: #fff;
  opacity: 1;
  margin-right: -2%;
  cursor: pointer; }

.redux-toastr .toastr.rrt-error.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-success.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-warning.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-pendencia.toast-marph .rrt-progressbar,
.redux-toastr .toastr.rrt-info.toast-marph .rrt-progressbar {
  background-color: #0000000f; }

.redux-toastr .toastr.rrt-error.toast-marph-erro {
  background-color: #dc3545; }

.redux-toastr .toastr.rrt-success.toast-marph-success {
  background-color: #02cb7d; }

.redux-toastr .toastr.rrt-warning.toast-marph-warning {
  background-color: #ffc107; }

.redux-toastr .toastr.rrt-info.toast-marph-info {
  background-color: #428ee6; }

.redux-toastr .toastr.rrt-info.toast-marph-info .rrt-text,
.redux-toastr .toastr.rrt-info.toast-marph-info .rrt-title {
  color: #fff; }

.redux-toastr .toastr.toast-marph.toast-marph-info .close-toastr {
  color: #fff; }

.redux-toastr .toastr.rrt-info.toast-marph-info:hover {
  box-shadow: none; }

.redux-toastr .toastr.rrt-info.toast-marph-info-black {
  background-color: #fff; }

.redux-toastr .toastr.rrt-pendencia.toast-marph-pendencia:hover {
  box-shadow: none; }

.redux-toastr .toastr.rrt-info.toast-marph-info:hover {
  box-shadow: none; }

.toast-marph {
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  left: 50%;
  margin-left: -254px;
  width: 509px;
  height: 50px;
  border-radius: 4px;
  margin-top: 9px; }

.toast-marph {
  position: fixed;
  z-index: 99999999;
  padding: 0 5px;
  left: 0%;
  margin-left: auto;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  margin-top: 9px; }

.icon_error,
.icon_sucess,
.icon_pendente_WHITE,
.icon_warning,
.icon_pendencia,
.icon_info,
.icon_fechar {
  max-width: 20px; }

@media (max-width: 1023px) {
  .redux-toastr .top-center .toast-marph,
  .redux-toastr .bottom-center {
    width: 100%;
    left: 0; }
  .redux-toastr .toastr.rrt-info.toast-marph-info,
  .redux-toastr .toastr.rrt-error.toast-marph-erro,
  .redux-toastr .toastr.rrt-pendencia.toast-marph-pendencia,
  .redux-toastr .toastr.rrt-warning.toast-marph-warning,
  .redux-toastr .toastr.rrt-success.toast-marph-success {
    margin: 0 1% 2%; }
  .redux-toastr .toastr.toast-marph .rrt-middle-container {
    width: 76%;
    margin: 0 12%;
    top: 4px;
    padding: 0; }
  .redux-toastr .toastr.toast-marph .close-toastr {
    margin: 0 10px; }
  .toast-marph {
    position: fixed;
    z-index: 99999999;
    padding: 0 5px;
    left: 0%;
    margin-left: auto;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-top: 9px; } }

.Select {
  font-family: "Muli", sans-serif;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 10px; }

.Select .react-select__control {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  box-shadow: none !important;
  cursor: pointer;
  border: 1px solid #f0f5f9; }

.Select.select-barra .react-select__control {
  min-height: 50px;
  height: 50px; }

.Select .react-select__value-container {
  padding: 2px 0 0; }

.Select .react-select__single-value {
  width: 99%;
  margin: 0 0 0 5px;
  white-space: pre-wrap; }

.Select .css-b8ldur-Input {
  margin: 0;
  padding: 0 0 6px;
  height: 100%; }

.Select .css-26l3qy-menu {
  z-index: 10;
  text-transform: capitalize; }

.Select .react-select__input {
  height: 100%;
  margin: 0 0 0 20px;
  padding-top: 3px; }

.Select .react-select__input input {
  height: 90%;
  margin: auto 0;
  border: 0 !important; }

.Select .react-select__value-container--is-multi .react-select__input {
  margin: 0 0 0 10px; }

.Select .react-select__indicators span {
  background: none; }

.Select .react-select__dropdown-indicator {
  padding: 8px 8px 8px 4px; }

.Select .react-select__dropdown-indicator svg {
  width: 20px;
  height: 15px; }

.Select .react-select__clear-indicator {
  padding: 0;
  cursor: pointer; }

.Select .react-select__clear-indicator svg {
  width: 14px;
  height: 14px;
  color: #dc3545; }

.Select .react-select__placeholder {
  color: #a9afb5;
  font-weight: 200;
  margin: 0 0 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%; }

.Select.is-invalid {
  border: 0px solid red !important;
  box-shadow: none !important;
  border-radius: 0px;
  background-color: transparent; }

.Select.is-invalid .react-select__dropdown-indicator {
  color: red; }

.react-select__menu {
  color: #000;
  border: 1px solid #eff3f4;
  margin: 4px 0 !important;
  padding: 4px 10px;
  cursor: pointer;
  z-index: 1000; }

.patient .react-select__menu {
  z-index: 100; }

.react-select__option {
  border: none !important;
  cursor: pointer !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.select-wrap .react-select__option {
  border: none !important;
  cursor: pointer !important;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: initial; }

.patient .react-select__option {
  height: 60px; }

.patient .react-select__menu-list .react-select__option .create {
  padding: 0 20px; }

.patient .react-select__menu-list div:first-child .react-select__option {
  color: #02cb7d;
  border-radius: 10px; }

.patient .react-select__menu-list div:first-child .react-select__option:hover {
  background-color: #fff;
  text-decoration: underline; }

.patient .react-select__menu-list div:first-child .react-select__option .optionCreateUser {
  display: none !important;
  margin: 0 0 0 6px; }

.react-select__option:hover {
  background-color: #f0f5f9;
  border-radius: 10px; }

.react-select__option:active {
  background-color: #fff !important;
  color: #02cb7d !important; }

.react-select__menu .react-select__option--is-focused {
  background-color: #f0f5f9;
  color: #000;
  border-radius: 10px; }

.react-select__menu .react-select__option--is-selected {
  background-color: #fff !important;
  color: #02cb7d !important;
  border-radius: 10px; }

.react-select__menu-notice {
  font-size: 14px;
  font-weight: 200;
  color: #a9afb5 !important;
  padding: 2px !important; }

.checkbox.control.control--checkbox:first-child {
  font-weight: bold;
  background-color: #f0f5f9;
  border-radius: 20px; }

.checkbox.control.control--checkbox .react-select__option input {
  display: none;
  margin: 0; }

.Select .react-select__multi-value {
  background-color: #fff;
  border: 1px solid #eff3f4;
  border-radius: 0px;
  padding: 0px 4px 1px;
  margin: 0px 6px 0px 0px; }

.Select .react-select__multi-value .css-12jo7m5 {
  font-size: 14px;
  font-weight: 600; }

.Select .react-select__multi-value__remove {
  margin-right: 5px;
  content: url("./images/icon_x_red.svg");
  width: 15px;
  float: left;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important; }

.Select .customOption {
  overflow: hidden;
  max-width: 90%;
  margin: 0 0 0 6px;
  text-transform: uppercase; }

.Select .select-especialidades.customOption {
  font-size: 10px;
  line-height: 12px; }

.Select .select-nome.customOption {
  font-size: 13px;
  line-height: 15px;
  font-weight: bold; }

.Select .select-conselho.customOption {
  font-size: 11px; }

.Select .medicamentos.customOption {
  text-overflow: ellipsis;
  max-width: 80%; }

.patient .Select .optionCreateUser {
  margin-left: 6px; }

.disabled,
.react-select--is-disabled {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent; }

.disabled,
.react-select--is-disabled .react-select__control {
  background: transparent; }

.disabled,
.react-select--is-disabled .react-select__placeholder {
  color: #000;
  font-style: italic; }

.disabled,
.react-select--is-disabled .react-select__indicators {
  display: none; }

@media (max-width: 1023px) {
  .patient .react-select__menu-list .avatarCustom.avatar-list {
    width: 40px;
    height: 40px; }
  .patient .react-select__menu-list .avatarCustom.avatar-list span {
    font-size: 14px; }
  .patient .Select .customOption {
    max-width: 90%;
    margin-left: 10px; }
  .patient .Select .optionCreateUser {
    margin-left: 10px; }
  .patient .react-select__menu-list div:last-child .react-select__option .create {
    padding: 0; } }

.checkbox input[type='checkbox'] + i,
.radio input[type='radio'] + i {
  width: 22px;
  height: 22px;
  background: url("./images/icon-check-radio-button.svg") 0 0 no-repeat;
  background-size: 240px 24px;
  display: inline-block;
  position: relative;
  bottom: -4px;
  margin-right: 5px;
  cursor: pointer; }

.checkbox input[type='checkbox'] {
  display: none; }

.checkbox input[type='checkbox'] + .icon-checkbox {
  background-position: -120px 0; }

.checkbox:hover input[type='checkbox'] + .icon-checkbox {
  background-position: -144px 0; }

.checkbox input[type='checkbox']:checked + .icon-checkbox {
  background-position: -168px 0; }

.checkbox input[type='checkbox']:disabled + .icon-checkbox {
  background-position: -196px 0; }

.checkbox input[type='checkbox']:disabled:checked + .icon-checkbox {
  background-position: -220px 0; }

.react-datepicker {
  font-family: "Muli", sans-serif;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  border: none; }

.react-datepicker__triangle {
  border-bottom-color: #eff3f4; }

.react-datepicker__triangle::before {
  border-bottom-color: transparent !important; }

.react-datepicker__header {
  background-color: #eff3f4;
  border: none;
  border-radius: 10px; }

.react-datepicker__time-list-item {
  transition: all 250ms;
  padding: 0 5px;
  margin: 0 8px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #fff !important;
  color: #02cb7d;
  border-radius: 20px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #f0f5f9;
  border-radius: 20px; }

.react-datepicker__day {
  transition: all 250ms; }

.react-datepicker__day--today {
  color: #02cb7d;
  background-color: #fff; }

.react-datepicker__day:hover {
  background-color: #f0f5f9;
  color: #000;
  border-radius: 20px; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #02cb7d !important;
  color: #fff !important;
  border-radius: 20px; }

.textCKeditor .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border: 1px solid #f0f5f9;
  border-radius: 20px !important;
  background-color: #fff;
  padding: 0 20px; }

.textCKeditor .ck.ck-toolbar .ck.ck-toolbar__separator {
  background-color: #f0f5f9; }

.textCKeditor .ck.ck-content.ck-editor__editable {
  background-color: #fff;
  border-radius: 20px !important;
  min-height: 200px;
  border: 1px solid #f0f5f9 !important;
  margin: 10px 0 50px;
  padding: 10px 20px;
  font-size: 14px; }

.textCKeditor .ck.ck-content.ck-editor__editable:focus {
  border: 1px solid #eff3f4;
  box-shadow: none; }

.textCKeditor .ck.ck-content.ck-editor__editable .ck-placeholder:before {
  color: #a9afb5;
  font-size: 14px;
  font-weight: 200; }

.modal-body .textCKeditor .ck.ck-content.ck-editor__editable {
  margin: 5px;
  min-height: 350px; }

.textCKeditor.is-invalid .ck.ck-content.ck-editor__editable {
  border: 2px solid #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("images/invalid.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.menu-filtros label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  margin-left: 2px; }

.menu-filtros .select-filter {
  padding: 0px 0px 10px;
  border-bottom: 1px solid #eff3f4; }

.menu-filtros .titulo-filtro img {
  height: 16px;
  margin-bottom: 0px;
  filter: brightness(0);
  margin-right: 5px; }

.menu-indisponibilidade label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  margin-left: 2px; }

.menu-indisponibilidade .titulo-indisponibilidade img {
  height: 28px;
  margin-bottom: 0px;
  filter: brightness(0); }

.menu-indisponibilidade .select-filter {
  padding: 25px 10px 30px;
  border-bottom: 1px solid #eff3f4; }

.menu-indisponibilidade hr {
  margin: 0px 0; }

.menu-indisponibilidade .disabled,
.react-select--is-disabled {
  background-color: white; }

.menu-indisponibilidade .disabled,
.react-select--is-disabled .react-select__placeholder {
  font-weight: bold;
  font-style: normal; }

.menu-indisponibilidade .disabled,
.react-select--is-disabled {
  opacity: 1; }

.historico-bg .cheeseburger-menu-inner {
  background-color: #e3e7e8; }

.historico label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  margin-left: 2px; }

.historico h2 {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px; }

.historico h3 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  font-style: italic; }

.historico p {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0px;
  padding: 0 10px; }

.historico p b {
  font-size: 12px;
  color: #2a444e; }

.historico .txt-sem-historico {
  font-size: 13px;
  font-weight: bold;
  color: #284149; }

.historico.titulo-indisponibilidade img {
  height: 28px;
  margin-bottom: 0px;
  filter: brightness(0); }

.historico.select-filter {
  padding: 25px 10px 30px;
  border-bottom: 1px solid #eff3f4; }

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto; }

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #01cb7d;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px; }

/* Container around content */
.container-historico {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%; }

/* The circles on the timeline */
.container-historico::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 7px solid #01cb7d;
  top: 20px;
  border-radius: 50%;
  z-index: 1; }

/* Place the container to the left */
.left {
  left: 0; }

/* Place the container to the right */
.right {
  left: 50%; }

/* Add arrows to the left container (pointing right) */
.left::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white; }

/* Add arrows to the right container (pointing left) */
.right::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent; }

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px; }

/* The actual content */
.content {
  padding: 15px 5px 6px 5px;
  background-color: white;
  position: relative;
  border-radius: 6px; }

.content p:last-child {
  margin-bottom: 0px; }

.content hr {
  border-top: 3px solid #e3e7e8;
  margin: 5px 0;
  width: 100%; }

.content .itemHistorico {
  border-bottom: 1px solid #e3e7e8;
  padding-bottom: 5px;
  margin-top: 5px; }

.content .itemHistorico:last-child {
  border-bottom: 0px solid #e3e7e8;
  padding-bottom: 5px;
  margin-top: 5px; }

/* Media queries - Responsive timeline on screens less than 600px wide */
.timeline::after {
  left: 31px; }

/* Full-width containers */
.container-historico {
  width: 100%;
  padding-left: 60px;
  padding-right: 0px; }

/* Make sure that all arrows are pointing leftwards */
.container-historico::before {
  left: 50px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent; }

/* Make sure all circles are at the same spot */
.left::after,
.right::after {
  left: 19px; }

/* Make all right containers behave like the left ones */
.right {
  left: 0%; }

.indisponibilidade-row {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; }

.indisponibilidade-row p {
  margin-bottom: 2px;
  font-size: 12px; }

.indisponibilidade-row .anchor-link a {
  font-size: 12px; }

.indisponibilidade-row .titulo-red {
  color: #ea3a45;
  font-weight: bold; }

.barra-filtro ~ .fixed-date,
.barra-filtro ~ .agenda,
.barra-filtro ~ .calendar {
  margin-top: 80px; }

.agenda .date {
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: bold; }

.agenda .month {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 30px; }

.agenda .font-details {
  font-size: 14px;
  color: #000;
  margin-bottom: -3px; }

.agenda .font-details.count {
  font-weight: bold; }

.agenda .margin {
  background: #eff3f4;
  height: 115px;
  width: 50%;
  top: 80px;
  position: fixed;
  z-index: 98; }

.fixed-date .calendar-date {
  position: fixed;
  cursor: default;
  margin-top: 14px;
  border: 1px solid #fff !important; }

.doctor {
  margin-top: 5px; }

.agenda .doctor {
  border-left: 1px solid #d8dde3; }

.filter.agenda .doctor {
  justify-content: center;
  border-left: none; }

.doctor .top {
  display: flex;
  justify-content: space-between;
  background: #eff3f4;
  z-index: 9;
  top: 185px;
  width: 100%;
  height: 60px;
  align-items: center;
  border-radius: 0;
  position: sticky; }

.doctor .top .background {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 70px;
  z-index: -1;
  border-top: 2px solid #2a444e; }

.doctor .top .photo-name {
  padding-left: 20px; }

.doctor .top .photo-name .avatar {
  margin-right: 1px; }

.doctor .top .photo-name .avatar .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 24px; }

.doctor .top .title {
  padding-left: 10px;
  text-align: left;
  width: 100%; }

.doctor .top .title .name {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%; }

.doctor .top .title .info {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%; }

.doctor .count {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding-right: 30px; }

.doctor .schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fff;
  margin-bottom: 50px; }

.agenda-online-content .doctor .top {
  display: flex;
  justify-content: space-between;
  background: #eff3f4;
  z-index: 10;
  top: 65px;
  width: 100%;
  height: 60px;
  align-items: center;
  border-radius: 0;
  position: sticky; }

.agenda-online-content .doctor .barra-info {
  background-color: #f8f8f8;
  font-size: 11px;
  color: #ababab;
  position: -webkit-sticky;
  position: sticky;
  top: 125px;
  z-index: 1; }

.schedules .row {
  padding: 0px 20px; }

.schedules .row:nth-child(odd) {
  background-color: #fff; }

.schedules .row:nth-child(even) {
  background-color: #e3e7e8; }

.doctor .barra-info {
  background-color: #f8f8f8;
  font-size: 11px;
  color: #ababab;
  position: sticky;
  top: 245px;
  z-index: 1; }

.label-custom {
  padding: 3px 12px;
  margin: 0px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: normal; }

.label-particular {
  background-color: #2a444e;
  color: #fff; }

.label-especialidade {
  background-color: #00cb7ca6;
  color: #04613d; }

.label-convenio {
  background-color: #fff;
  color: #2a444e;
  outline: 1px solid #2a444e;
  outline-offset: -1px; }

.label-cortesia {
  background-color: #8f9aa0;
  color: #fff; }

.doctor .timetable {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eff3f4;
  width: 100%;
  min-height: 60px; }

.doctor .timetable .Select .react-select__placeholder,
.doctor .timetable .Select .react-select__single-value {
  margin: 0 0 0 15px; }

.doctor .encaixe.timetable {
  border-top: 1px solid #eff3f4;
  border-bottom: 0px solid #eff3f4; }

.doctor .timetable .hour {
  font-size: 18px;
  font-weight: 300;
  width: 50px;
  margin: 0px 5px 0px 0px; }

.doctor .timetable .patient {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
  margin: 15px 0; }

.doctor .timetable .patient .select {
  padding: 0;
  width: 100%; }

.doctor .timetable .patient .name {
  font-weight: bold; }

.doctor .timetable .actions {
  padding: 0;
  display: flex;
  align-items: center; }

@media (max-width: 1024px) {
  .barra-filtro ~ .fixed-date,
  .barra-filtro ~ .agenda,
  .barra-filtro ~ .calendar {
    margin-top: 0; } }

@media (max-width: 1023px) {
  .fixed-date .calendar-date {
    position: initial;
    margin-top: 14px;
    height: 80px;
    max-width: 95%;
    background: #fff;
    box-shadow: none; }
  .agenda .doctor {
    border-left: none; }
  .doctor .top .background {
    height: 250px; }
  .doctor .barra-info {
    top: 185px;
    display: none; }
  .actions .btn {
    padding: 0 10px; }
  .doctor .top .photo-name .avatar .avatarCustom {
    width: 60px;
    height: 60px; }
  .doctor .top .title .name {
    text-align: center;
    margin: 10px 0 0;
    max-width: 100%; }
  .doctor .top .title .info {
    text-align: center;
    max-width: 100%; }
  .doctor.sem-horarios {
    width: 100%;
    margin: 10px auto 0; }
  .doctor.sem-horarios img {
    margin-bottom: 10px; }
  .doctor.sem-horarios p.title {
    text-align: center;
    margin-bottom: 10px;
    line-height: 20px;
    padding: 0 10px; }
  .doctor .schedules {
    padding: 0px; }
  .doctor.sem-horarios p.instruction {
    margin: 0;
    padding: 0 10px; }
  .doctor .timetable {
    padding: 0px 10px 20px 10px; }
  .doctor .timetable .hour {
    font-size: 24px;
    font-weight: 100;
    margin: 0px 5px 0 0;
    width: 60px; }
  .doctor .timetable .patient {
    margin: 10px auto;
    width: 100%;
    text-align: center; }
  .doctor .timetable .patient .dados-user {
    justify-content: center !important;
    text-align: left; }
  .doctor .timetable .actions {
    justify-content: flex-end; } }

.barra-filtro ~ .fixed-date,
.barra-filtro ~ .listaTarefas,
.barra-filtro ~ .calendar {
  margin-top: 80px; }

.listaTarefas .date {
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: bold; }

.listaTarefas .month {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 30px; }

.listaTarefas .font-details {
  font-size: 14px;
  color: #000;
  margin-bottom: -3px; }

.listaTarefas .font-details.count {
  font-weight: bold; }

.listaTarefas .margin {
  background: #eff3f4;
  height: 115px;
  width: 50%;
  top: 80px;
  position: fixed;
  z-index: 98; }

.fixed-date .calendar-date {
  position: fixed;
  cursor: default;
  margin-top: 14px;
  border: 1px solid #fff !important; }

.tarefas {
  display: flex;
  justify-content: center;
  margin: auto; }

.listaTarefas .tarefas {
  border-left: 1px solid #d8dde3; }

.filter.listaTarefas .tarefas {
  justify-content: center;
  border-left: none; }

.tarefas .top {
  display: flex;
  justify-content: space-between;
  background: #eff3f4;
  z-index: 10;
  top: 60px;
  width: 90%;
  height: 80px;
  padding-top: 20px;
  align-items: center;
  border-radius: 0;
  position: sticky;
  max-width: 90%; }

.tarefas .top .background {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 25px 25px 3px 3px; }

.tarefas .top .photo-name {
  padding-left: 20px; }

.tarefas .top .photo-name .avatar {
  margin-right: 1px; }

.tarefas .top .photo-name .avatar .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 24px; }

.tarefas .top .title {
  padding-left: 10px;
  text-align: left;
  width: 100%; }

.tarefas .box-alerta {
  background-color: white;
  width: 90%;
  padding: 20px 32px;
  max-width: 90%;
  margin-top: 11px; }

.tarefas .box-alerta p {
  margin-bottom: 0px; }

.tarefas .top .title .name {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%; }

.tarefas .top .title .info {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%; }

.tarefas .count {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding-right: 30px; }

.tarefas .count p {
  margin-bottom: 0px; }

.tarefas .schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  background: #f0f5f9;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 0 0 25px 25px;
  max-width: 90%; }

.tarefas .timetable {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eff3f4;
  width: 100%;
  min-height: 60px; }

.tarefas .timetable .hour {
  font-size: 18px;
  font-weight: 300;
  max-width: 80px;
  margin: auto 24px; }

.tarefas .timetable .patient {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-grow: 1;
  margin: 30px 0; }

.tarefas .timetable .patient .select {
  padding: 0;
  width: 100%; }

.tarefas .timetable .patient .name {
  font-weight: bold; }

.tarefas .timetable .actions {
  padding: 0;
  display: flex;
  align-items: center; }

@media (max-width: 1024px) {
  .barra-filtro ~ .fixed-date,
  .barra-filtro ~ .listaTarefas,
  .barra-filtro ~ .calendar {
    margin-top: 0; } }

@media (max-width: 1023px) {
  .fixed-date .calendar-date {
    position: initial;
    margin-top: 14px;
    height: 80px;
    max-width: 95%;
    background: #fff;
    box-shadow: none; }
  .tarefas {
    margin: 0 -20px; }
  .listaTarefas .tarefas {
    border-left: none; }
  .tarefas .top {
    height: 200px; }
  .tarefas .top .photo-name .avatar .avatarCustom {
    width: 60px;
    height: 60px; }
  .tarefas .top .title .name {
    text-align: center;
    margin: 10px 0 0; }
  .tarefas .top .title .info {
    text-align: center; }
  .tarefas.sem-horarios {
    width: 100%;
    margin: 10px auto 0; }
  .tarefas.sem-horarios img {
    margin-bottom: 10px; }
  .tarefas.sem-horarios p.title {
    text-align: center;
    margin-bottom: 10px;
    line-height: 20px;
    padding: 0 10px; }
  .tarefas.sem-horarios p.instruction {
    margin: 0;
    padding: 0 10px; }
  .tarefas .timetable {
    padding-bottom: 20px; }
  .tarefas .timetable .hour {
    font-size: 24px;
    font-weight: 100;
    margin: 20px 0 0; }
  .tarefas .timetable .patient {
    margin: 10px auto;
    width: 100%;
    text-align: center; }
  .tarefas .timetable .patient .dados-user {
    justify-content: center !important;
    text-align: left; }
  .tarefas .timetable .actions {
    justify-content: flex-end; } }

.atendimento-row {
  border-bottom: 1px solid #d8dde3;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px; }
  .atendimento-row .icon-status-cancelado {
    height: 24px;
    width: 24px; }
  .atendimento-row .atendimento-status {
    padding: 5px;
    margin: 5px;
    width: 150px;
    height: 25px;
    left: 145px;
    top: 130px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px; }
  .atendimento-row .atendimento-status-cancelado {
    color: #ff0000; }
  .atendimento-row .atendimento-dados {
    min-width: 0;
    padding: 0 20px; }
  .atendimento-row .atendimento-dados .obs {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px; }
  .atendimento-row .atendimento-acao {
    margin: 0 20px; }
    .atendimento-row .atendimento-acao button {
      box-shadow: none; }

.atendimento-row:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 10px; }

@media (max-width: 1023px) {
  .atendimento-row {
    padding: 5px 0px 20px; }
    .atendimento-row .atendimento-data {
      width: 100%;
      margin-bottom: 30px; }
    .atendimento-row .atendimento-dados {
      width: 100%;
      padding: 0 10px 10px; } }

.consultas-container {
  padding-top: 5px;
  background-color: #02cb7d;
  border: 1px solid #02cb7d;
  width: 100% !important; }

.mr-50 {
  margin-right: 50% !important; }

.w-c-50 {
  width: 50%; }

.consultas-container-texto {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  height: 30px;
  box-shadow: none; }

.prontuario label {
  font-size: 12px; }

.hr-green {
  border-top: 1px solid #56cb7d; }

.checkbox-container {
  background-color: #fff;
  padding-bottom: 10px;
  border: 1px solid #02cb7d;
  border-top: none;
  width: 100% !important; }

.anamnese-anterior-topo {
  top: 60px;
  position: sticky;
  z-index: 100; }

.sem-consulta-container {
  background-color: #fff;
  padding-bottom: 10px;
  width: 100% !important; }

.sem-consulta {
  padding-top: 5px;
  background-color: #fff;
  border-top: 1px solid #2a444e;
  width: 100% !important;
  height: 60px; }

.sem-consulta p {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 0; }

.exameAnteriores {
  height: 60px;
  overflow: auto; }

.descricao-anamnese table {
  width: 100%; }

#anamnese-sintetico p {
  margin: 0px; }

@media (max-width: 414px) {
  .mr-50 {
    margin-right: 0% !important; }
  .w-c-50 {
    width: 100%; }
  .descricao-anamnese {
    padding: 10px; }
  .prontuario-box-sintetico .font-date {
    font-size: 16px;
    font-weight: bold; }
  .prontuario-box-sintetico .font-professional {
    font-size: 13px; }
  .descricao-receituario {
    margin-top: 5px;
    padding: 10px; } }

.prontuario-box {
  margin-top: 20px; }
  .prontuario-box .col-esquerda,
  .prontuario-box .col-direita {
    padding: 0px 21px;
    z-index: -1; }
  .prontuario-box .calendar-date {
    width: 300px;
    height: 80px;
    max-width: 300px; }
    .prontuario-box .calendar-date .font-date {
      font-size: 22px; }
  .prontuario-box .col-direita {
    width: 152px;
    min-width: 152px; }
  .prontuario-box .col-esquerda .avatar {
    margin-left: 4px;
    z-index: 100; }
  .prontuario-box .col-esquerda .calendar-date {
    cursor: default; }
  .prontuario-box .col-esquerda .calendar-date {
    margin-top: 120px; }
  .prontuario-box .col-esquerda .calendar-date:hover {
    border: 1px solid #fff; }
  .prontuario-box hr {
    margin: 10px 0; }
  .prontuario-box .view-doencas {
    font-weight: bold;
    margin: -5px 35px 0;
    font-style: italic; }
  .prontuario-box .descricao-anamnese p,
  .prontuario-box .descricao-anamnese ul,
  .prontuario-box .descricao-anamnese ol {
    font-size: 14px;
    margin: 0 15px; }
  .prontuario-box .buttons-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%; }
  .prontuario-box .buttons-bottom .btn {
    min-width: 165px; }
  .prontuario-box .buttons-bottom .btn-secondary {
    box-shadow: none; }
  .prontuario-box span.icon-btn-receituario {
    content: url("./images/icon-receituario.svg");
    width: 20px;
    margin-bottom: -5px;
    margin-right: 0px; }
  .prontuario-box .buttons-bottom .destaque {
    font-size: 12px; }
  .prontuario-box span.icon-btn-anexar {
    content: url("./images/icon_anexar.svg");
    width: 20px;
    margin-bottom: -5px;
    margin-right: 0px; }
  .prontuario-box .buttons-bottom span.icon-btn-imprimir {
    content: url("./images/icon-print.svg");
    float: initial; }
  .prontuario-box .receituario button {
    box-shadow: none; }
  .prontuario-box .receituario .receituario-label-buttons label {
    margin-top: 10px;
    font-size: 12px;
    color: #02cb7d; }
  .prontuario-box .receituario-content {
    margin-bottom: 65px;
    min-height: 300px; }
  .prontuario-box .receituario-content .receituario-info {
    text-align: center;
    max-width: 300px;
    margin: 40px auto;
    color: #a9afb5;
    font-weight: 200; }
  .prontuario-box .receituario .receituario-buttons button {
    margin: 5px; }
  .prontuario-box .footer-folhas {
    margin: 5px 21px;
    max-width: 100%; }

.prontuario-box:last-child {
  margin-bottom: 80px; }

.prontuario-box-sintetico {
  margin-top: 0; }

.prontuario-box-sintetico:last-child {
  margin-bottom: 0; }

@media (max-width: 1280px) {
  .prontuario-box .box-folhas {
    min-width: 946px; } }

@media (max-width: 1024px) {
  .prontuario-box .box-folhas {
    min-width: 994px; }
  .prontuario-box .col-esquerda,
  .prontuario-box .col-direita {
    border: none; }
  .prontuario-box .col-esquerda .calendar-date {
    margin: 0 0 10px 40px;
    width: 220px;
    max-width: 220px; }
  .prontuario-box .col-esquerda .avatar {
    margin-left: 2px;
    z-index: 1; } }

@media (max-width: 1023px) {
  .prontuario-box .col-esquerda {
    margin-bottom: 10px;
    padding: 0 0 10px 0; }
  .prontuario-box .col-esquerda .calendar-date {
    margin: 14px auto 0;
    height: 80px;
    max-width: 100%;
    width: 95%;
    background: #fff;
    box-shadow: none; }
  .prontuario-box .col-esquerda .calendar-date .font-date {
    font-size: 30px;
    margin: 0 6px 0 10px; }
  .prontuario-box .box-folhas {
    min-width: 0;
    width: 100%;
    min-width: 0;
    z-index: 10; } }

.anamnese .table td {
  padding: 5px; }

.prontuario {
  background-color: #fff;
  padding: 10px 30px 20px 10px; }

.prontuario > div {
  flex-basis: 50%; }

.btn span.icon-anexar {
  content: url("./images/icon-anexar.svg");
  width: 20px;
  height: 20px; }

.prontuario .btn.btn-warning {
  color: #fff;
  background-color: #cb9e00; }

.prontuario .nav .nav-item {
  margin: 10px auto 0px auto;
  min-width: 30%;
  cursor: pointer; }

.prontuario .nav .nav-item .nav-link {
  padding: 10px 20px;
  border-color: #dee2e6 #dee2e6 #eff3f4; }

.prontuario .nav-tabs {
  border-bottom: 0px; }

.icon-receituario {
  content: url("./images/icon-receituario.svg");
  width: 25px;
  height: 25px; }

.icon-procedimentos {
  content: url("./images/icon-procedimentos.svg");
  width: 25px;
  height: 25px; }

.icon-exames {
  content: url("./images/icon-exames.svg");
  width: 25px;
  height: 25px; }

.btn-incluir {
  background-color: #00cbcb;
  color: #fff; }

.icon-incluir {
  content: url("./images/icon-incluir.svg"); }

.tabela-prontuario {
  border: 1px #f2f3f5 solid;
  font-family: 'Nunito', sans-serif;
  font-size: 12px; }

.tabela-prontuario .row:nth-child(odd) {
  background-color: #f2fcf8; }

.tabela-prontuario .row:nth-child(even) {
  background-color: #f2f3f5; }

.icon-edit {
  content: url("./images/icon-edit.svg"); }

.tabela-prontuario .icon-delete {
  content: url("./images/icon-delete.svg");
  width: 20px;
  height: 20px; }

.procedimentos .form-control,
.receituario-exame .form-control,
.modal-cids .form-control {
  height: 38px; }

.modal-cids {
  max-width: 800px; }

.box-list-cid {
  height: 600px;
  overflow: auto; }

.item-list-cid {
  border: 1px #ededed solid;
  font-size: 15px;
  font-weight: 600; }

.cabecalho-list-cid {
  border: 1px #cccc solid;
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 10000; }

.cabecalho-list-cid p {
  font-size: 18px;
  font-weight: 800; }

.btn span.icon-search {
  content: url("./images/icones/search.svg");
  width: 20px;
  height: 20px; }

.btn span.ico_mais {
  content: url("./images/ico_mais-white.svg");
  width: 15px;
  height: 15px; }

.btn span.icon-close {
  content: url("./images/icon_fechar_WHITE.svg");
  width: 15px;
  height: 15px; }

@media (max-width: 414px) {
  .prontuario {
    padding: 5px;
    width: 100%; }
  .prontuario .nav .nav-item {
    margin: 1px; }
  .prontuario > div {
    flex-basis: 100%; }
  .prontuario .nav .nav-item .nav-link {
    padding: 5px; }
  .receituario-exame {
    width: 100%; }
  .anamnese {
    width: 100%; } }

.prontuario.d-flex .header-prontuario {
  flex-basis: 100%; }

.header-prontuario h1,
.header-prontuario > div {
  flex-basis: 50%; }

.icon-print {
  content: url("./images/print.svg"); }

.icon-triagem {
  content: url("./images/icon-param-triagem-white.svg"); }

.header-prontuario h1 {
  font-size: 22px; }

.agenda-online-filter {
  min-width: 0px;
  padding: 0px 0 0 20px; }
  .agenda-online-filter .input-required .is-invalid .react-select__control {
    border: 2px solid #ea3a45 !important; }
  .agenda-online-filter h2 {
    text-align: center;
    max-width: 400px;
    margin: auto; }
  .agenda-online-filter label {
    color: #000;
    font-size: 12px;
    font-style: normal;
    margin-left: 2px; }
  .agenda-online-filter .icone-label {
    filter: brightness(0); }
  .agenda-online-filter .filters {
    max-width: 400px;
    margin: auto; }
  .agenda-online-filter .tag {
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #2cda97;
    margin: 6px;
    font-weight: 700;
    text-align: center;
    color: #053c48; }
  .agenda-online-filter .tag-success {
    background-color: #02cb7d;
    border-radius: 10px;
    color: #fff;
    margin: 0px auto 0;
    padding: 20px 50px;
    text-align: center;
    max-width: 500px; }

.agenda-online-info {
  border-left: 1px solid #eff3f4;
  margin: 0 0px;
  padding: 0 40px;
  display: flex;
  justify-content: center; }
  .agenda-online-info .recomendacoes {
    max-width: 380px;
    margin: auto; }
    .agenda-online-info .recomendacoes h2 {
      font-weight: bold;
      font-style: italic;
      margin-bottom: 20px; }

.agenda-online-content {
  border-left: 1px solid #eff3f4;
  margin: 0 20px;
  min-width: 800px;
  height: 100%; }
  .agenda-online-content .doctor {
    margin: 0 0px; }

@media (max-width: 1024px) {
  .agenda-online-filter {
    max-width: 100%;
    padding: 0 0 50px; }
  .agenda-online-content {
    border: none;
    min-width: 0;
    margin: 0; }
  .agenda-online-info {
    border-left: none;
    border-top: 1px dashed #a9afb5;
    min-width: 0;
    margin: 0;
    padding: 30px 10px 0;
    min-height: 0; } }

@media (max-width: 1023px) {
  .agenda-online-content .doctor {
    margin: 0 0;
    width: 100%;
    padding: 0px;
    margin-top: 70px; }
    .agenda-online-content .doctor .top .background {
      height: 200px; }
    .agenda-online-content .doctor .schedules {
      margin-top: 70px; } }

.metric-cards {
  background: #f8f9fa !important;
  padding: 20px;
  margin-left: 30px;
  margin-right: 20px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50px;
  transition: all 500ms; }

.metric-card {
  width: 19rem; }

.metric-card-title {
  font-weight: 800;
  color: #546e7a;
  font-size: 18px;
  padding-bottom: 40px;
  letter-spacing: 1px;
  margin-bottom: 0; }

.metric-card-text {
  font-weight: 800;
  color: #546e7a;
  font-size: 60px;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-right: 15px; }

.metric-card-body {
  padding-top: 1px; }

.metric-card-pacientes-agendados {
  color: #bc4b0d; }

.metric-card-pacientes-atendidos {
  color: #606c38; }

.metric-card-pacientes-confirmados {
  color: #02457a; }

.metric-card-pacientes-faltantes {
  color: #8c2f39; }

.chart-cards {
  background: #f8f9fa !important;
  padding: 20px;
  margin-left: 30px;
  margin-right: 20px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50px;
  transition: all 500ms; }

.chart-card {
  width: 18rem;
  height: 34rem; }

.chart-card-body {
  padding-top: 1px; }

.chart-card-title {
  font-weight: 800;
  color: #546e7a;
  font-size: 18px;
  padding-bottom: 10px;
  letter-spacing: 1px; }

.chart-card-pacientes-first {
  width: 100%;
  height: 70%; }

.chart-card-pacientes-second {
  width: 100%;
  height: 30%; }

.chart-card-full {
  width: 100%;
  height: 100%; }

.column-chart-convenios {
  width: 100%; }

.column-chart-convenios-with-padding {
  width: 100%;
  padding: 43px; }

.chart-card-h85p {
  width: 100%;
  height: 85%; }

.chart-tempo-atendimento-lengenda-main {
  height: 15%;
  width: 100%;
  color: #546e7a;
  font-size: 11px;
  padding-top: 22px; }

.chart-tempo-atendimento-legenda {
  width: 10%;
  margin: 8px 7px 10px 10px; }

.chart-tempo-atendimento-minimo {
  border: 1px solid #7c8657; }

.chart-tempo-atendimento-medio {
  border: 1px solid #b95722; }

.chart-tempo-atendimento-maximo {
  border: 1px solid #831a26; }

.search-form-row {
  width: 94%; }

.chart-text-blank {
  width: 100%;
  height: 100%;
  font-weight: 800;
  color: #546e7a;
  font-size: 13px;
  padding: 37px;
  letter-spacing: 1px;
  display: flex;
  align-items: center; }

@media (max-width: 414px) {
  .metric-cards {
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border-radius: 0px; }
  .column-chart-convenios-with-padding {
    padding: 15px; }
  .chart-card {
    width: 100%;
    margin-top: 10px; }
  .chart-cards {
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border-radius: 0px; } }

.calendar {
  max-width: 800px;
  margin: auto; }

.agenda-online-content .calendar {
  margin: initial; }

.calendar .arrowMonth {
  padding: 0;
  text-align: center; }

.calendar .arrowMonth img {
  margin-top: 18px;
  height: 20px;
  cursor: pointer; }

.calendar .month {
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  text-align: center;
  margin: 0 auto 30px;
  cursor: pointer;
  background-color: #f8f8f8;
  color: #000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 250px;
  padding: 10px 0;
  height: 90px; }

.calendar .week {
  font-size: 14px;
  text-align: center; }

.calendar .week > div {
  max-width: 100px;
  width: 100px;
  margin: 5px; }

.calendar-date {
  text-align: center;
  width: 100px;
  height: 100px;
  padding: 0;
  margin: 5px;
  border: 1px solid #fff;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  max-width: 100px;
  transition: all 500ms;
  cursor: pointer; }

.calendar .calendar-date:hover {
  background: #fff; }

.calendar-date.view {
  cursor: default; }

.calendar-date .font-date {
  font-size: 30px;
  font-weight: 600;
  margin: 0; }

.calendar-date .font-details {
  font-size: 12px;
  color: #85898e;
  margin-bottom: -3px;
  min-height: 15px;
  border-top: 1px solid #eff3f4;
  padding-top: 4px;
  margin: -4px -20px 0; }

.calendar-date .font-details:last-child {
  margin: -6px 0 0;
  border: none; }

.calendar-date .font-details.bold {
  font-weight: bold; }

.calendar-date .red {
  color: #dc3545 !important; }

.calendar-date .font-details.italic {
  font-style: italic;
  font-weight: 300; }

.calendar-date.date-actual {
  opacity: 1 !important;
  border: 2px solid #02cb7d !important;
  background: #fff; }

.calendar-date .disponivel {
  color: #02cb7d;
  font-weight: 600;
  margin: -12px 0 0 !important; }

.calendar-date.agenda-vazia {
  background: none;
  box-shadow: none;
  opacity: 0.4;
  border: 1px solid #d8dde3; }

.calendar-date.agenda-vazia:hover {
  background: #f0f5f9;
  border: 1px solid #a9afb5;
  opacity: 1; }

.calendar-date .font-details.concluido {
  margin: -10px 0 6px; }

.calendar-date .font-details.nenhuma-agenda {
  border: none;
  margin: -10px 0 -2px; }

.calendar-date .font-date.big {
  margin-top: 2px; }

.calendar-date .nome-mes {
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 1px;
  font-weight: 600;
  margin: -6px 0 -2px; }

.calendar-date .ano {
  font-size: 10px;
  color: #a9afb5;
  margin: 0; }

.calendar-date .diaSemana {
  font-size: 10px;
  color: #02cb7d;
  font-weight: bold;
  margin: 0;
  line-height: 9px;
  margin-top: 4px; }

.calendar-date .prev,
.calendar-date .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  margin: -8px;
  height: 70%;
  transition: all 250ms;
  cursor: pointer; }

.calendar-date .prev {
  border-right: 1px solid transparent; }

.calendar-date .prev:hover {
  border-right: 1px solid #eff3f4; }

.calendar-date .next {
  border-left: 1px solid transparent; }

.calendar-date .next:hover {
  border-left: 1px solid #eff3f4; }

.calendar-date .arrows {
  width: 10px;
  height: 15px;
  margin: 2px;
  cursor: pointer;
  transition: all 250ms; }

.calendar-date .prev:hover .arrows,
.calendar-date .next:hover .arrows {
  filter: opacity(1); }

.lista-horarios {
  position: sticky;
  top: 60px;
  z-index: 11;
  background-color: #eff3f4;
  padding-bottom: 20px; }

.lista-horarios .calendar-date {
  text-align: center;
  width: 400px;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 400px;
  margin: 0px;
  box-shadow: none; }

.month-picker {
  width: 100%; }

.month-picker > div {
  width: 100%; }

.month-picker > .rmp-container .rmp-overlay {
  background: none; }

.month-picker > .rmp-container .rmp-cell {
  display: flex;
  justify-content: center; }

.month-picker > .rmp-container .rmp-popup.light {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 40px #85898e66;
  border: none;
  padding: 20px;
  z-index: 1003; }

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  color: #000; }

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  background: url("./images/icones/arrow-left.svg") no-repeat center;
  background-size: 40px 20px;
  color: transparent; }

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i:hover {
  background-color: transparent !important; }

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
  transform: rotate(180deg); }

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  border-radius: 10px; }

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
  background: none;
  color: transparent; }

.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: #02cb7d;
  font-weight: 800; }

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
  background-color: #f0f5f9; }

@media (max-width: 1023px) {
  .calendar {
    max-width: 340px; }
  .calendar .arrowMonth img {
    margin-top: 20px;
    height: 16px; }
  .calendar .week > div {
    padding: 0 5px; }
  .calendar-date {
    height: 38px;
    box-shadow: -2px -2px 4px #ffffff88, 2px 2px 4px #85898e21;
    border: none; }
  .calendar-date.view {
    height: 70px;
    min-width: 100%;
    margin: 0;
    background: #fff;
    box-shadow: none; }
  .calendar-date .arrows {
    width: 20px;
    height: 16px; }
  .calendar-date .font-date {
    font-size: 16px;
    font-weight: 700;
    margin: 2px 0 0; }
  .calendar-date .font-date.big {
    font-size: 30px;
    margin: 0 6px 0 10px; }
  .calendar-date .info-fixed-date p {
    text-align: left; }
  .calendar-date .nome-mes {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-right: 4px;
    margin-top: -4px; }
  .calendar-date .ano {
    font-size: 12px;
    text-align: left;
    margin: 0; }
  .calendar-date.date-actual {
    box-shadow: none;
    border: 1px solid #02cb7d !important;
    color: #02cb7d; }
  .month-picker .rmp-container .rmp-popup {
    left: 0px; }
  .month-picker > .rmp-container .rmp-overlay {
    background: none; }
  .month-picker > .rmp-container .rmp-popup.light {
    margin: 0 5% 5% 5%;
    width: 90%;
    height: 250px; } }

.dados-user {
  min-width: 0; }
  .dados-user .avatar .avatarCustom {
    width: 42px;
    height: 42px;
    font-size: 20px; }
  .dados-user .info-secundaria {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dados-user .nome {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dados-user .min-0 {
    min-width: 0; }

.folha-anamnese {
  background-color: #fff;
  min-height: 600px;
  flex-basis: 50%;
  border-top: 2px solid #2a444e;
  position: relative;
  padding: 30px 30px 20px 30px;
  margin: 0px 10px; }
  .folha-anamnese .titulo {
    font-size: 16px;
    font-weight: bold; }

.folha-anamnese .btn-secondary {
  box-shadow: none; }

.folha-anamnese .folha-topo .folha-icon {
  width: 20px;
  filter: brightness(0);
  margin-right: 6px; }

.folha-conteudo {
  position: relative; }

@media (max-width: 1023px) {
  .folha-anamnese {
    max-width: 100%;
    padding: 24px; }
  .folha-anamnese:first-child {
    border-bottom: 20px solid #eff3f4; } }

.med-prescrito {
  margin: 15px 0 15px; }
  .med-prescrito .number {
    font-size: 14px;
    font-weight: bold;
    background-color: #2cda97;
    color: #fff;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    max-width: 35px;
    min-width: 35px; }

@media (max-width: 1023px) {
  .med-prescrito .number {
    margin: 0 auto 10px; }
  .med-prescrito p {
    text-align: center; } }

.barra-filtro {
  position: relative;
  width: 98%;
  background-color: #fff;
  padding: 7px 15px;
  margin: 0 1%;
  z-index: 100; }

.barra-filtro .icon-filtro {
  position: inherit;
  background: #f0f5f9;
  width: 40px;
  height: 40px;
  box-shadow: none; }

.barra-filtro .icon-filtro img {
  fill: #02cb7d;
  height: 16px;
  filter: brightness(1); }

.barra-filtro .avatarCustom {
  width: 42px;
  height: 42px;
  font-size: 18px;
  font-weight: bold;
  margin: inherit; }

.barra-filtro .dados-filtro p {
  margin-bottom: 0px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.barra-filtro .dados-filtro span.info-secundaria {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.barra-filtro .tipo-filtro span {
  font-size: 12px; }

.barra-filtro .tipo-filtro img {
  filter: brightness(0); }

.barra-filtro .icon-limpar-filtro {
  cursor: pointer; }

@media (max-width: 1024px) {
  .barra-filtro {
    bottom: 10px;
    top: inherit; } }

@media (max-width: 1023px) {
  .barra-filtro {
    position: fixed;
    top: inherit;
    bottom: 10px;
    margin-right: 14px; }
  .barra-filtro .dados-filtro {
    max-width: 90%; }
  .barra-filtro .dados-filtro p {
    width: 90%; }
  .barra-filtro .dados-filtro span.info-secundaria {
    width: 90%; } }

@media (max-width: 320px) {
  .barra-filtro .icon-filtro {
    display: none !important; } }

.modal-body form h2.w-100 {
  background-color: transparent;
  color: #000;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase; }

.modal-body .form-inside {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 0px 0px 0px; }
  .modal-body .form-inside .section-form.item-view div.form-group {
    background-color: initial;
    padding: 5px; }
  .modal-body .form-inside .section-form {
    color: #85898e;
    font-size: 10px;
    font-style: italic;
    padding: 0px 15px; }
    .modal-body .form-inside .section-form .convenios .control--checkbox span {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      font-style: normal; }
    .modal-body .form-inside .section-form > h6 {
      padding-left: 15px;
      font-size: 10px;
      line-height: 30px;
      margin-bottom: 0px;
      height: 30px !important;
      background-color: #f0f5f9; }
    .modal-body .form-inside .section-form > .convenios label.desabilitado {
      font-size: 12px;
      font-style: normal;
      padding: 0px 10px; }
    .modal-body .form-inside .section-form div.form-group .form-control {
      background-color: transparent; }
    .modal-body .form-inside .section-form .horario-agenda {
      padding: 0px 5px;
      font-size: 14px; }
    .modal-body .form-inside .section-form div.form-group,
    .modal-body .form-inside .section-form div.form-row div.form-group {
      padding-right: 5px;
      padding-left: 5px;
      background-color: transparent;
      padding-bottom: 3px;
      margin-bottom: 0px; }
    .modal-body .form-inside .section-form > div .form-control:focus {
      box-shadow: 0 0 0px #1f8e86 !important;
      outline-offset: 0px !important;
      outline: none !important;
      border: none; }
    .modal-body .form-inside .section-form div > label {
      margin: 0px; }
    .modal-body .form-inside .section-form div.form-group .form-control {
      width: 100%;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid #a9afb5;
      height: 35px;
      padding: 0 5px; }
    .modal-body .form-inside .section-form div.form-group .form-control:disabled,
    .modal-body .form-inside .section-form div.form-group .form-control[readonly] {
      color: #999999;
      opacity: 1;
      border: none;
      background-color: #fff; }
    .modal-body .form-inside .section-form div.form-group .form-control.is-invalid {
      border-bottom: 2px solid #ea3a45; }
    .modal-body .form-inside .section-form div.form-group {
      padding: 20px 10px;
      width: 0; }
    .modal-body .form-inside .section-form div.form-group.central {
      max-width: 140px;
      min-width: 70px;
      padding: 20px 5px;
      text-align: center; }
    .modal-body .form-inside .section-form div.form-group.central .Select .react-select__value-container {
      display: flex !important;
      justify-content: center !important; }
    .modal-body .form-inside .section-form div.form-group.central.fixo {
      width: 70px; }
    .modal-body .form-inside .section-form .quebra {
      flex-basis: 100%;
      height: 0; }
    .modal-body .form-inside .section-form div.form-group.fixo {
      width: 210px;
      flex: 0 0 auto !important; }
    .modal-body .form-inside .section-form div.form-group.large {
      width: 30%; }
    .modal-body .form-inside .section-form div.form-group.full {
      width: 80%; }
    .modal-body .form-inside .section-form > .w-100 + div {
      border-left: 1px solid #eff3f4; }
    .modal-body .form-inside .section-form > .quebra + div {
      border-left: 1px solid #eff3f4; }
    .modal-body .form-inside .section-form .form-group.campoPendente {
      outline: 2px solid #3c6669;
      outline-offset: -2px;
      z-index: 2;
      background-size: 10px;
      background-position: 97% center;
      background-repeat: no-repeat; }
    .modal-body .form-inside .section-form .icone-pendente {
      display: none; }
    .modal-body .form-inside .section-form .form-group.campoPendente .icone-pendente {
      display: inline-flex; }
    .modal-body .form-inside .section-form div.form-group.w-20 {
      width: 20%; }
    .modal-body .form-inside .section-form .label-nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0; }
    .modal-body .form-inside .section-form .input-required label {
      font-weight: bold; }
    .modal-body .form-inside .section-form label {
      color: #000;
      font-size: 12px;
      padding-left: 0px;
      display: inline-block;
      padding: 0px 5px; }
    .modal-body .form-inside .section-form .form-group.central label {
      padding-left: 0px;
      padding-right: 0px; }
    .modal-body .form-inside .section-form > div.form-group .input-group .form-control {
      width: 90%; }
    .modal-body .form-inside .section-form > div.form-group .input-group .input-group-text {
      background-color: transparent;
      border: none;
      border-radius: 0; }
    .modal-body .form-inside .section-form > div.form-group textarea.form-control {
      min-height: 53px; }
    .modal-body .form-inside .section-form > div.form-group.central .form-control {
      text-align: center; }
    .modal-body .form-inside .section-form div.form-group .input-required input {
      border-color: #000000;
      border-width: 2px;
      color: #000000; }
    .modal-body .form-inside .section-form div.form-group .form-control:disabled,
    .modal-body .form-inside .section-form div.form-group .form-control[readonly] {
      color: #85898e;
      font-weight: bold;
      background-color: #fff;
      border-bottom: 2px solid #a9afb5; }

.modal-body .Select.form-control .Select-control {
  border: none;
  background-color: transparent;
  text-align: left; }

.modal-body .Select.form-control .Select-control:hover {
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0); }

.modal-body .Select .Select-control .Select-value-label {
  font-size: 12px; }

.modal-body .text-center .Select .react-select__value-container {
  display: flex !important;
  justify-content: center !important;
  margin-left: 30px; }

.modal-body .Select.form-control.is-focused:not(.is-open) > .Select-control {
  box-shadow: none; }

.modal-body .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
  color: #000; }

.modal-body .Select .Select-menu-outer,
.modal-body .react-select__menu {
  font-family: 'Muli', sans-serif;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
  border: none !important;
  box-shadow: 0 0 20px #00000030 !important;
  margin-top: 0px;
  z-index: 300000 !important; }

.modal-body .Select .Select-placeholder,
.modal-body .Select.Select--single > .Select-control .Select-value,
.modal-body .react-select__placeholder {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  color: #a9afb5 !important;
  border-radius: 10px; }

.modal-body .Select {
  padding: 0px;
  background-color: transparent;
  min-height: 40px;
  font-family: 'Muli', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border-radius: 10px;
  border: initial; }

.modal-body .Select .react-select__control {
  min-height: 30px;
  font-family: 'Muli', sans-serif;
  border-radius: 0px;
  border: none;
  border-bottom: 2px solid #a9afb5;
  background-color: transparent;
  cursor: pointer; }

.modal-body .Select.is-invalid .react-select__control {
  border-bottom: 2px solid #ea3a45; }

.modal-body .Select.is-invalid .react-select__control .css-1uccc91-singleValue {
  color: #ea3a45; }

.modal-body .input-required .react-select__control {
  border-color: #000;
  border-width: 2px; }

.modal-body .Select .Select-placeholder,
.modal-body .Select.Select--single > .Select-control .Select-value,
.modal-body .react-select__placeholder {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  color: #a9afb5 !important;
  border-radius: 10px; }

.modal-body .css-tlfecz-indicatorContainer,
.modal-body .css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
  width: 13px;
  height: 20px;
  margin-right: 10px; }

.modal-body .icon-input {
  position: absolute;
  width: 15px;
  top: 12px;
  left: 20px; }

.modalCrud .modal-content {
  border-radius: 10px;
  background-color: #f0f5f9;
  max-width: 638px;
  margin: 0 auto;
  max-height: 90vh; }

.modalCrud .modal-header {
  background-color: #fff;
  height: 80px;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  padding: 0 10px 0 20px; }

.modalCrud .modal-header .modal-header-info {
  width: 100%; }

.modalCrud .modal-header .modal-header-info h1 {
  font-size: 18px;
  margin-left: 10px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.modalCrud .modal-header .modal-header-info .btn-secondary {
  box-shadow: none; }

.modalCrud .modal-body {
  padding: 10px 20px 20px;
  overflow-y: auto;
  max-height: 93vh; }

@media (max-width: 1023px) {
  .modalCrud .modal-content {
    max-width: none;
    width: 96%;
    margin: 10px 10px 20px 6px; }
  .modalCrud .modal-header {
    height: 130px; }
  .modalCrud .modal-header .modal-header-info h1 {
    text-align: center; }
  .modalCrud .modal-header .modal-header-info .btn-secondary {
    border: 1px solid #e1e6eb;
    margin: 0 8px 0;
    padding: 0 20px; } }

.modal-anexos .modal-content {
  padding: 30px;
  background: #f0f5f9;
  border-radius: 10px;
  color: #000;
  border: none;
  box-shadow: 0 0 40px #85898e66; }

.modal-anexos .dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px; }

.modal-anexos .selected-file-wrapper {
  text-align: center; }

.modal-anexos .selected-file {
  color: #000;
  font-weight: bold; }

.modal-anexos .modal-body {
  padding: 0 20px; }

.modal-anexos .top-modal {
  margin-bottom: 20px; }

.modal-anexos .txt-modal {
  font-size: 14px;
  padding-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; }

.modal-anexos .txt-modal:hover {
  text-decoration: underline; }

.modal-anexos .txt-modal.disable {
  color: #a9afb5;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  overflow: initial;
  padding: 0 2px;
  cursor: default;
  text-decoration: none; }

.modal-anexos span.icon-btn-anexar {
  content: url("./images/icon_anexar.svg");
  width: 20px;
  float: left;
  margin-left: -4px;
  margin-bottom: -4px; }

.modal-anexos .btn-add-modal {
  text-align: center; }

.modal-anexos .btn-add-modal .btn-secondary {
  margin-top: 20px;
  box-shadow: none; }

.modal-anexos hr {
  margin: 15px 0 2px;
  border-top: 1px solid #e2e5f2; }

.modal-anexos .font-bold {
  font-weight: bold; }

.modal-anexos .destaque {
  font-size: 12px;
  color: #02cb7d; }

.modal-anexos .pointer {
  cursor: pointer; }

.modal-anexos .borda-separacao {
  border-right: 1px solid #eff3f4; }

.modal-anexos .modal-footer {
  border-top: none;
  flex-wrap: nowrap;
  padding: 0; }

.modal-anexos .btn-icon-only {
  min-width: 0px;
  padding: 0px;
  height: 30px;
  width: 30px;
  margin: 0 0 4px 4px;
  box-shadow: none;
  border-radius: 0px;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-anexos .btn.btn-icon-only:hover {
  transform: none; }

.modal-anexos span.icon-btn-excluir {
  content: url("./images/icones/delete.svg");
  width: 15px;
  height: 15px; }

.modal-soluti-otp .modal-content {
  padding: 30px;
  background: #f0f5f9;
  border-radius: 10px;
  color: #000;
  border: none;
  box-shadow: 0 0 40px #85898e66; }

.modal-soluti-otp .modal-body {
  padding: 0 20px; }

.modal-soluti-otp .top-modal {
  margin-bottom: 20px; }

.modal-soluti-otp .txt-modal {
  font-size: 14px;
  padding-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; }

.modal-soluti-otp .txt-modal:hover {
  text-decoration: underline; }

.modal-soluti-otp .txt-modal.disable {
  color: #a9afb5;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  overflow: initial;
  padding: 0 2px;
  cursor: default;
  text-decoration: none; }

.modal-soluti-otp hr {
  margin: 30px 0 20px; }

.modal-soluti-otp .font-bold {
  font-weight: bold; }

.modal-soluti-otp .pointer {
  cursor: pointer; }

.modal-soluti-otp .borda-separacao {
  border-right: 1px solid #eff3f4; }

.modal-soluti-otp .modal-footer {
  border-top: none;
  flex-wrap: nowrap;
  padding: 0; }

.modal-soluti-otp .btn-icon-only {
  min-width: 0px;
  padding: 0px;
  height: 30px;
  width: 30px;
  margin: 0 0 4px 4px;
  box-shadow: none;
  border-radius: 0px;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-soluti-otp .btn.btn-icon-only:hover {
  transform: none; }

.modal-impressao {
  display: flex;
  justify-content: center; }
  .modal-impressao .min-heigth-modal {
    min-height: 580px; }
  .modal-impressao .modal-content {
    padding: 30px;
    background: #f0f5f9;
    border-radius: 10px;
    color: #000;
    border: none;
    box-shadow: 0 0 40px #85898e66; }
  .modal-impressao .modal-body {
    padding: 0 20px; }
    .modal-impressao .modal-body .nome,
    .modal-impressao .modal-body .posologia {
      margin-bottom: 0px; }
    .modal-impressao .modal-body .nome {
      font-weight: bold; }
    .modal-impressao .modal-body .flag-impresso {
      color: #02cb7d;
      font-size: 12px;
      font-style: italic; }
    .modal-impressao .modal-body .data {
      color: #000;
      font-size: 10px;
      font-style: italic; }
    .modal-impressao .modal-body .flag-nao-impresso {
      color: #dc3545;
      font-size: 12px;
      font-style: italic; }
  .modal-impressao .top-modal {
    margin-bottom: 20px; }
  .modal-impressao hr {
    margin: 5px 0 5px; }
  .modal-impressao .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0; }

@media (max-width: 1023px) {
  .modal-impressao {
    margin: 0 20px 0 10px; } }

.iconProtocolo img {
  height: 80px; }

.iconProtocolo img {
  height: 80px; }

.iconLocal img {
  height: 60px;
  mix-blend-mode: multiply;
  margin-bottom: 20px; }

.iconLocal img {
  height: 60px;
  mix-blend-mode: multiply;
  margin-bottom: 20px; }

.modal-consulta-avulsa label {
  margin-left: 0px;
  font-size: 12px; }

.modal-termo {
  display: flex;
  justify-content: center; }
  .modal-termo .modal-content {
    padding: 30px;
    background: #f0f5f9;
    border-radius: 10px;
    color: #000;
    border: none;
    box-shadow: 0 0 40px #85898e66;
    height: 80vh; }
  .modal-termo .modal-body {
    padding: 0 20px;
    height: 100%;
    overflow-y: scroll; }
  .modal-termo .top-modal {
    margin-bottom: 20px; }
  .modal-termo hr {
    margin: 5px 0 5px; }
  .modal-termo .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0; }

@media (max-width: 1023px) {
  .modal-termo {
    margin: 0 20px 0 10px; } }

.tags {
  border: #f0f0f0 1px solid;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  font-weight: bold; }

.tags:nth-child(even) {
  background-color: #02cb7d; }

.tags:nth-child(odd) {
  background-color: #c0c0c0; }

.icon-remove {
  content: url("./images/icon_x.svg");
  width: 20px;
  height: 20px;
  vertical-align: middle; }

body {
  font-family: "Muli", sans-serif; }

h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0; }

h2 {
  font-size: 18px;
  line-height: 22px; }

p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 17px; }

p.instruction {
  font-size: 12px;
  color: #000;
  margin-bottom: 20px;
  margin-left: 15px; }

hr {
  border-top: 1px solid #eff3f4;
  margin: 30px 0;
  width: 100%; }

.font-md {
  font-size: 18px; }

.font-xs {
  font-size: 12px; }

.h100 {
  height: 100vh; }

.rotate {
  transform: rotate(180deg); }

.filas {
  /* ... */ }
  .filas > *:nth-child(n) > * {
    border-left: 5px solid #4c4dfa;
    background-color: transparent; }
  .filas > *:nth-child(2n) > * {
    border-left: 5px solid #297df9;
    background-color: transparent; }
  .filas > *:nth-child(3n) > * {
    border-left: 5px solid #06b3c7;
    background-color: transparent; }
  .filas > *:nth-child(4n) > * {
    border-left: 5px solid #c4df2e;
    background-color: transparent; }

.barra-status-pendente {
  background-color: #d4dd66;
  color: #697300; }

.barra-status-sucesso {
  background-color: #2cda97;
  color: #fff; }

.barra-status-negado {
  background-color: #ea3a45;
  color: #fff; }

.card.card-fila {
  height: auto; }

.card.login > hr {
  margin-top: 5px;
  margin-bottom: 20px; }

.valor-particular {
  font-size: 15px;
  color: #02cb7d;
  margin-left: 10px; }

.logo {
  background: url("./images/logo.svg") center no-repeat;
  width: 240px;
  height: 180px;
  margin: 0 auto 20px; }

.logo-agenda {
  background: url("./images/icon-agenda-online.svg") center no-repeat;
  width: 100%;
  height: 120px;
  margin: 0 auto 20px; }

.logoOnline {
  height: 70px;
  margin-bottom: 20px;
  width: auto; }

.title-tab {
  font-weight: bold;
  border-bottom: solid 1px #000;
  font-size: 13px; }

.line {
  border-bottom: solid 1px #7f7c7c;
  font-size: 13px;
  padding: 10px 5px 10px 5px; }

.f-10 {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding-top: 11px; }

.modalCrud .modal-content {
  max-width: 800px; }

.logo-sasc-cm {
  background: url("./images/logosasccm.png") center no-repeat;
  background-size: contain;
  width: 310px;
  height: 120px;
  margin: 0 auto 20px; }

.back-elements {
  background: url("./images/background-menu.svg") top left no-repeat;
  width: 100%;
  height: 100vh; }

.back-elements::after {
  content: '';
  background: url("./images/background-menu.svg") top left no-repeat; }

.topo .iconNotification,
.topo .iconMenu,
.topo .iconError {
  height: 26px;
  cursor: pointer; }

.bg-1 {
  background-color: #f0f5f9;
  padding: 20px 20px;
  margin: 20px 0px; }

.bg-2 {
  background-color: #fff;
  padding: 20px 20px;
  margin: 20px 0px; }

.subitem {
  border-top: 1px dotted #d8dde3;
  margin-top: 15px; }

.bg-2 > .subitem,
.bg-1 > .subitem {
  margin-top: 0px; }

.bg-1 input:-webkit-autofill,
.bg-1 input:-webkit-autofill:hover,
.bg-1 input:-webkit-autofill:focus,
.bg-1 input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #f0f5f9 !important;
  -webkit-box-shadow: 0 0 0px 1000px #f0f5f9 inset !important; }

.topo .iconLogo {
  height: 50px;
  cursor: pointer; }

.txt-destaque {
  font-size: 13px;
  font-weight: 700;
  color: #516c80; }

.txt-destaque.red {
  color: #da2c2c; }

.card {
  background: #eff3f4;
  padding: 20px;
  border-radius: 10px;
  transition: all 500ms; }

.card.login {
  width: 420px;
  box-shadow: -10px -10px 20px #ffffff88, 10px 10px 20px #85898e21;
  background-color: #fff;
  margin: auto;
  border: none;
  padding: 40px 50px;
  border-radius: 35px; }

.card.cadastro {
  height: 80vh; }

.checkbox {
  padding: 5px; }

.is-invalid .checkbox {
  border-bottom: 2px solid #ea3a45;
  padding: 5px; }

.termoCheck {
  font-size: 12px;
  padding: 5px 5px 4px 0px;
  font-weight: bold;
  cursor: pointer;
  color: #04af72; }

.fixed-btn {
  position: fixed;
  bottom: 315px;
  right: 1rem;
  z-index: 1001; }

footer {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: -10; }

.bg-riscos {
  background-color: #eff3f4;
  background: url("./images/background-menu.svg") no-repeat; }

.background-lines .bg-riscos {
  position: absolute;
  height: 545px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  background-color: none; }

.bg-riscos.bottom {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  height: 400px;
  width: 600px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute; }

.background-lines {
  position: relative; }

.btn-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: -1;
  bottom: 0; }

.card.telemedicina {
  height: 90vh;
  width: 90vw;
  box-shadow: -10px -10px 20px #ffffff88, 10px 10px 20px #85898e21;
  margin: auto;
  border: none;
  padding: 30px; }

.card.telemedicina iframe {
  border-radius: 20px; }

.card.videocall {
  cursor: move;
  position: fixed;
  z-index: 3000;
  min-width: 30vw; }

.card.videocall iframe {
  border-radius: 20px; }

.card.filter {
  width: 400px;
  margin: auto;
  border: none;
  padding: 20px 50px;
  background: none; }

.card.select {
  position: fixed;
  background: linear-gradient(#fff 0%, #eff3f4 100%);
  border-radius: 10px;
  width: 250px;
  box-shadow: -10px -10px 20px #ffffff88, 10px 10px 20px #85898e21;
  margin-top: 40px;
  margin-left: 10px;
  z-index: 200; }

.cardInfo {
  background-color: #f0f5f9;
  border-radius: 10px;
  padding: 30px;
  border: none !important;
  margin: 10px 20px; }

.cardInfo img {
  width: 260px;
  height: 180px; }

.cardInfo p.title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  padding-left: 20px;
  line-height: 20px; }

.cardInfo p.instruction {
  margin: 0;
  padding-left: 20px; }

.icon-atendimento-prioritario {
  width: 40px;
  margin-left: 10px; }

input {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  height: 40px;
  border-radius: 10px;
  padding: 6px 20px; }

input:focus {
  color: #000 !important; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; }

.nomeProprio {
  text-transform: uppercase; }

.nomeProprioMenu {
  text-transform: uppercase;
  font-size: 12px; }

.select-menu {
  margin: 0px 0px 9px -10px; }

.form-control {
  color: #000;
  font-size: 14px;
  height: 50px;
  border-radius: 10px;
  padding: 6px 20px; }

.form-inside .section-form > div.form-group textarea.textarea-modelo {
  background-color: #f2f5f6;
  padding: 10px;
  min-height: 150px;
  border-radius: 5px; }

.receituario-content .form-control {
  padding: 8px 5px;
  line-height: 30px; }

.form-control:focus {
  box-shadow: none;
  background-color: transparent; }

textarea.form-control {
  background-color: #fff;
  border-radius: 10px;
  min-height: 50px;
  border: 1px solid #f0f5f9;
  resize: none; }

textarea.form-control:focus {
  color: #000; }

span.icon-ver {
  content: url("./images/icon_visualizar.svg");
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer; }

span.icon-ocultar {
  content: url("./images/icon_ocultar.svg");
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer; }

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-radius: 10px; }

.input-group-append {
  position: absolute;
  right: 2px;
  top: 12px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 4; }

.is-invalid .input-group-append {
  display: none; }

.input-group-text {
  background-color: transparent;
  border: none; }

.is-invalid .input-group-text {
  border: 2px solid #ea3a45; }

.pl-5px {
  padding-left: 5px; }

.pl-10px {
  padding-left: 10px; }

.tooltip.show {
  opacity: 1; }

.invalid-tip {
  z-index: 9000; }

.info-tip .tooltip-inner {
  background-color: #2a444eec; }

.tooltip-inner {
  padding: 14px;
  line-height: 16px;
  color: #fff;
  font-size: 12px;
  max-width: 230px; }

.invalid-tip .tooltip-inner {
  background-color: #c7323c;
  box-shadow: 0px 0px 20px #85898e2e; }

.invalid-tip .arrow::before {
  border-top-color: #c7323c; }

.info-tip .tooltip-inner {
  background-color: #2a444eec; }

.info-tip .arrow::before {
  border-top-color: #2a444eec; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #dc3545; }

.float-button-out {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 12; }

.icon-duvida {
  content: url("./images/Info_icon.svg");
  width: 18px;
  margin-left: 0px;
  cursor: pointer; }

.icon-duvida:before {
  content: '\e914'; }

.tool-tip {
  display: inline-block; }

.tool-tip [disabled] {
  pointer-events: none; }

.disabled {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent; }

.btn.btn-sm.btn-link.desabilitado, .btn-group-sm > .btn.btn-link.desabilitado {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent; }

.legends {
  width: 70px; }

.encaixe .legends {
  margin-left: 55px; }

.legenda-consulta {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-right: 4px;
  font-weight: bold;
  padding-top: 0px;
  cursor: context-menu; }

.hora-detalhe-row {
  width: 130px; }
  .hora-detalhe-row .btn.btn-sm, .hora-detalhe-row .btn-group-sm > .btn {
    margin: 0px 5px 0px 24px;
    font-size: 12px;
    padding: 5px 0px;
    text-align: left; }
  .hora-detalhe-row .btn.btn-sm span, .hora-detalhe-row .btn-group-sm > .btn span {
    margin-right: 3px;
    margin-bottom: -1px;
    width: 12px; }

.button-logout {
  width: 130px;
  padding: 0;
  border: none;
  background: none; }

.button-logout:focus {
  outline: 3px solid #d8dde3; }

.button-logout span {
  font-size: 12px;
  margin: 0; }

.txt-red {
  color: #da2c2c; }

.txt-black {
  color: #000; }

.txt-green {
  color: #2dc352; }

.txt-reserva {
  font-size: smaller;
  color: #000; }

.box-obs {
  max-width: 630px; }

.btn.txt-red,
.btn.txt-red:hover,
.btn.txt-red:visited {
  color: #da2c2c; }

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none; }

.btn.txt-green,
.btn.txt-green:hover,
.btn.txt-green:visited {
  color: #2dc352; }

.legenda-consulta.recomendacao {
  background-color: #2a444f;
  border: 1px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a;
  border-radius: 20px; }

.recomendacao::before {
  content: '?';
  color: #ffffff; }

.check-recorrente > label {
  font-size: 12px; }

.check-recorrente .lbl-recorrente {
  font-size: 12px;
  padding: 0px 5px; }

.dias-recorrentes {
  font-size: 13px;
  color: black; }

.legenda-consulta.comum {
  background-color: #efb274;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.legenda-consulta.primeira {
  background-color: #2dc352;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.checkbox-custom {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #02cb7d !important;
  appearance: none;
  cursor: pointer; }

.checkbox-custom:checked {
  background-color: #02cb7d !important; }

.checkbox-custom:checked::after {
  content: '✓';
  color: white;
  font-size: 18px;
  position: absolute;
  top: -2px;
  left: 5px; }

.legenda-consulta.encaixe {
  background-color: #e0f116;
  color: #778007;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.legenda-consulta.retorno {
  background-color: #457bb7;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.legenda-consulta.compareceu {
  background-color: #2cda97;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.legenda-consulta.nao-compareceu {
  background-color: #da2c2c;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 1px 1px #8c8c8c7a; }

.item-precificacao p.form-view {
  line-height: 20px; }

.btn {
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  min-width: 80px;
  padding: 0 10px;
  margin: 10px 5px;
  transition: all 250ms;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: scale(0.99); }

.btn:hover {
  transform: scale(0.95);
  box-shadow: none !important;
  border-color: transparent; }

.btn:active {
  transform: scale(0.92); }

.btn:disabled {
  box-shadow: none !important;
  color: #000 !important;
  transform: scale(1) !important;
  cursor: default;
  opacity: 0.4;
  border: none; }

.bt-size-fixed {
  width: 120px; }

.btn-link {
  font-size: 14px;
  color: #000;
  height: 30px;
  box-shadow: none;
  font-weight: 600;
  transition: all 500ms;
  border: 1px solid transparent; }

.btn-link:hover {
  color: #000;
  text-decoration: none; }

.btn-link:focus {
  color: #000;
  text-decoration: none;
  box-shadow: 0px 0px 0px #ffffff00, 0px 0px 0px #ffffff00;
  border: 0px solid #eff3f4; }

.btn-link.btn-link-black {
  color: #000; }

.btn-link-danger {
  font-size: 12px;
  color: #ea3a45;
  height: 18px;
  box-shadow: none;
  font-weight: bold;
  transition: all 500ms;
  border: 0px solid transparent;
  margin: -1px 5px;
  padding: 0px; }

.btn-link-danger:hover {
  color: #ea3a45;
  text-decoration: none; }

.btn-link-danger:focus {
  color: #ea3a45;
  text-decoration: none;
  box-shadow: 0px 0px 0px #ffffff00, 0px 0px 0px #ffffff00;
  border: 0px solid #eff3f4; }

.btn.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  height: initial; }

a {
  color: #85898e;
  transition: all 500ms; }

a:hover {
  color: #000; }

a:focus {
  outline: none; }

.action {
  cursor: pointer; }

.btn-primary {
  border: none;
  color: #fff !important; }

.btn-primary.light {
  border: none;
  color: #000 !important;
  background: #fff !important; }

.btn-secondary {
  border: 1px solid #e3e7e8;
  color: #000;
  background: #fff !important; }

.btn-secondary:focus {
  border: 1px solid #d8dde3 !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #000; }

.btn-secondary:active {
  border: 1px solid transparent !important;
  color: #000 !important; }

.btn-secondary.save:hover,
.btn-secondary.save:focus {
  color: #428ee6; }

.btn-secondary.delete:hover,
.btn-secondary.delete:focus {
  color: #dc3545; }

.btn-secondary.open:hover,
.btn-secondary.open:focus {
  border: 1px solid #e3e7e8;
  color: #28a745; }

.btn-secondary:hover {
  color: #000; }

.btn span {
  margin-right: 6px;
  margin-bottom: -1px;
  width: 16px; }

span.icon-visualizar {
  content: url("./images/icon_visualizar.svg"); }

span.icon-recorrente {
  content: url("./images/recorrente.png");
  margin-bottom: -3px; }

span.icon-btn-ecxel {
  content: url("./images/icones/export_excel.svg"); }

span.icon-btn-add {
  content: url("./images/icones/circle-plus.svg"); }

span.icon-btn-editar {
  content: url("./images/icones/edit.svg"); }

span.icon {
  display: inline-flex;
  width: 30px;
  height: 24px;
  padding: 0;
  border: 0 !important;
  outline: none !important; }

span.icon.icon-cadeirante {
  background: url("./images/icones/cadeirante.svg") no-repeat center !important; }

span.icon.icon-idoso {
  background: url("./images/icones/idoso.svg") no-repeat center !important; }

span.icon.icon-gestante {
  background: url("./images/icones/gravida.svg") no-repeat center !important; }

span.icon.risco-queda {
  background: url("./images/icones/riscoQueda.svg") no-repeat center !important; }

span.icon-historico {
  content: url("./images/icon-hitorico.svg");
  margin-bottom: -3px;
  width: 20px; }

span.icon-btn-deletar {
  content: url("./images/icon_fechar_BLACK.svg");
  margin-bottom: -3px; }

span.icon-btn-voucher {
  content: url("./images/icon_voucher.svg");
  margin-bottom: -3px; }

span.icon-btn-fechar {
  content: url("./images/icones/circle-x.svg");
  margin-bottom: -3px; }

span.icon-btn-cancelar {
  content: url("./images/icones/close.svg"); }

span.mobile-center {
  text-align: center; }

.btn-integration {
  font-weight: normal; }

.btn-blue {
  border: none;
  color: #fff;
  background: #2a444e !important; }

.btn-blue:focus {
  border: none !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #fff; }

.btn-secondary.search {
  border: 1px solid #eff3f4; }

.btn-blue:hover {
  border: none !important;
  color: #fff; }

.btn-blue:active {
  border: none !important;
  color: #fff !important; }

.btn-edit {
  border: none;
  color: #fff;
  background: #095c7d !important; }

.btn-edit:focus {
  border: none !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #fff; }

.btn-edit:hover {
  border: none !important;
  color: #fff; }

.btn-edit:active {
  border: none !important;
  color: #fff !important; }

.btn-orange {
  color: #fff;
  background-color: #eb9a20;
  border-color: #eb9a20; }

.btn-orange:hover {
  color: #fff;
  background-color: #db8d17;
  border-color: #db8d17; }

.btn-orange:active {
  border: none !important;
  color: #fff !important; }

.btn-orange:focus {
  border: none !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #fff; }

.btn-gray {
  color: #fff;
  background-color: #959595;
  border-color: #959595; }

.btn-gray:hover {
  color: #fff;
  background-color: #7f7c7c;
  border-color: #7f7c7c; }

.btn-gray:active {
  border: none !important;
  color: #fff !important; }

.btn-gray:focus {
  border: none !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #fff; }

.btn-light-blue {
  border: none;
  color: #000;
  background: #eff3f4 !important; }

.btn-light-blue:focus {
  border: none !important;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
  color: #000; }

.btn-light-blue:hover {
  border: none !important;
  color: #000; }

.btn-light-blue:active {
  border: none !important;
  color: #000 !important; }

.btn-arrow {
  background: url("./images/icones/arrow-left.svg") no-repeat center;
  width: 70px;
  height: 24px;
  border: none;
  outline: none !important; }

.btn-arrow.white {
  filter: invert(1); }

.btn-close {
  background: url("./images/icones/close.svg") no-repeat center;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  border: none; }

.cardInfo img.ma-icon {
  width: 16px;
  height: 16px;
  padding-right: 3px; }

.btn.btn-primary:disabled {
  background-color: #eff3f4;
  background: linear-gradient(#eff3f4 0%, #d8dde3 100%) !important; }

.lbl-status {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin-right: 10px; }

.toogleButtons {
  margin-bottom: 10px; }

.toogleButtons .btn-toogle {
  width: 150px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
  margin: 5px 10px;
  transform: scale(1);
  cursor: pointer;
  transition: all 250ms; }

.toogleButtons .btn-toogle.disabled {
  display: inline-block;
  cursor: default; }

.pointer {
  cursor: pointer; }

.toogleButtons .btn-toogle-off {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  font-weight: 600; }

.toogleButtons .btn-toogle-off:hover {
  outline: none !important;
  box-shadow: none !important;
  transform: scale(0.95); }

.toogleButtons .btn-toogle-off.disabled:hover {
  outline: none !important;
  box-shadow: none !important;
  transform: scale(1); }

.toogleButtons .btn-toogle-on {
  color: #fff !important;
  background-color: #02cb7d !important;
  border-color: #02cb7d;
  font-weight: 800; }

.quebra {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden; }

.alert {
  font-size: 14px;
  position: fixed;
  padding: 20px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 30px 30px 0 0;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  box-shadow: 0px -20px 30px #d2d6dc;
  z-index: 1000; }

.alert-danger {
  background-color: #dc3545;
  color: #fff;
  font-weight: 600;
  border: none; }

.alert-success {
  background-color: #28a745;
  color: #fff;
  font-weight: 600;
  border: none; }

.sideMenu {
  height: 100vh;
  background: #516c80; }

.icon-back {
  background: url("./images/icones/arrow-left.svg") no-repeat center;
  width: 30px;
  height: 30px;
  opacity: 0.5; }

.icon-doctor {
  background: url("./images/icones/profissionais.svg") no-repeat center;
  width: 22px;
  height: 22px; }

.icon-convenio {
  background: url("./images/icon-convenio.svg") no-repeat center;
  width: 22px;
  height: 50px; }

.icon-patient {
  background: url("./images/icones/clipboard.svg") no-repeat center;
  width: 22px;
  height: 22px; }

.icon-delete {
  width: 40px;
  height: 40px;
  cursor: pointer; }

.icon-delete img {
  height: 30px; }

.icon-delete.local-horario img {
  height: 40px; }

.icon-delete-2 {
  cursor: pointer; }

.icon-delete-2 img {
  height: 30px; }

.fixed {
  position: fixed; }

.icon-limpar-filtro {
  background: url("./images/ico_x_clear.svg") no-repeat;
  width: 15px;
  height: 16px; }

.icon-filtro {
  position: fixed;
  z-index: 99;
  top: 94px;
  left: 40px;
  background: linear-gradient(#fff 0%, #eff3f4 100%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21; }

.icon-filtro.active {
  background: linear-gradient(#02cb7d 0%, #1ec182 100%); }

.icon-filtro img {
  height: 26px;
  filter: brightness(0); }

.icon-filtro.active img {
  filter: invert(1);
  height: 30px; }

.icon-indisponibilidade {
  position: fixed;
  z-index: 99;
  top: 150px;
  left: 40px;
  background: linear-gradient(#fff 0%, #eff3f4 100%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21; }

.empty-content {
  content: ' '; }

.icon-indisponibilidade.active {
  background: linear-gradient(#02cb7d 0%, #1ec182 100%); }

.icon-indisponibilidade img {
  height: 30px; }

.icon-indisponibilidade.active img {
  height: 30px; }

.alpha {
  opacity: 0.7;
  margin: 10px; }

span.icon-btn-imprimir {
  content: url("./images/icon-print.svg");
  width: 15px;
  float: left;
  margin-right: 5px;
  margin-left: 0px;
  margin-top: 4px; }

button.btn-trash {
  background: url("./images/icones/delete.svg") no-repeat;
  height: 20px;
  width: 20px;
  display: flex;
  min-width: inherit;
  min-height: inherit;
  margin: auto; }

.item-edit hr {
  margin: 7px; }

.wh-40 {
  width: 40px;
  height: 40px; }

.cardCrud.card {
  background-color: #f0f5f9; }

.btn-branco {
  background-color: #fff; }

.btn-rounded {
  border-radius: 20px; }

.bg-branco {
  background-color: #fff;
  border-radius: 20px; }

.bkg-branco {
  background-color: #fff; }

.bkg-cinza {
  background-color: #ececec; }

.modalLoading .modal-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: none; }

.form-control.is-invalid,
textarea.form-control.is-invalid {
  border: 2px solid #dc3545 !important;
  box-shadow: none !important; }

.border-left .custom-card {
  border-left: 1px solid #eff3f4; }

.list-horarios {
  background: linear-gradient(#fefeff 0%, #f0f5f9 100%);
  padding: 24px 10px;
  border-bottom: 1px solid #e0e5ea; }

.fade-in {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s; }

.badge {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  position: absolute;
  right: 12px;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 4px 2px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  top: 18px; }

.badge-danger {
  background-color: #ea3a45;
  border: 1px solid #d1333d; }

.container-meet {
  height: 90vh; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-out {
  opacity: 1;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.move-in {
  animation-name: moveIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards; }

@keyframes moveIn {
  0% {
    transform: translate(0, -150%); }
  100% {
    transform: translate(0, 0); } }

.move-out {
  animation-name: moveOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards; }

@keyframes moveOut {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, -150%); } }

.move-up {
  animation-name: moveUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards; }

@keyframes moveUp {
  0% {
    transform: translate(0, 20px); }
  100% {
    transform: translate(0, 0); } }

.move-down {
  animation-name: moveDown;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; }

@keyframes moveDown {
  0% {
    transform: translate(0, -20px); }
  100% {
    transform: translate(0, 0); } }

@media (max-width: 1366px) {
  .listagem-custom .conteudo-listagem {
    padding: 0px; } }

@media (max-width: 1280px) {
  .listagem-custom .conteudo-listagem {
    padding: 0px; } }

@media (max-width: 1024px) {
  .icon-filtro {
    bottom: 30px;
    left: 30px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: -2px -2px 4px #ffffff88, 2px 2px 4px #85898e21; }
  body {
    margin-bottom: 100px; }
  .back-elements {
    background-size: 180%;
    background-position-x: 0;
    background-position-y: 0; }
  .hora-detalhe-row {
    width: 160px; }
  .logo-sasc-cm {
    width: 100%; }
  .btn-secondary.save {
    color: #428ee6; }
  .btn-secondary.delete {
    color: #dc3545; }
  .btn-secondary.open {
    color: #28a745; }
  .legends {
    width: initial; }
  .icon-filtro {
    bottom: 30px;
    left: 20px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: -2px -2px 4px #ffffff88, 2px 2px 4px #85898e21; }
  .icon-indisponibilidade {
    bottom: 30px;
    left: 85px;
    top: inherit;
    width: 50px;
    height: 50px;
    box-shadow: -2px -2px 4px #ffffff88, 2px 2px 4px #85898e21; }
  .cardInfo {
    margin: 0; }
  .cardInfo p.title {
    text-align: center;
    margin-bottom: 20px; }
  .cardInfo p.instruction {
    margin-bottom: 20px; } }

@media (max-width: 414px) {
  .toogleButtons .btn-toogle {
    padding: 10px 8px;
    width: initial;
    margin: 0px 3px; } }

label {
  margin-bottom: 0;
  font-size: 10px;
  color: #000;
  margin-left: 10px;
  font-weight: bold; }

form label {
  font-weight: 300; }

input {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  height: 40px;
  border-radius: 10px;
  padding: 6px 20px; }

input:focus {
  color: #000 !important;
  box-shadow: none !important; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.form-row input,
.form-row .Select {
  margin-bottom: 10px; }

.form-control {
  background-color: transparent;
  color: #000;
  font-size: 14px;
  height: 50px;
  border-radius: 10px;
  padding: 6px 20px; }

.form-control::placeholder {
  color: #a9afb5;
  font-weight: 200; }

.form-group {
  margin-bottom: 0px; }

p.form-view {
  margin: -3px 0 0 5px;
  line-height: 45px; }

p.form-view.lines {
  font-size: 16px;
  margin: 0 0 5px 10px; }

.form-view .tag {
  border: 1px solid #d8dde3;
  padding: 0px 5px;
  margin: 0px 2px; }

.icone-label {
  width: 30px; }

.icone-label.off {
  filter: brightness(0); }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; }

form h2.w-100 {
  font-size: 10px;
  background-color: #f8f8f8;
  color: #85898e;
  padding: 10px 35px;
  padding-top: 10px; }

@media (max-width: 1023px) {
  form h2.w-100 {
    text-align: center; } }

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 20000 !important; }

.react-confirm-alert {
  width: 400px;
  padding: 30px;
  background: #f0f5f9;
  border-radius: 10px;
  color: #000; }

.react-confirm-alert img {
  width: 100%;
  height: 80px;
  margin: auto auto 10px; }

.react-confirm-alert h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 800; }

.react-confirm-alert .body-confirm {
  font-size: 14px;
  width: 100%;
  margin: auto;
  text-align: center; }

.react-confirm-alert .alert-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  width: 100%; }

.alert-absolute {
  position: absolute;
  border-radius: 20px;
  z-index: 1000;
  padding: 20px;
  width: 80vh;
  text-align: center; }

@media (max-width: 1023px) {
  .react-confirm-alert {
    width: 85%;
    padding: 20px; }
  .react-confirm-alert .body-confirm {
    width: 100%; } }

.background-menu {
  height: 100vh;
  box-shadow: 0px 4px 20px #0000001c;
  background-color: #eff3f4; }

.topo .iconError,
.topo.iconMenu .btnVoltar img,
.topo.iconNotification .btnVoltar img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer; }

.background-menu .icon-menu {
  height: 35px; }

.container-box {
  width: 880px;
  padding: 80px 20px 20px;
  position: relative; }

.container-box div.box {
  margin: 2px;
  background: #ffffff;
  border-radius: 3px;
  text-align: center;
  font-size: 2.7em;
  float: left;
  width: 120px;
  height: 120px;
  cursor: pointer;
  padding: 0px 5px;
  border: 1px solid #a5a6a9; }

.container-box div.box.sub-menu {
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 0px; }

.container-box div.box.sub-menu:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0px 12px 12px;
  border-color: transparent transparent #a5a6a9 transparent;
  right: 0;
  bottom: 0;
  position: absolute; }

.container-box div.box.selecionado {
  outline: 1px solid #00cb7c;
  border: 1px solid #00cb7c; }

.container-box div.box p {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal; }

.container-box > .box-perfil {
  width: 195px;
  height: 495px;
  float: left; }

.container-box div.menu-user {
  height: 245px;
  padding: 20px 10px 5px;
  width: 190px; }

.container-box div.menu-user .txt-secondary {
  color: #a9afb5;
  font-size: 12px; }

.container-box .box-perfil .menu-perfil {
  padding: 0px 15px 0px; }

.container-box .box-perfil.menu-user-perfil {
  height: 46px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  font-size: 4.5em;
  float: left;
  width: 140px;
  cursor: pointer;
  border-radius: 10px;
  font-family: "Roboto Slab", serif;
  font-weight: 400; }

.container-box .menu-user-perfil .multi {
  color: #fff; }

.container-box .menu-user-perfil p {
  color: #85898e; }

.container-box .menu-user-perfil .multi p {
  color: #fff; }

.container-box > div.menu-user.selecao-auditor {
  position: absolute;
  z-index: 100; }

.container-box > div.menu-felicoop {
  width: 290px; }

.container-box > div.menu-auditor {
  position: absolute;
  width: 460px;
  left: 170px;
  height: 290px;
  text-align: inherit;
  opacity: 1; }

.logoMenu {
  width: 200px; }

.container-box > div.menu-auditor.fadeOut {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 3s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s; }

@-webkit-keyframes slide {
  100% {
    left: -150px;
    transform: scaleX(0);
    opacity: 0; } }

@keyframes slide {
  100% {
    left: -150px;
    transform: scaleX(0);
    opacity: 0; } }

.container-box .box-auditor {
  max-width: 250px;
  margin: auto;
  padding-top: 2rem;
  font-size: initial; }

.botao-box {
  position: absolute;
  right: 30px; }

.container-box .box-auditor h2 {
  text-align: center; }

.container-box .box-auditor p {
  text-align: center; }

.menu-user h5 {
  font-size: 12px; }

.menu-user h6 {
  font-size: 12px;
  font-style: italic;
  color: #999999; }

.logo-menor {
  height: 50px; }

.max-hr {
  margin-top: 80px;
  width: 90%; }

.backMenu {
  height: 17px;
  margin-bottom: 2px; }

.menuOpened .container-com-topo {
  margin-top: 0px;
  transition: all 0.4s ease-in-out; }

.menuOpened .bodyMenu {
  overflow-y: auto;
  padding-top: 60px; }

.navbarMenu {
  background-color: #eff3f4;
  opacity: 1; }

footer p {
  color: #cccccc !important; }

/* MOBILE */
@media (max-width: 768px) {
  .container-box {
    width: 750px; } }

@media (max-width: 575px) {
  .background-menu {
    background-color: #eff3f4 !important;
    background: none;
    height: initial; }
  .container-box {
    width: 550px;
    padding: 20px;
    height: 100vh;
    overflow-y: scroll; }
  .container-box > div.box {
    width: calc(50% - 5px);
    margin: 5px 0px; }
  .max-hr {
    margin-top: 30px;
    width: 70%; }
  .container-box > div.box:nth-child(odd) {
    margin-left: 10px; }
  .container-box div.menu-user {
    height: 140px;
    width: 100%;
    padding: 20px 30px;
    margin: 0px; }
  .container-box div.menu-felicoop {
    width: 100%; }
  .backMenu {
    height: 22px; }
  .container-box > .box-perfil {
    width: 100%;
    height: 210px;
    top: 35px;
    position: relative; }
  .container-box .box-perfil.menu-user-perfil {
    height: 50px;
    width: 100%; } }

@media (max-width: 414px) {
  .avatarMenu {
    width: 95px;
    height: 95px; }
  .container-box {
    width: 100%;
    padding: 30px 5px 5px 5px; } }

@media (max-width: 375px) {
  .container-box {
    width: 100%;
    padding: 30px 10px 10px 10px; }
  .container-box div.menu-user {
    padding: 20px; }
  .avatarMenu {
    width: 90px;
    height: 90px; }
  .max-hr {
    margin-top: 60px;
    width: 70%; } }

@media (max-width: 320px) {
  .container-box div.menu-user {
    padding: 20px 13px; }
  .avatarMenu {
    width: 70px;
    height: 70px;
    font-size: 34px; } }

.topo-listagem-fixo {
  background-color: #eff3f4; }

.bloco-image-user {
  min-width: 100px;
  position: relative;
  cursor: pointer; }

hr.topo {
  margin: 0px auto;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 59px;
  z-index: 1; }

.info-user h2 {
  font-family: "Roboto Slab", serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px; }

.info-user h2,
.info-user label,
.info-user p {
  margin-bottom: 0;
  margin-left: 10px; }

.info-user p.txtPerfil {
  color: #999;
  margin-top: 2px; }

span.icon-btn-filtro {
  content: url("./images/icones/filter.svg"); }

span.icon-btn-indisponibilidade {
  content: url("./images/icon-close-agenda-x1.svg");
  width: 20px !important;
  margin-bottom: -5px !important;
  margin-right: 3px !important;
  filter: brightness(0); }

.topo-listagem-fixo .btn {
  height: 45px; }

.topo-listagem-fixo .Select .react-select__single-value {
  margin: 0 0 0 15px; }

.topo-listagem-fixo .btn-light span,
.topo-listagem-fixo .btn-link span,
.topo-listagem-fixo .btn-primary span,
.topo-listagem-fixo .btn-secondary span {
  margin-right: 6px;
  margin-bottom: -3px;
  width: 16px; }

.action-buttons-fixed {
  float: right;
  position: fixed;
  top: 55px;
  right: 23px;
  z-index: 13; }

.action-buttons-fixed .Select .react-select__control {
  min-height: 45px;
  width: 200px; }

@media (max-width: 414px) {
  .action-buttons-fixed {
    position: inherit; }
  .local-atendimento-fixed {
    margin-bottom: 50px; }
  .doctor .top {
    top: 166px;
    height: 60px; }
  .lista-horarios .calendar-date {
    width: initial; }
  .doctor .top .background {
    height: 190px; }
  .doctor .schedules {
    padding-top: 110px; }
  .doctor .top .photo-name {
    margin-top: 15px; } }

.uppercase {
  text-transform: uppercase; }

.blocoPontas {
  padding: 0px; }

.bloco-pesquisa {
  position: relative;
  z-index: 2; }

.temFiltro .icone-pesquisa {
  background-color: #00cb7d; }

.corpo-pesquisa {
  margin-top: -15px;
  position: relative;
  z-index: 1; }

.btn-menor span {
  margin-right: 5px; }

.corpo-pesquisa .collapse {
  margin-top: -15px;
  animation: fade 0.1s forwards; }

.corpo-pesquisa .barra-pesquisa .form-row {
  margin-left: -60px;
  margin-right: -10px; }

.corpo-pesquisa .barra-pesquisa .input-group {
  flex-wrap: nowrap;
  justify-content: space-between; }

.corpo-pesquisa .barra-pesquisa .input-group .form-control {
  width: 270px; }

.corpo-pesquisa .form-group {
  margin-bottom: 0px; }

@keyframes fade {
  from {
    background-color: transparent; }
  to {
    background-color: #f0f5f9; } }

@keyframes alpha {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.barra-pesquisa {
  animation: alpha 1s forwards; }

.corpo-pesquisa .collapsing {
  background-color: transparent;
  transition: height 0.35s ease; }

.collapse ~ .barra-dotted,
.collapsing ~ .barra-dotted {
  display: none; }

.collapse > .barra-pesquisa,
.collapsing > .barra-pesquisa {
  padding: 28px 10px 24px 44px; }

.collapse.show ~ .barra-dotted {
  display: block;
  position: relative;
  height: 1px;
  width: 93%;
  margin: auto; }

.barra-dotted .barra {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, transparent 50%, #a9afb5 50%);
  background-size: 15px 100%;
  z-index: -1; }

td .avatarCustom {
  z-index: 0; }

.searchFilter {
  position: relative;
  min-width: 250px;
  margin-right: 5px; }

.searchFilter .react-select__control {
  padding-left: 30px; }

.searchFilterEnd {
  min-width: 250px;
  margin-left: 5px;
  margin-right: 50px; }

.searchFilterEnd .react-select__control {
  padding-right: 35px; }

/* FIM PESQUISA */
.action-line {
  font-family: "Roboto Slab", serif;
  min-height: 70px; }

.action-line .labelInfoGrid {
  font-size: 12px;
  margin-left: 20px; }

.action-line .btDefaultGrid {
  margin: 20px 10px;
  z-index: 100; }

.action-line .labelInfoAction {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.action-topo-tabela {
  background-color: #f0f5f9; }

.action-line.flutuante {
  position: fixed;
  top: calc(70px - 1.5rem);
  z-index: 1000;
  left: 15px;
  width: calc(100% - 30px);
  background-color: transparent; }

.flutuante.collapse,
.flutuante.collapsing {
  position: fixed;
  top: 129px;
  z-index: 1000;
  width: calc(100% - 30px);
  margin: 0px;
  box-shadow: 1px 10px 10px 0px rgba(0, 0, 0, 0.15); }

.boxAction {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 2px;
  padding: 9px;
  margin: 12px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }

.table {
  color: #000;
  background-color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  margin-bottom: 5px; }

.form-check-input {
  position: initial;
  margin-top: 0;
  margin-left: 0; }

.table-bordered thead th,
.table-bordered thead td {
  text-align: center;
  border-bottom-width: 1px;
  padding: 10px; }

.table-bordered th,
.table-bordered td {
  border: 1px solid #d8dde3; }

.table-bordered thead tr:hover {
  cursor: pointer; }

.table-bordered tbody tr:hover {
  background-color: #f0f5f9;
  cursor: pointer; }

.table-bordered tbody tr.checked {
  background-color: #f0f5f9; }

.table-bordered tbody tr:last-child {
  border-bottom: 2px solid #d8dde3; }

.table thead th {
  font-weight: 800;
  border-bottom: 0px; }

.table th,
.table td {
  vertical-align: middle;
  padding: 1rem; }

th label {
  margin: 0 0 2px 0; }

thead th.classChecked {
  background-color: #00cb7d;
  border-bottom-color: #0cbc78; }

td label {
  margin: 0 0 2px 0; }

td.classChecked {
  background-color: #00cb7d;
  border-bottom-color: #0cbc78; }

.table .classChecked {
  background-color: #00cb7d; }

.label-resultados {
  font-size: 10px;
  color: #85898e;
  font-style: italic; }

td .avatarCustom {
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin: -10px auto; }

.table th.headerCenter {
  padding: 0 14px;
  vertical-align: middle;
  text-align: center;
  position: relative; }

.table th.headerLeft {
  padding: 10px 14px;
  vertical-align: middle;
  text-align: left;
  position: relative; }

.ascendente {
  height: 8px;
  transform: rotate(-90deg);
  position: absolute;
  right: 8px;
  bottom: 6px; }

.descendente {
  height: 8px;
  transform: rotate(90deg);
  position: absolute;
  right: 8px;
  bottom: 6px; }

.table td.colunaCheck {
  width: 45px;
  padding: 10px 0;
  text-align: center; }

.table td.colunaFoto {
  width: 45px;
  padding: 10px 0;
  text-align: left; }

.table td.colunaMin {
  width: 90px;
  padding: 0 14px;
  text-align: center; }

.table td.colunaPeq {
  width: 135px;
  padding: 0 14px;
  text-align: center; }

.table td.colunaMed {
  width: 200px;
  padding: 0 14px;
  text-align: center; }

.table td.colunaGra {
  padding: 0 14px;
  text-align: left; }

th label {
  margin: 0 14px 14px 0; }

td label {
  margin: 0 14px 14px 0; }

.container-com-topo .action-line .boxAction label {
  margin: 0px 30px 14px 0px; }

.container-com-topo .action-line .boxAction .labelInfoAction {
  margin-left: 4px; }

.boxAction a:not([href]) {
  color: #00cb7d;
  cursor: pointer; }

.boxAction a:not([href]):hover {
  color: #00e392;
  cursor: pointer; }

.adicionarPesquisa {
  position: absolute;
  right: 10px;
  top: 38px; }

@media (max-width: 1024px) {
  .collapse > .barra-pesquisa,
  .collapsing > .barra-pesquisa {
    padding: 28px 10px 24px 20px; }
  .searchFilter {
    min-width: 230px; }
  .searchFilterEnd {
    min-width: 230px;
    margin-right: 10px; }
  .adicionarPesquisa {
    top: 33px; } }

@media (max-width: 767px) {
  .corpo-pesquisa .barra-pesquisa .form-row {
    margin-left: -25px; }
  .corpo-pesquisa .barra-pesquisa .form-row {
    margin-left: -8px;
    margin-right: 13px; }
  .corpo-pesquisa .barra-pesquisa .defaultSelect {
    margin-left: -5px;
    margin-right: -5px; }
  .corpo-pesquisa .barra-pesquisa .defaultSelect .input-group-append .btn {
    z-index: 0; }
  .react-code4-table table,
  .react-code4-table thead,
  .react-code4-table tbody,
  .react-code4-table th,
  .react-code4-table td,
  .react-code4-table tr {
    display: block; }
  .react-code4-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .react-code4-table td {
    border: none;
    border-bottom: 1px solid #d8dde3;
    position: relative;
    padding-left: 36% !important;
    white-space: normal;
    text-align: center; }
  .react-code4-table td:after {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    text-transform: none;
    color: #000;
    background-color: #eff3f4;
    border-right: 1px solid #d8dde3;
    padding: 0 10px; }
  .react-code4-table td:last-child {
    padding-bottom: 5px; }
  .react-code4-table td:before {
    top: 5px; }
  .react-code4-table td .colunaDestaque:after {
    top: 15px; }
  .react-code4-table td:after {
    content: attr(data-title);
    padding-top: 12px;
    height: 100%;
    text-align: center;
    margin: -10px; }
  .react-code4-table .table tbody tr {
    border-top: 1px solid #d8dde3; }
  .react-code4-table .table tbody tr td {
    border: none;
    padding: 3px; }
  .temSinal .react-code4-table .table tbody tr td:last-child {
    display: inline-flex;
    padding-left: 0px !important;
    vertical-align: sub; }
  .temSinal .react-code4-table .table tbody tr td:nth-last-child(2) {
    display: inline-flex; }
  .react-code4-table .table tbody tr:first-child {
    border-top: 0px solid #d8dde3; }
  .react-bs-container-header .table thead {
    display: none; }
  .table td.colunaCheck {
    width: 100%;
    text-align: center;
    padding: 10px 20px; }
  .table td.colunaFoto {
    width: 100%;
    text-align: center;
    padding: 10px 20px; }
  .table td.colunaFoto .avatarCustom {
    width: 50px;
    height: 50px;
    margin: 0 auto; }
  .table td.colunaMin,
  .table td.colunaPeq,
  .table td.colunaMed,
  .table td.colunaGra {
    width: 100%;
    text-align: center;
    padding: 10px 20px; }
  .action-line .labelInfoAction.half {
    width: 50%; }
  tbody tr {
    border-bottom: 2px solid #000; }
  .container-com-topo .action-line .boxAction label {
    margin: 0px 30px 6px 0px; }
  .action-line .labelInfoGrid {
    margin-left: 0; }
  .action-line .boxAction .labelInfoAction {
    margin: 10px 0;
    white-space: pre-wrap;
    max-width: 90%;
    text-align: center; }
  .action-line .labelInfoAction label {
    font-size: 12px; }
  .boxAction a {
    white-space: nowrap; }
  .collapse > .barra-pesquisa,
  .collapsing > .barra-pesquisa {
    padding: 28px 20px 40px 25px; }
  .searchFilter {
    max-width: 250px;
    margin: 5px auto; }
  .searchFilterEnd {
    width: 240px;
    margin: 0 auto 20px; }
  .adicionarPesquisa {
    position: inherit;
    text-align: center; } }

.react-select {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative; }

.react-select-trigger {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 31px;
  padding: 6px 27px 6px 6px;
  font-size: 14px;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #d3d4d4;
  outline: 0;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 21 21"><polygon points="10.5,12 7,8.5 14,8.5"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center; }
  .react-select-trigger, .react-select-trigger:active {
    color: black; }
  .react-select-trigger.react-select-target-attached-top {
    border-radius: 0 0 3px 3px; }
  .react-select-trigger.react-select-target-attached-bottom {
    border-radius: 3px 3px 0 0; }
  .react-select-trigger * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.react-select-trigger__option {
  user-select: none; }
  .react-select-trigger__option + .react-select-trigger__option {
    margin-left: 6px; }
  .react-select-trigger--multiple .react-select-trigger__option {
    padding: 0 3px;
    border-radius: 2px;
    background: #e6f9ff; }

.react-select-trigger__arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%); }

.react-select-menu {
  max-height: 180px;
  padding: 3px 0;
  border: 1px solid #e1e1e1;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px 0 rgba(218, 221, 222, 0.35);
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent; }
  .react-select-enabled .react-select-menu {
    pointer-events: auto; }
  .react-select-element-attached-top .react-select-menu {
    margin-top: -1px;
    border-top: 0; }
  .react-select-element-attached-bottom .react-select-menu {
    margin-top: 1px;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 -2px 4px 0 rgba(218, 221, 222, 0.35); }

.react-select-header {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #f1f3f5;
  border-top: 1px solid #f1f3f5; }

.react-select-btn {
  flex: 1; }

.react-select-options {
  padding: 0;
  margin: 0;
  list-style: none; }

.react-select-option {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  text-indent: 4px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none; }
  .react-select-option:hover {
    background: #fbfbfb; }

.react-select-option__label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.react-select-optgroup {
  display: block;
  padding: 3px 0;
  cursor: default;
  border-top: 1px solid #f1f3f5; }
  .react-select-optgroup:first-child {
    border: 0; }

.react-select-optgroup__title {
  display: block;
  padding: 8px 12px 6px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #909294;
  user-select: none; }
