.menu-indisponibilidade label {
  color: $preto;
  font-size: $font-xs;
  font-style: normal;
  margin-left: 2px;
}

.menu-indisponibilidade .titulo-indisponibilidade img {
  height: 28px;
  margin-bottom: 0px;
  filter: brightness(0);
}

.menu-indisponibilidade .select-filter {
  padding: 25px 10px 30px;
  border-bottom: 1px solid $cinza2;
}

.menu-indisponibilidade hr {
  margin: 0px 0;
}
.menu-indisponibilidade .disabled,
.react-select--is-disabled {
  background-color: white;
}

.menu-indisponibilidade .disabled,
.react-select--is-disabled .react-select__placeholder {
  font-weight: bold;
  font-style: normal;
}
.menu-indisponibilidade .disabled,
.react-select--is-disabled {
  opacity: 1;
}
