.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelProgBar {
  position: absolute;
  top: 120%;
  width: 130px;
  text-align: center;
}

.labelProgBar p {
  font-size: 16px;
  text-transform: uppercase;
}
.labelProgBar span {
  font-size: 14px;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

.RSPBprogressBar {
  margin: 10px 40px 70px 40px;
  border-bottom: 3px #c1c5c0 dotted;
  background-color: transparent;
  height: initial;
  border-radius: 0px;
}

.RSPBprogressBar .RSPBstep {
  top: -9px;
}
.indexedStep {
  background: #fefefe;
  border: 1px solid #555353;
}
.indexedStep.accomplished {
  background-color: #04af72;
  border: 1px solid #04af72;
}
