.horarioFuncionamento .form-inside {
  margin-right: 0px;
  margin-left: 0px;
}

@media (max-width: 1023px) {
  .weekDays {
    text-align: center;
  }
}
