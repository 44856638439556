// INPUTS / SELECTS
label {
  margin-bottom: 0;
  font-size: $font-label;
  color: $preto;
  margin-left: 10px;
  font-weight: bold;
}
form label {
  font-weight: 300;
}
input {
  background-color: $branco;
  color: $preto;
  font-size: $font-default;
  height: 40px;
  border-radius: $border-radius;
  padding: 6px 20px;
  // border: 1px solid $cinza1 !important;
  // margin-bottom: 10px;
}
input:focus {
  color: $preto !important;
  box-shadow: none !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.form-row input,
.form-row .Select {
  margin-bottom: 10px;
}

.form-control {
  background-color: transparent;
  color: $preto;
  font-size: $font-default;
  height: 50px;
  border-radius: $border-radius;
  padding: 6px 20px;
  // border: 1px solid $cinza1 !important;
}
.form-control::placeholder {
  color: $cinza4;
  font-weight: 200;
}

.form-group {
  margin-bottom: 0px;
}
p.form-view {
  margin: -3px 0 0 5px;
  line-height: 45px;
}
p.form-view.lines {
  font-size: $font-sm;
  margin: 0 0 5px 10px;
}

.form-view .tag {
  border: 1px solid $cinza3;
  padding: 0px 5px;
  margin: 0px 2px;
}

.icone-label {
  width: 30px;
}
.icone-label.off {
  filter: brightness(0);
}

// Autocomplete
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

/////////////////////////////////

// CUSTOM SELECT

// .textArea {
//   height: 150px;
// }

/////////////////////////////`

// BOX

form h2.w-100 {
  font-size: $font-label;

  background-color: #f8f8f8;
  color: $cinza5;
  padding: 10px 35px;
  padding-top: 10px;
}

//  Media Mobile
@media (max-width: 1023px) {
  form h2.w-100 {
    text-align: center;
  }
}
