.historico-bg .cheeseburger-menu-inner {
  background-color: #e3e7e8;
}
.historico label {
  color: $preto;
  font-size: $font-xs;
  font-style: normal;
  margin-left: 2px;
}
.historico h2 {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
}

.historico h3 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  font-style: italic;
}
.historico p {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0px;
  padding: 0 10px;
}
.historico p b {
  font-size: 12px;
  color: #2a444e;
}
.historico .txt-sem-historico {
  font-size: 13px;
  font-weight: bold;
  color: #284149;
}

.historico.titulo-indisponibilidade img {
  height: 28px;
  margin-bottom: 0px;
  filter: brightness(0);
}

.historico.select-filter {
  padding: 25px 10px 30px;
  border-bottom: 1px solid $cinza2;
}
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #01cb7d;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container-historico {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container-historico::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 7px solid #01cb7d;
  top: 20px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 15px 5px 6px 5px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}
.content p:last-child {
  margin-bottom: 0px;
}

.content hr {
  border-top: 3px solid #e3e7e8;
  margin: 5px 0;
  width: 100%;
}
.content .itemHistorico {
  border-bottom: 1px solid #e3e7e8;
  padding-bottom: 5px;
  margin-top: 5px;
}

.content .itemHistorico:last-child {
  border-bottom: 0px solid #e3e7e8;
  padding-bottom: 5px;
  margin-top: 5px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */

.timeline::after {
  left: 31px;
}

/* Full-width containers */
.container-historico {
  width: 100%;
  padding-left: 60px;
  padding-right: 0px;
}

/* Make sure that all arrows are pointing leftwards */
.container-historico::before {
  left: 50px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Make sure all circles are at the same spot */
.left::after,
.right::after {
  left: 19px;
}

/* Make all right containers behave like the left ones */
.right {
  left: 0%;
}
