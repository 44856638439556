@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,600,600i,700,700i&display=swap');

$danger-color: #ea3a45;
$warning-color: #d4dd66;
$success-color: #2cda97;

$theme-colors: (
  'danger': $danger-color,
  'warning': $warning-color,
  'success': $success-color,
);

$font-family-default: 'Muli', sans-serif;
$font-family-default-2: 'Roboto Slab', serif;

$default: #eff3f4;
$primary: #02cb7d;
$primary-hover: #1ec182;
$cinza-hover: #d8dde3;
$secondary: #428ee6;
$preto-sombra: #00000024;

$bt-azul: #2a444e;

$bt-edit: #095c7d;

$branco: #fff;
$cinza0: #ececec;
$cinza1: #f0f5f9;
$cinza2: #eff3f4;
$cinza3: #d8dde3;
$cinza4: #a9afb5;
$cinza5: #85898e;
$preto: #000;

$vermelho: #ea3a45;
$vermelho1: #d1333d;
$vermelho2: #c7323c;
$amarelo: #d4dd66;
$azul: #3898d0;

$cor1: #2b454e; //Auditor e Auditoria
$cor2: #3c6669; //Protocolo e Pendencia
$cor3: #1f8e86; //Consultorio e Faturado
$cor4: #00cb7d; //Cooperado e Glosado
$cor5: #58e26d; //Administrador e Repassado
$cor6: #0cbc78;
$cor7: #3fd89d;

$body-bg: $default;
$body-color: $preto;

$gradient-default: linear-gradient($default 0%, $branco 100%);
$gradient-default-invert: linear-gradient($branco 0%, $default 100%);
$gradient-primary: linear-gradient($primary 0%, $primary-hover 100%);
$gradient-cinza: linear-gradient($cinza2 0%, $cinza-hover 100%);

$shadow-none: 0px 0px 0px #ffffff00, 0px 0px 0px #ffffff00;
$shadow1: -10px -10px 20px #ffffff88, 10px 10px 20px #85898e21;
$shadow2: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
$shadow3: -2px -2px 4px #ffffff88, 2px 2px 4px #85898e21;
$shadow4: 5px 5px 10px #85898e21;
$shadow-modal: 0 0 40px #85898e66;

$font-xxl: 30px;
$font-xl: 24px;
$font-lg: 22px;
$font-md: 18px;
$font-sm: 16px;
$font-default: 14px;
$font-xs: 12px;
$font-label: 10px;
$font-btn: 14px;

$border-radius-btn: 10px;
$border-radius-min: 20px;
$border-radius: 10px;

$border-color: $cinza3;

$bt-size-fixed: 120px;

@function theme-color-level($color-name: 'success', $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, #000, #fff);
  $level: abs($level);
  @return mix($color-base, $color, $level * $theme-color-interval);
}
