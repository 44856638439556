.atendimento-row {
  border-bottom: 1px solid $cinza3;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;

  .atendimento-data {
  }
  .icon-status-cancelado {
    height: 24px;
    width: 24px;
  }
  .atendimento-status {
    //position: relative;
    padding: 5px;
    margin: 5px;
    width: 150px;
    height: 25px;
    left: 145px;
    top: 130px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .atendimento-status-cancelado {
    //position: relative;

    color: #ff0000;
  }

  .atendimento-dados {
    min-width: 0;
    padding: 0 20px;
  }
  .atendimento-dados {
    .obs {
      font-size: 12px;
      line-height: 16px;
      margin-top: 10px;
    }
  }
  .atendimento-acao {
    margin: 0 20px;
    button {
      box-shadow: none;
    }
  }
}
.atendimento-row:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 10px;
}
@media (max-width: 1023px) {
  .atendimento-row {
    padding: 5px 0px 20px;

    .atendimento-data {
      width: 100%;
      margin-bottom: 30px;
    }
    .atendimento-dados {
      width: 100%;
      padding: 0 10px 10px;
    }
  }
}
