.cardCrud span.icon-btn-excluir {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px;
}
.cardCrud {
  // margin-top: 35px;
}
.cardCrud:first-child {
  margin-top: 0px;
}
.cardCrud .section-form div.form-group input.is-invalid {
  border: none;
  border-bottom: 2px solid $vermelho !important;
}
.cardCrud .section-form.section-form-confirm {
  padding: 5px 0px;
}
.cardCrud.card {
  //border-top: 2px solid $cor2;
  //border-radius: 0;
  border-left: none;
  border-right: none;
}
.cardCrud .card-header {
  padding: 1.75rem 1.25rem 1.75rem 0.75rem;
  margin-bottom: 0;
  background-color: $branco;
  border-bottom: none;
  height: 70px;
  color: $preto;
}

.cardCrud .card-body {
  padding: 0 0.25rem 0 0.25rem;
}

.cardCrud .subheader.card-header {
  padding: 0.45rem 1.25rem 0.75rem;
  margin-bottom: 0;
  background-color: $cinza1;
  border-bottom: none;
  color: $cinza5;
  height: 30px;
  font-size: 10px;
}

.cardCrud .section-form .input-required input {
  border-color: $preto;
  border-width: 2px;
  color: $preto;
}
// .cardCrud .section-form {
//   padding: 0px 14px;
// }
.cardCrud .input-required label {
  font-weight: bold;
  margin-bottom: -5px;
}

.input-required .is-invalid .react-select__control {
  border: 2px solid $vermelho !important;
}
.cardCrud .Select.is-invalid .react-select__control {
  border-bottom: 2px solid $vermelho !important;
}

.cardCrud .Select.is-invalid .react-select__control {
  border-bottom: 2px solid $vermelho;
}
.cardCrud .Select.is-invalid .react-select__control .css-1uccc91-singleValue {
  color: $vermelho;
}

.cardCrud .input-required label {
  font-weight: bold;
}

.cardCrud .section-form > div.form-group,
.cardCrud .section-form > div.form-row > div.form-group {
  padding: 7px 5px;
}
.cardCrud {
  background-color: #fff;
  .topo-card {
    background-color: #fff;
    height: 80px;
    border-radius: 0px;
    border-top: 2px solid $bt-azul;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid #d8dde357;
    margin-bottom: 20px;
    h1 {
      font-size: $font-md;
      margin-left: 15px;
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
    }
    button {
      box-shadow: none;
      // margin: 0;
      // padding: 0;
    }
  }

  .viewMode .topo-card {
    border: 0px dashed $cinza4;
    border-bottom: none;
  }
  .viewMode.fixed {
    position: initial;
  }
  .form-control {
    height: 35px;
  }
  .Select {
    min-height: 35px;
    .react-select__control {
      min-height: 35px;
    }
    .react-select__control:hover {
      border: 0px solid $cinza2;
      border-bottom: 2px solid $cinza4;
    }
  }

  .conteudo-card {
    padding: 0px;
    border-radius: 0px;
    .item-view {
      background: linear-gradient($branco 50%, $cinza1 100%);
      padding: 24px 10px;
      border-radius: $border-radius-min $border-radius-min 0 0;
    }
    .btn.shadow {
      box-shadow: $shadow2;
    }
    h1 {
      font-size: $font-md;
      margin-left: 15px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  .viewMode .conteudo-card {
    background-color: $cinza1;
    border: 0px dashed $cinza4;
    border-top: none;
    // box-shadow: $shadow1;
  }
  .noViewMode .conteudo-card {
    padding: 30px 30px 40px;
    background-color: $cinza1;
  }
  .noViewMode.fixed {
    position: fixed;
  }
}

.mt-100 {
  margin-top: 100px;
  .topo-card {
    border-top: 5px solid $bt-azul;
  }
}

// FORMULARIOS
.form-inside,
.search-box.form-inside {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px 0px 20px 0px;
  .visualizacao .section-form > div.form-group {
    border-right: 1px solid $cinza2;
  }
  .visualizacao .section-form > div.b-bottom {
    border-bottom: 1px solid $cinza2;
  }
  .visualizacao .section-form > div.form-group > p {
    line-height: 20px;
    margin-bottom: 3px;
  }
  .section-form.item-view {
    div.form-group {
      background-color: initial;
      padding: 5px;
    }
  }
  .section-form {
    ///////////////////////FORMULÁRIOS E GUIAS//////////////////

    color: $cinza5;
    font-size: 10px;
    font-style: italic;
    padding: 0px 15px;

    .weekDays {
      padding: 0;
    }
    .weekDays .buttonWeek {
      width: 62px;
      font-size: $font-default;
      font-style: normal;
      text-align: center;
      padding: 10px 0;
      border-radius: $border-radius-btn;
      margin: 5px 0 5px 9px;
      transform: scale(1);
      cursor: pointer;
      transition: all 250ms;
    }

    .weekDays .buttonWeek-off {
      color: $preto;
      background-color: $branco;
      border-color: $branco;
      font-weight: 600;
    }
    .weekDays .buttonWeek-off:hover {
      outline: none !important;
      box-shadow: none !important;
      transform: scale(0.9);
    }

    .weekDays .buttonWeek-on {
      color: $branco !important;
      background-color: $primary !important;
      border-color: $primary;
      font-weight: 800;
    }

    .convenios .control--checkbox span {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: $preto;
      font-style: normal;
    }
    > h6 {
      padding-left: 15px;
      font-size: 10px;
      line-height: 30px;
      margin-bottom: 0px;
      height: 30px !important;
      background-color: $cinza1;
    }
    > .convenios label.desabilitado {
      font-size: 12px;
      font-style: normal;
      padding: 0px 10px;
    }

    div.form-group.text-center .form-control {
      text-align: center;
    }
    .horario-agenda {
      padding: 0px 5px;
      font-size: 14px;
    }
    div.form-group,
    div.form-row div.form-group {
      padding-right: 5px;
      padding-left: 5px;
      background-color: transparent;
      padding-bottom: 3px;
      margin-bottom: 0px;
    }

    > div .form-control:focus {
      outline-offset: 0px !important;
      outline: none !important;
      border: none;
      background-color: transparent;
    }

    div > label {
      margin: 0px;
      // padding: 0px 10px;
    }
    div.form-group .form-control {
      width: 100%;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid #ccc;
      height: 35px;
      padding: 0 5px;
    }
    div.form-group .form-control:disabled,
    div.form-group .form-control[readonly] {
      color: rgb(153, 153, 153);
      opacity: 1;
      border: none;
      background-color: $branco;
    }

    div.form-group .form-control.is-invalid {
      border-bottom: 2px solid $vermelho;
    }
    // BOX
    div.form-group {
      background-color: transparent;
      padding: 20px 10px;
      width: 0;
    }
    div.form-group.central {
      max-width: 140px;
      min-width: 70px;
      padding: 20px 5px;
      text-align: center;
    }
    div.form-group.central .Select .react-select__value-container {
      display: flex !important;
      justify-content: center !important;
      // margin-left: 30px;
    }

    div.form-group.central.fixo {
      width: 70px;
    }
    .quebra {
      flex-basis: 100%;
      height: 0;
    }
    div.form-group.fixo {
      width: 210px;
      flex: 0 0 auto !important;
    }
    div.form-group.large {
      width: 30%;
    }
    div.form-group.full {
      width: 80%;
    }
    .form-group:first-child {
      // border-left: 1px solid $cinza2;
      // border-top: 1px solid $cinza2;
    }
    .form-group {
      // border-right: 1px solid $cinza2;
      // border-bottom: 1px solid $cinza2;
      // border-top: 1px solid $cinza2;
    }
    > .w-100 + div {
      border-left: 1px solid $cinza2;
    }

    > .quebra + div {
      border-left: 1px solid $cinza2;
    }
    .form-group.campoPendente {
      outline: 2px solid #3c6669;
      outline-offset: -2px;
      z-index: 2;
      // background-image: url('./img/icon_pendente_SMALL.svg');
      background-size: 10px;
      background-position: 97% center;
      background-repeat: no-repeat;
    }
    .icone-pendente {
      display: none;
    }
    .form-group.campoPendente .icone-pendente {
      display: inline-flex;
    }

    div.form-group.w-20 {
      width: 20%;
    }
    // LABELS
    .label-nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0;
    }
    .input-required label {
      font-weight: bold;
    }
    label {
      color: $preto;
      font-size: 12px;
      padding-left: 0px;
      display: inline-block;
      padding: 0px 5px;
    }

    .form-group.central label {
      padding-left: 0px;
      padding-right: 0px;
    }
    // INPUT COMUM

    > div.form-group .input-group .form-control {
      width: 90%;
    }
    > div.form-group .input-group .input-group-text {
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
    > div.form-group textarea.form-control {
      min-height: 54px;
    }
    > div.form-group.central .form-control {
      text-align: center;
    }
    // REQUIRED
    div.form-group .input-required input {
      border-color: #000000;
      border-width: 2px;
      color: #000000;
    }
    // DISABLED
    div.form-group .form-control:disabled,
    div.form-group .form-control[readonly] {
      color: $cinza5;
      font-weight: bold;
      background-color: $branco;
      border-bottom: 2px solid $cinza4;
      // SELECT
    }
  }

  .Select.form-control .Select-control {
    border: none;
    background-color: transparent;
    text-align: left;
  }
  .Select.form-control .Select-control:hover {
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0);
  }

  .Select .Select-control .Select-value-label {
    font-size: 12px;
  }
  .text-center .Select .react-select__value-container {
    display: flex !important;
    justify-content: center !important;
    margin-left: 30px;
  }

  .Select.form-control.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }
  .Select.has-value.Select--single
    > .Select-control
    .Select-value
    .Select-value-label {
    color: $preto;
  }
  .Select .Select-menu-outer,
  .react-select__menu {
    font-family: 'Muli', sans-serif;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $branco;
    border: none !important;
    box-shadow: 0 0 20px #00000030 !important;
    margin-top: 0px;
    z-index: 300000 !important;
  }

  .Select .Select-placeholder,
  .Select.Select--single > .Select-control .Select-value,
  .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: $cinza4 !important;
    border-radius: 10px;
  }

  .Select {
    padding: 0px;
    background-color: transparent;
    min-height: 40px;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $preto;
    border-radius: 10px;
    border: initial;
  }
  .Select .react-select__control {
    min-height: 30px;
    font-family: 'Muli', sans-serif;
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid $cinza4;
    background-color: transparent;
    cursor: pointer;
  }
  .input-required .react-select__control {
    border-color: #000;
    border-width: 2px;
  }
  .input-required .is-invalid .react-select__control {
    border: 2px solid #ea3a45 !important;
  }
  .Select .Select-placeholder,
  .Select.Select--single > .Select-control .Select-value,
  .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: $cinza4 !important;
    border-radius: 10px;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0px !important;
    width: 13px;
    height: 20px;
    margin-right: 10px;
  }
  .icon-input {
    position: absolute;
    width: 15px;
    top: 12px;
    left: 20px;
  }
}

.search-box.form-inside {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}

.search-box.form-inside .section-form div.form-group {
  padding: 5px 10px;
  width: 0;
}
// CARD AGENDA / LOCAL ATENDIMENTO
.card-localAtendimento {
  margin: 10px;
  padding: 20px 0;
  background-color: $cinza1;
  border-radius: $border-radius;
  max-width: 618px;

  img {
    width: 70px;
  }
  h1 {
    font-size: $font-md;
    font-weight: 800;
  }
  p {
    font-size: $font-xs;
    margin: 0;
  }
}
//////////
/// // CARD UNIDADE FRACAO
.card-central {
  margin: 10px;
  padding: 20px 0;
  border-radius: $border-radius;
  max-width: 400px;
  background-color: transparent;

  img {
    height: 60px;
  }
  h1 {
    font-size: $font-md;
    font-weight: 800;
  }
  p {
    font-size: $font-xs;
    margin: 0;
  }
}
//////////
///
/// /// // CARD UNIDADE FRACAO
.card-central-sumario {
  margin: 10px;
  padding: 20px 0;
  border-radius: $border-radius;
  background-color: transparent;

  .bl-1 {
    border-left: 1px solid $cinza3;
  }

  img {
    height: 60px;
  }
  h1 {
    font-size: $font-md;
    font-weight: 800;
  }
  p {
    font-size: $font-xs;
    margin: 0;
  }
}
//////////

// CARD LINKS AGENDAMENTO / TV
.card-agendamento {
  margin: 35px 10px 0px 10px;
  background-color: $cinza1;
  border-radius: $border-radius;
  max-width: initial;

  .area-link {
    border-radius: $border-radius;
    padding: 20px 20px 6px;
    text-align: center;
    max-width: 50%;
    cursor: pointer;
    transition: all 500ms ease-out;
  }
  .area-link a {
    text-decoration: none;
  }
  .area-link .icon {
    height: 70px;
    width: 70px;
    border-radius: 40px;
    padding: 20px;
    margin: auto;
    background: transparent;
  }
  .area-link .icon img {
    height: 45px;
    mix-blend-mode: multiply;
    transition: all 500ms ease-in-out;
  }

  .area-link .title {
    font-size: $font-default;
    color: $preto;
    text-align: center;
    font-weight: 800;
  }
  .area-link p {
    margin-bottom: 0;
    text-align: center;
  }
  .area-link .btn-link {
    color: $primary;
    text-decoration: none !important;
    transform: translatey(6px) !important;
    opacity: 0;
  }
  .area-link .button-copy {
    transform: translatey(6px) !important;
    opacity: 0;
  }
  .area-link .button-copy img {
    filter: brightness(0);
    width: 20px;
    transition: all 250ms;
  }
}
.area-link:hover {
  background: $branco;
}
.area-link:hover .icon {
  background-color: $primary;
  transition: all 500ms ease-in-out;
}
.area-link:hover .icon img {
  mix-blend-mode: normal;
}
.area-link:hover .btn-link {
  transform: translatey(0) !important;
  opacity: 1;
}
.area-link:hover .button-copy {
  transform: translatey(0) !important;
  opacity: 1;
}
.area-link .button-copy:hover img {
  filter: brightness(1);
}
//////////////

//1280
@media (max-width: 1280px) {
  .noViewMode.fixed {
    position: initial;
  }
}
// 1024
@media (max-width: 1024px) {
  .custom-card {
    width: 60%;
    margin: auto auto 10px;
    padding: 0px;
  }
  .noViewMode.fixed {
    position: initial;
  }
  .card-localAtendimento {
    margin: auto auto 10px;
    max-width: 600px;
    text-align: center;
    padding: 20px 100px;

    p {
      margin-bottom: 20px;
    }
  }
  .card-agendamento {
    margin: auto auto 10px;
    max-width: 60%;
    .area-link {
      max-width: 100%;
    }
  }
}

// Mobile
@media (max-width: 1023px) {
  .custom-card {
    width: 100%;
  }
  .card-localAtendimento {
    max-width: 100%;
    padding: 20px;
  }
  .card-agendamento {
    max-width: 100%;
    background-color: transparent;

    .area-link {
      background: $branco;
      margin-bottom: 10px;
    }
    .area-link:last-child {
      margin-bottom: 0;
    }
    .area-link .icon {
      background-color: $primary;
    }
    .area-link .icon img {
      mix-blend-mode: normal;
    }
    .area-link .btn-link {
      transform: translatey(0) !important;
      opacity: 1;
    }
    .area-link .button-copy {
      transform: translatey(0) !important;
      opacity: 1;
    }
    .area-link .button-copy img {
      filter: brightness(1);
    }
  }

  .cardCrud {
    .topo-card {
      text-align: center;
      height: 130px;
      h1 {
        margin-top: 10px;
        margin-left: 0;
      }
      button {
        border: 1px solid $cinza2;
        margin: 0 8px 0;
        padding: 0 20px;
        height: 40px;
      }
    }
    .conteudo-card {
      .form-inside {
        .section-form {
          .weekDays .buttonWeek-on {
            width: 18%;
          }

          div.form-group {
            // min-width: 100px;
            width: 100%;
            padding: 7px 5px;
          }
          div.form-group.central {
            max-width: 50%;
            width: 50%;
            padding: 20px 20px;
            text-align: left;
          }
          div.form-group.central.fixo {
            width: 50%;
          }

          div.form-group.fixo {
            width: 50%;
          }

          .quebra {
            display: none;
          }

          div.form-group.mobile-100 {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}

// Mobile
@media (max-width: 414px) {
  .cardCrud {
    .topo-card {
      height: 155px;
      padding: 0px;
    }
    .conteudo-card {
      .form-inside {
        .section-form {
          .weekDays .buttonWeek-on,
          .weekDays .buttonWeek-off {
            width: 14%;
          }
        }
      }
    }
  }
}
