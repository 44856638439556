.indisponibilidade-row {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.indisponibilidade-row p {
  margin-bottom: 2px;
  font-size: $font-xs;
}
.indisponibilidade-row .anchor-link a {
  font-size: 12px;
}

.indisponibilidade-row .titulo-red {
  color: #ea3a45;
  font-weight: bold;
}
