.modal-soluti-otp {
  .modal-content {
    padding: 30px;
    background: $cinza1;
    border-radius: $border-radius;
    color: $preto;
    border: none;
    box-shadow: $shadow-modal;
  }

  .modal-body {
    padding: 0 20px;
  }

  .top-modal {
    margin-bottom: 20px;
  }

  .txt-modal {
    font-size: $font-default;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .txt-modal:hover {
    text-decoration: underline;
  }

  .txt-modal.disable {
    color: $cinza4;
    font-style: italic;
    font-weight: 300;
    text-align: center;
    overflow: initial;
    padding: 0 2px;
    cursor: default;
    text-decoration: none;
  }

  hr {
    margin: 30px 0 20px;
  }

  .font-bold {
    font-weight: bold;
  }

  .pointer {
    cursor: pointer;
  }

  .borda-separacao {
    border-right: 1px solid $cinza2;
  }

  .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0;
  }

  .btn-icon-only {
    min-width: 0px;
    padding: 0px;
    height: 30px;
    width: 30px;
    margin: 0 0 4px 4px;
    box-shadow: none;
    border-radius: 0px;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn.btn-icon-only:hover {
    transform: none;
  }
}
