.local-horario-atendimento .horario-estabelecimento label {
  margin-top: 10px;
}  
.local-horario-atendimento .horario-estabelecimento p {
  margin: 2px 0 0 10px;
}
  
.local-horario-atendimento .input-hour {
  margin: 0 5px 0px;
}
.local-horario-atendimento .agenda {
  width: 100%;
}
.agenda .horario-agenda {
  // margin-bottom: 20px;
  background: linear-gradient($branco 50%, $cinza1 100%);
  padding: 20px;
  border-radius: $border-radius-min $border-radius-min 0 0;
}
  // .agenda .horario-agenda:last-child {
  //   margin-bottom: 0;
  // }
  .agenda .horario-agenda .lines {
    border-bottom: 1px solid $cinza1;
    padding-bottom: 10px;
  }