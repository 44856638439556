.metric-cards {
  background: #f8f9fa !important;
  padding: 20px;
  margin-left: 30px;
  margin-right: 20px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50px;
  transition: all 500ms;
}

.metric-card {
  width: 19rem;
}

.metric-card-title {
  font-weight: 800;
  color: #546e7a;
  font-size: 18px;
  padding-bottom: 40px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.metric-card-text {
  font-weight: 800;
  color: #546e7a;
  font-size: 60px;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-right: 15px;
}

.metric-card-body {
  padding-top: 1px;
}

.metric-card-pacientes-agendados {
  color: #bc4b0d;
}

.metric-card-pacientes-atendidos {
  color: #606c38;
}

.metric-card-pacientes-confirmados {
  color: #02457a;
}

.metric-card-pacientes-faltantes {
  color: #8c2f39;
}

.chart-cards {
  background: #f8f9fa !important;
  padding: 20px;
  margin-left: 30px;
  margin-right: 20px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50px;
  transition: all 500ms;
}

.chart-card {
  width: 18rem;
  height: 34rem;
}

.chart-card-body {
  padding-top: 1px;
}

.chart-card-title {
  font-weight: 800;
  color: #546e7a;
  font-size: 18px;
  padding-bottom: 10px;
  letter-spacing: 1px;
}

.chart-card-pacientes-first {
  width: 100%;
  height: 70%;
}

.chart-card-pacientes-second {
  width: 100%;
  height: 30%;
}

.chart-card-full {
  width: 100%;
  height: 100%;
}

.column-chart-convenios {
  width: 100%;
}

.column-chart-convenios-with-padding {
  width: 100%;
  padding: 43px;
}

.chart-card-h85p {
  width: 100%;
  height: 85%;
}

.chart-tempo-atendimento-lengenda-main {
  height: 15%;
  width: 100%;
  color: #546e7a;
  font-size: 11px;
  padding-top: 22px;
}

.chart-tempo-atendimento-legenda {
  width: 10%;
  margin: 8px 7px 10px 10px;
}

.chart-tempo-atendimento-minimo {
  border: 1px solid #7c8657;
}

.chart-tempo-atendimento-medio {
  border: 1px solid #b95722;
}

.chart-tempo-atendimento-maximo {
  border: 1px solid #831a26;
}

.search-form-row {
  width: 94%;
}

.chart-text-blank {
  width: 100%;
  height: 100%;
  font-weight: 800;
  color: #546e7a;
  font-size: 13px;
  padding: 37px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

// Mobile
@media (max-width: 414px) {
  .metric-cards {
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border-radius: 0px;
  }
  .column-chart-convenios-with-padding {
    padding: 15px;
  }
  .chart-card {
    width: 100%;
    margin-top: 10px;
  }

  .chart-cards {
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border-radius: 0px;
  }
}
