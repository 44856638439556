.agenda-online-filter {
  // max-width: 300px;
  min-width: 0px;
  padding: 0px 0 0 20px;

  .input-required .is-invalid .react-select__control {
    border: 2px solid #ea3a45 !important;
  }
  h2 {
    text-align: center;
    max-width: 400px;
    margin: auto;
  }
  label {
    color: $preto;
    font-size: $font-xs;
    font-style: normal;
    margin-left: 2px;
  }
  .icone-label {
    filter: brightness(0);
  }
  .filters {
    max-width: 400px;
    margin: auto;
  }

  .tag {
    font-size: $font-default;
    padding: 10px 20px;
    border-radius: $border-radius;
    background-color: #2cda97;
    margin: 6px;
    font-weight: 700;
    text-align: center;
    color: #053c48;
  }
  .tag-success {
    background-color: $primary;
    border-radius: $border-radius;
    color: $branco;
    margin: 0px auto 0;
    padding: 20px 50px;
    text-align: center;
    max-width: 500px;
  }
}
.agenda-online-info {
  border-left: 1px solid $cinza2;
  margin: 0 0px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  // min-height: 70vh;

  .recomendacoes {
    max-width: 380px;
    margin: auto;

    h2 {
      font-weight: bold;
      font-style: italic;
      margin-bottom: 20px;
    }
  }
}
.agenda-online-content {
  border-left: 1px solid $cinza2;
  margin: 0 20px;
  min-width: 800px;
  height: 100%;

  .doctor {
    margin: 0 0px;
  }
}

// 1024
@media (max-width: 1024px) {
  .agenda-online-filter {
    max-width: 100%;
    padding: 0 0 50px;
  }

  .agenda-online-content {
    border: none;
    min-width: 0;
    margin: 0;
  }
  .agenda-online-info {
    border-left: none;
    border-top: 1px dashed $cinza4;
    min-width: 0;
    margin: 0;
    padding: 30px 10px 0;
    min-height: 0;
  }
}

// Mobil
@media (max-width: 1023px) {
  .agenda-online-filter {
    // min-height: 530px;
  }
  .agenda-online-content {
    .doctor {
      margin: 0 0;
      width: 100%;
      padding: 0px;
      margin-top: 70px;
      .top .background {
        height: 200px;
      }
      .schedules {
        margin-top: 70px;
      }
    }
  }
}
