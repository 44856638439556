.med-prescrito {
  margin: 15px 0 15px;

  .number {
    font-size: 14px;
    font-weight: bold;
    // margin-top: 2px;
    background-color: #2cda97;
    color: #fff;
    border-radius: $border-radius-min;
    width: 35px;
    height: 35px;
    max-width: 35px;
    min-width: 35px;
  }
  .btn-action {
  }
}

@media (max-width: 1023px) {
  .med-prescrito {
    .number {
      margin: 0 auto 10px;
    }
    p {
      text-align: center;
    }
  }
}
