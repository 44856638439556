.dumb-number-container {
  display: flex;
  justify-content: center;
  font-size: 15px;
  align-items: center;
}
.number-btn {
  background-color: transparent;
  border: 2px solid #555353;
  font-size: 20px;
  line-height: 20px;
}
.number-value {
  border: solid #555353;
  border-width: 2px 0;
  min-width: 50px;
  text-align: center;
  padding: 0 5px;
}
