.modal-body {
  form h2.w-100 {
    background-color: transparent;
    color: $preto;
    padding: 10px 15px;
    font-size: $font-xs;
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-inside {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 0px 0px 0px;
    // .visualizacao .section-form > div.form-group {
    //   border-right: 1px solid $cinza2;
    // }
    // .visualizacao .section-form > div.b-bottom {
    //   border-bottom: 1px solid $cinza2;
    // }
    // .visualizacao .section-form > div.form-group > p {
    //   line-height: 20px;
    //   margin-bottom: 3px;
    // }
    .section-form.item-view {
      div.form-group {
        background-color: initial;
        padding: 5px;
      }
    }
    .section-form {
      ///////////////////////FORMULÁRIOS E GUIAS//////////////////

      color: $cinza5;
      font-size: 10px;
      font-style: italic;
      padding: 0px 15px;

      //   .weekDays {
      //     padding: 0;
      //   }
      //   .weekDays .buttonWeek {
      //     width: 62px;
      //     font-size: $font-default;
      //     font-style: normal;
      //     text-align: center;
      //     padding: 10px 0;
      //     border-radius: $border-radius-btn;
      //     margin: 5px 0 5px 9px;
      //     transform: scale(1);
      //     cursor: pointer;
      //     transition: all 250ms;
      //   }

      //   .weekDays .buttonWeek-off {
      //     color: $preto;
      //     background-color: $branco;
      //     border-color: $branco;
      //     font-weight: 600;
      //   }
      //   .weekDays .buttonWeek-off:hover {
      //     outline: none !important;
      //     box-shadow: none !important;
      //     transform: scale(0.9);
      //   }

      //   .weekDays .buttonWeek-on {
      //     color: $branco !important;
      //     background-color: $primary !important;
      //     border-color: $primary;
      //     font-weight: 800;
      //   }

      .convenios .control--checkbox span {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: $preto;
        font-style: normal;
      }
      > h6 {
        padding-left: 15px;
        font-size: 10px;
        line-height: 30px;
        margin-bottom: 0px;
        height: 30px !important;
        background-color: $cinza1;
      }
      > .convenios label.desabilitado {
        font-size: 12px;
        font-style: normal;
        padding: 0px 10px;
      }

      div.form-group .form-control {
        background-color: transparent;
      }
      .horario-agenda {
        padding: 0px 5px;
        font-size: 14px;
      }
      div.form-group,
      div.form-row div.form-group {
        padding-right: 5px;
        padding-left: 5px;
        background-color: transparent;
        padding-bottom: 3px;
        margin-bottom: 0px;
      }

      > div .form-control:focus {
        box-shadow: 0 0 0px $cor3 !important;
        outline-offset: 0px !important;
        outline: none !important;
        border: none;
      }

      div > label {
        margin: 0px;
        // padding: 0px 10px;
      }
      div.form-group .form-control {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $cinza4;
        height: 35px;
        padding: 0 5px;
      }
      div.form-group .form-control:disabled,
      div.form-group .form-control[readonly] {
        color: rgb(153, 153, 153);
        opacity: 1;
        border: none;
        background-color: $branco;
      }

      div.form-group .form-control.is-invalid {
        border-bottom: 2px solid $vermelho;
      }
      // BOX
      div.form-group {
        // background-color: #ffffff;
        padding: 20px 10px;
        width: 0;
      }
      div.form-group.central {
        max-width: 140px;
        min-width: 70px;
        padding: 20px 5px;
        text-align: center;
      }
      div.form-group.central .Select .react-select__value-container {
        display: flex !important;
        justify-content: center !important;
        // margin-left: 30px;
      }

      div.form-group.central.fixo {
        width: 70px;
      }
      .quebra {
        flex-basis: 100%;
        height: 0;
      }
      div.form-group.fixo {
        width: 210px;
        flex: 0 0 auto !important;
      }
      div.form-group.large {
        width: 30%;
      }
      div.form-group.full {
        width: 80%;
      }
      .form-group:first-child {
        // border-left: 1px solid $cinza2;
        // border-top: 1px solid $cinza2;
      }
      .form-group {
        // border-right: 1px solid $cinza2;
        // border-bottom: 1px solid $cinza2;
        // border-top: 1px solid $cinza2;
      }
      > .w-100 + div {
        border-left: 1px solid $cinza2;
      }

      > .quebra + div {
        border-left: 1px solid $cinza2;
      }
      .form-group.campoPendente {
        outline: 2px solid #3c6669;
        outline-offset: -2px;
        z-index: 2;
        // background-image: url('./img/icon_pendente_SMALL.svg');
        background-size: 10px;
        background-position: 97% center;
        background-repeat: no-repeat;
      }
      .icone-pendente {
        display: none;
      }
      .form-group.campoPendente .icone-pendente {
        display: inline-flex;
      }

      div.form-group.w-20 {
        width: 20%;
      }
      // LABELS
      .label-nowrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin: 0;
      }
      .input-required label {
        font-weight: bold;
      }
      label {
        color: $preto;
        font-size: 12px;
        padding-left: 0px;
        display: inline-block;
        padding: 0px 5px;
      }

      .form-group.central label {
        padding-left: 0px;
        padding-right: 0px;
      }
      // INPUT COMUM

      > div.form-group .input-group .form-control {
        width: 90%;
      }
      > div.form-group .input-group .input-group-text {
        background-color: transparent;
        border: none;
        border-radius: 0;
      }
      > div.form-group textarea.form-control {
        min-height: 53px;
      }
      > div.form-group.central .form-control {
        text-align: center;
      }
      // REQUIRED
      div.form-group .input-required input {
        border-color: #000000;
        border-width: 2px;
        color: #000000;
      }
      // DISABLED
      div.form-group .form-control:disabled,
      div.form-group .form-control[readonly] {
        color: $cinza5;
        font-weight: bold;
        background-color: $branco;
        border-bottom: 2px solid $cinza4;
        // SELECT
      }
    }
  }

  .Select.form-control .Select-control {
    border: none;
    background-color: transparent;
    text-align: left;
  }
  .Select.form-control .Select-control:hover {
    box-shadow: 0 0px 0 rgba(0, 0, 0, 0);
  }

  .Select .Select-control .Select-value-label {
    font-size: 12px;
  }
  .text-center .Select .react-select__value-container {
    display: flex !important;
    justify-content: center !important;
    margin-left: 30px;
  }

  .Select.form-control.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }
  .Select.has-value.Select--single
    > .Select-control
    .Select-value
    .Select-value-label {
    color: $preto;
  }
  .Select .Select-menu-outer,
  .react-select__menu {
    font-family: 'Muli', sans-serif;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $branco;
    border: none !important;
    box-shadow: 0 0 20px #00000030 !important;
    margin-top: 0px;
    z-index: 300000 !important;
  }

  .Select .Select-placeholder,
  .Select.Select--single > .Select-control .Select-value,
  .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: $cinza4 !important;
    border-radius: 10px;
  }

  .Select {
    padding: 0px;
    background-color: transparent;
    min-height: 40px;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $preto;
    border-radius: 10px;
    border: initial;
  }
  .Select .react-select__control {
    min-height: 30px;
    font-family: 'Muli', sans-serif;
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid $cinza4;
    background-color: transparent;
    cursor: pointer;
  }
  .Select.is-invalid .react-select__control {
    border-bottom: 2px solid $vermelho;
  }
  .Select.is-invalid .react-select__control .css-1uccc91-singleValue {
    color: $vermelho;
  }
  .input-required .react-select__control {
    border-color: #000;
    border-width: 2px;
  }
  .Select .Select-placeholder,
  .Select.Select--single > .Select-control .Select-value,
  .react-select__placeholder {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    color: $cinza4 !important;
    border-radius: 10px;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0px !important;
    width: 13px;
    height: 20px;
    margin-right: 10px;
  }
  .icon-input {
    position: absolute;
    width: 15px;
    top: 12px;
    left: 20px;
  }
}

.modalCrud .modal-content {
  border-radius: $border-radius;
  background-color: $cinza1;
  max-width: 638px;
  margin: 0 auto;
  max-height: 90vh;
}

// HEADER MODAL
.modalCrud .modal-header {
  background-color: $branco;
  height: 80px;
  border-radius: $border-radius $border-radius 0 0;
  border-bottom: none;
  padding: 0 10px 0 20px;
}
.modalCrud .modal-header .modal-header-info {
  width: 100%;
}
.modalCrud .modal-header .modal-header-info h1 {
  font-size: $font-md;
  margin-left: 10px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modalCrud .modal-header .modal-header-info .btn-secondary {
  box-shadow: none;
}

// BODY MODAL
.modalCrud .modal-body {
  padding: 10px 20px 20px;

  overflow-y: auto;
  max-height: 93vh;
}

//  Media Mobile
@media (max-width: 1023px) {
  .modalCrud .modal-content {
    max-width: none;
    width: 96%;
    margin: 10px 10px 20px 6px;
  }
  .modalCrud .modal-header {
    height: 130px;
  }
  .modalCrud .modal-header .modal-header-info h1 {
    text-align: center;
  }
  .modalCrud .modal-header .modal-header-info .btn-secondary {
    border: 1px solid #e1e6eb;
    margin: 0 8px 0;
    padding: 0 20px;
  }
}
