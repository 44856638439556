.folha-anamnese {
  background-color: $branco;
  // max-width: 49%;
  min-height: 600px;
  flex-basis: 50%;
  border-top: 2px solid $bt-azul;
  position: relative;
  padding: 30px 30px 20px 30px;
  margin: 0px 10px;

  .titulo {
    font-size: $font-sm;
    font-weight: bold;
  }
}
// .folha-anamnese:before {
//   content: '';
//   position: absolute;
//   top: 0;
//   right: 0;
//   border-style: solid;
//   border-width: 0 25px 25px 0;
//   border-color: #f2f5f7 #eaf0f4;
//   transition: all ease 0.5s;
// }
// .folha-anamnese:hover:before {
//   border-width: 0 35px 35px 0;
//   border-color: #f2f5f7 #eaf0f4;
// }
.folha-anamnese .btn-secondary {
  box-shadow: none;
  // margin: 0;
}
.folha-anamnese .folha-topo .folha-icon {
  width: 20px;
  filter: brightness(0);
  margin-right: 6px;
}
.folha-conteudo {
  position: relative;
}

@media (max-width: 1023px) {
  .folha-anamnese {
    max-width: 100%;
    padding: 24px;
  }
  .folha-anamnese:first-child {
    border-bottom: 20px solid $default;
  }
}
