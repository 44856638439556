.modal-termo {
  display: flex;
  justify-content: center;

  .modal-content {
    padding: 30px;
    background: $cinza1;
    border-radius: $border-radius;
    color: $preto;
    border: none;
    box-shadow: $shadow-modal;
    height: 80vh;
  }
  .modal-body {
    padding: 0 20px;
    height: 100%;
    overflow-y: scroll;
  }
  .top-modal {
    margin-bottom: 20px;
  }

  hr {
    margin: 5px 0 5px;
  }

  .modal-footer {
    border-top: none;
    flex-wrap: nowrap;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .modal-termo {
    margin: 0 20px 0 10px;
  }
}
