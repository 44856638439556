.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}


//  Media Mobile
@media (max-width: 1023px) {
  .countdown-item {
    font-size: 20px;
    width: 45px;
    height: 40px;
  }
}